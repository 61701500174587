export default {
  instance: {
    id: 44,
    full_name: "Jonathan Bowman",
    unit: 1104,
    likelihood_to_renew: 23,
    location: {
      id: 1121,
      name: "Lakefront View",
      manager: {
        id: 45,
        full_name: "Michael Craig",
      },
    },
    region: {
      id: 98,
      name: "Upstate",
      manager: {
        id: 22,
        full_name: "Rebecca Bachman",
      },
    },
    survey: {
      id: 3,
      name: "Service Request Follow Up",
      date_taken: "08/12/2021",
      questions: [
        {
          id: 44,
          position: 1,
          body: "The office staff is responsive",
          question_type: 1,
          response: 0,
          region_score: 45,
          property_score: 34,
          company_score: 88,
          sbx: 82,
        },
        {
          id: 45,
          position: 2,
          body: "My home is in good condition",
          question_type: 1,
          response: 1,
          region_score: 45,
          property_score: 34,
          company_score: 88,
          sbx: 82,
        },
        {
          id: 46,
          position: 3,
          body: "The maintenance staff work quality is good",
          question_type: 2,
          response: 72,
          region_score: 45,
          property_score: 34,
          company_score: 88,
          sbx: 82,
        },
        {
          id: 48,
          position: 4,
          body: "Please share any suggestions or comments",
          question_type: 4,
          response:
            "They did a great job, and I can't really complain about anything! Jeremy was very professional, and super nice.",
        },
      ],
    },
  },
};
