<template>
  <div class="renewal-bar pa-2" :style="cssVars">
    <tooltip-icon
      :tooltip="orgType"
      :icon="icon"
      :icon-attrs="{
        class: 'renewal-bar__icon',
        color: color
      }"
    />

    <div class="renewal-bar__bar my-2">
      <div class="renewal-bar__bar__inner" />
      <div class="renewal-bar__bar__outer" />
    </div>

    <v-tooltip right transition="slide-x-reverse-transition">
      <template #activator="{ on }">
        <h3 class="renewal-bar__value" v-on="on">
          {{ value == "–" ? value : value + "%" }}
        </h3>
      </template>
      {{ label }}
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: [Number, String], default: 0 },
    label: { type: String, default: "" },
    icon: { type: String, default: "" },
    color: { type: String, default: "#f1f1f1" },
    orgType: { type: String, default: "" }
  },

  computed: {
    cssVars() {
      return {
        "--color": this.color,
        "--value": this.value === "–" ? "-100%" : (100 - this.value) * -1 + "%"
      };
    }
  }
};
</script>

<style lang="scss">
.renewal-bar {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100%;
  text-align: center;

  .renewal-bar__icon.v-icon {
    width: 100%;
    text-align: center;
    display: block;
  }

  .renewal-bar__bar {
    flex-grow: 1;
    position: relative;
    overflow: hidden;

    .renewal-bar__bar__inner {
      transition: bottom 0.25s ease-in-out;
      position: absolute;
      bottom: var(--value);
      left: 0;
      height: 100%;
      width: 100%;
      background-color: var(--color);
    }

    .renewal-bar__bar__outer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.2;
      background-color: var(--color);
    }
  }

  .renewal-bar__value {
    font-size: 24px;
    font-weight: 700;
  }
}
</style>
