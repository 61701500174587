<template>
  <div>
    <template v-if="resident && respondents.instanceReady">
      <v-row class="mb-4">
        <v-col md="auto" v-if="Object.values(cardMetrics).some(x => x)">
          <v-card>
            <v-card-title class="d-block text-no-wrap">
              <div v-if="cardMetrics.score">
                Score:
                <span class="font-weight-bold">{{ cardMetrics.score }}</span>
              </div>
              <div v-if="cardMetrics.likelyToRenew" class="d-flex align-center">
                Renewal:&nbsp;
                <strong>{{ cardMetrics.likelyToRenew * 20 }}%</strong>
                <likelihood-emoticon :likelihood="cardMetrics.likelyToRenew" />
              </div>
              <div v-if="cardMetrics.serviceIssue" class="d-flex align-center">
                Service Issue
                <v-icon color="error" class="ml-3">
                  mdi-alert-circle
                </v-icon>
              </div>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col md="auto">
          <h6 class="text-h6 font-weight-bold">
            <span v-if="!resident.first_name && !resident.last_name">
              Prospect
            </span>
            <span v-else
              >{{ resident.first_name }} {{ resident.last_name }}</span
            >
          </h6>
          <div v-if="auth.hasToken" class="font-italic">
            Login to see contact info
          </div>
          <template v-else-if="!resident.is_confidential">
            <div v-if="resident.primary_email" class="d-flex align-center">
              Email:
              <v-btn
                color="primary"
                class="ml-2"
                outlined
                small
                rounded
                :href="`mailto:${resident.primary_email}`"
              >
                <v-icon left>mdi-email</v-icon>
                {{ resident.primary_email }}
              </v-btn>
            </div>
            <div v-if="resident.phone_number" class="d-flex align-center mt-2">
              Phone:
              <v-btn
                color="primary"
                class="ml-2"
                outlined
                small
                rounded
                :href="`tel:${resident.phone_number}`"
              >
                <v-icon left>mdi-phone</v-icon>
                {{ resident.phone_number }}
              </v-btn>
            </div>
          </template>
        </v-col>
        <v-divider inset vertical class="mx-auto" />
        <v-col md="auto">
          <h3>
            Location:
            <a
              v-if="location && location.slug"
              :href="locationResultsUrl"
              class="font-weight-bold"
            >
              {{ location.name }}
            </a>
            <span v-else class="font-weight-bold">
              No Location Assigned
            </span>
          </h3>
          <h3 v-if="survey.id != 27">
            Unit #:
            <span v-if="resident.is_confidential" class="font-weight-bold"
              >NA</span
            >
            <span v-else class="font-weight-bold">{{
              unit ? unit.unit_number : "Missing"
            }}</span>
          </h3>
          <h3>
            On-Site Managers:
            <template v-if="location_managers && location_managers.length">
              <cp-explain v-if="location_managers.length > 1">
                <template #activator="{on}">
                  <v-chip
                    color="primary"
                    v-on="on"
                    class="font-weight-bold"
                    outlined
                  >
                    {{ pluralize("manager", location_managers.length, true) }}
                  </v-chip>
                </template>
                <cp-profile-list
                  :profiles="location_managers"
                  :href="x => (x.email ? `mailto:${x.email}` : null)"
                  target="_blank"
                  dense
                />
              </cp-explain>
              <span v-else :set="(manager = location_managers[0])">
                <v-chip
                  color="primary"
                  class="mr-1"
                  outlined
                  :disabled="!manager.email"
                  :href="manager.email ? `mailto:${manager.email}` : null"
                >
                  {{ manager.full_name }}
                </v-chip>
              </span>
            </template>
            <span v-else class="font-weight-bold">No Manager Assigned</span>
          </h3>
        </v-col>
        <v-divider inset vertical class="mx-auto" />
        <v-col md="auto">
          <h3>
            Region:
            <span class="font-weight-bold">
              {{ region && region.name ? region.name : "No Region Assigned" }}
            </span>
          </h3>
          <h3>
            Regional Managers:
            <template v-if="region_managers && region_managers.length">
              <cp-explain v-if="region_managers.length > 1">
                <template #activator="{on}">
                  <v-chip
                    color="primary"
                    v-on="on"
                    class="font-weight-bold"
                    outlined
                  >
                    {{ pluralize("manager", region_managers.length, true) }}
                  </v-chip>
                </template>
                <cp-profile-list
                  :profiles="region_managers"
                  :href="x => (x.email ? `mailto:${x.email}` : null)"
                  target="_blank"
                  dense
                />
              </cp-explain>
              <span v-else :set="(manager = region_managers[0])">
                <v-chip
                  :disabled="!manager.email"
                  :href="manager.email ? `mailto:${manager.email}` : null"
                  target="_blank"
                  color="primary"
                  class="mr-1"
                  outlined
                >
                  {{ manager.full_name }}
                </v-chip>
              </span>
            </template>
            <span v-else class="font-weight-bold">
              No Regional Manager Assigned
            </span>
          </h3>
        </v-col>
      </v-row>

      <v-sheet rounded class="pa-3">
        <div class="d-flex">
          <div class="flex-grow-1">
            <span class="text-h6 font-weight-bold">
              {{ survey.name }}
            </span>
            Responded {{ toMDY(resident_survey.taken_date) }}
          </div>
          <div class="scores-columns">
            <tooltip-icon
              tooltip="User Response"
              icon="mdi-account"
              :icon-attrs="{ class: 'user-color' }"
            />

            <tooltip-icon
              tooltip="Location"
              icon="mdi-home"
              :icon-attrs="{ class: 'location-color' }"
            />

            <tooltip-icon
              tooltip="Region"
              icon="mdi-home-group"
              :icon-attrs="{ class: 'region-color' }"
            />

            <tooltip-icon
              tooltip="Company"
              icon="mdi-office-building"
              :icon-attrs="{ class: 'company-color' }"
            />

            <tooltip-icon
              tooltip="SB Index"
              icon="$vuetify.icons.SwiftBunnyLogo"
              :icon-attrs="{ class: 'sbx-color' }"
            />
          </div>
        </div>
      </v-sheet>

      <response
        v-for="(response, i) in questions"
        :key="response.id"
        :response="response"
        :question-number="i + 1"
      />
    </template>

    <div v-else-if="!respondents.instanceReady" class="text-center pa-4">
      <v-progress-circular
        color="primary"
        indeterminate
        :size="80"
        :width="10"
      />
    </div>
  </div>
</template>

<script>
import pluralize from "pluralize";
import { mapState } from "vuex";

import { toMDY } from "@cp/utils/dateUtils";

export default {
  props: {
    omniClient: { type: Object, default: () => {} },
  },

  computed: {
    ...mapState({
      respondents: state => state.respondents,
      resident_survey: state => state.respondents.instance,
      unit: state => state.respondents.instance.unit,
      location_managers: state => state.respondents.instance.location_managers,
      region_managers: state => state.respondents.instance.region_managers,
      location: state => state.respondents.instance.location,
      questions: state => state.respondents.instance.questions,
      region: state => state.respondents.instance.region,
      survey: state => state.respondents.instance.survey,
      auth: state => state.auth,
    }),

    resident() {
      return this.$store.state.respondents.instance.resident || {};
    },

    locationResultsUrl() {
      if (!this.region || !this.location || !this.survey) {
        return "";
      }

      return `${process.env.VUE_APP_REDSTONE_UI_PATH}/regions/${this.region.slug}/${this.location.slug}/${this.survey.slug}?tab=1`;
    },

    cardMetrics() {
      return {
        score: this.resident_survey.agreement_importance,
        likelyToRenew: this.resident_survey.likely_to_renew,
        serviceIssue: this.resident_survey.service_issue,
      };
    },
  },

  created() {
    if (this.$route.query.showResidentSurvey) {
      this.$store.dispatch(
        "respondents/openDetailsModal",
        this.$route.query.showResidentSurvey
      );
    }
  },

  methods: {
    toMDY,
    pluralize,
    closeCleanup() {
      let query = Object.assign({}, this.$route.query);
      delete query.showResidentSurvey;
      this.$router
        .replace({ path: window.location.pathname, query: query })
        .catch(() => {});
      this.$store.commit("respondents/resetInstance");
    },
  },
};
</script>

<style lang="scss" scoped>
.header-icons {
  &::v-deep {
    .tooltip-icon {
      display: inline-block;
    }

    .v-icon.v-icon {
      padding: 0 17px;
    }
  }
}

.scores-columns {
  display: flex;
  ::v-deep > .tooltip-icon {
    justify-content: center;
    width: 5rem;
    text-align: center;
  }
}
</style>
