import Common from "../common.js";
let common = new Common("notification", "notifications", "notifications");

export default {
  namespaced: true,

  state: {
    ...common.state,
    exporting: false,
    tableHeaders: [
      { text: "Details", value: "details" },
      { text: "Queue Status", value: "notification_status" },
      { text: "Scheduled", value: "scheduled_date" },
      { text: "First Sent", value: "first_sent" },
      { text: "Survey Status", value: "status" },
      { text: "Last Reminder", value: "last_reminder_sent" },
      { text: "Next Reminder", value: "next_reminder" },
      { text: "", value: "actions" }
    ]
  },

  getters: {
    ...common.getters
  },

  actions: {
    ...common.actions,

    fetchSummary({ commit, state }) {
      state.instanceReady = false;

      return common.bApi
        .authorize()
        .get("notifications/summary")
        .then(({ notification_summaries }) => {
          commit("setInstance", notification_summaries);
        });
    },

    fetchInstance({ commit, state, dispatch }, id) {
      state.instanceReady = false;

      return common.bApi
        .authorize()
        .get(`notifications/${id}`)
        .then(data => {
          commit("setInstance", data);

          state.instance.forEach(queueItem => {
            dispatch("fetchEmailDetails", queueItem);
          });
        });
    },

    fetchEmailDetails({ commit, state }, queueItem) {
      return common.bApi
        .authorize()
        .get(
          `notifications/${queueItem.notification_queue_id}/${queueItem.message_id}`
        )
        .then(data => {
          queueItem.emailDetails = data;
        });
    },

    export({ state }) {
      state.exporting = true;

      return common.bApi
        .authorize()
        .download("/notifications/export")
        .then(() => {
          state.exporting = false;
        });
    },

    execQueueAction({ commit, state }, { link, method }) {
      return common.plainApi
        .authorize()
        [method](link)
        .then(data => {
          // console.log(data);
        });
    }
  },

  mutations: {
    ...common.mutations
  }
};
