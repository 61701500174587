var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.omniClient)?_c('div',{staticClass:"text-center pa-4"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":80,"width":10}})],1):_c('v-data-table',{attrs:{"dense":"","must-sort":"","item-key":"id","headers":_vm.headers,"items":_vm.omniClient.awaitingResponse ? [] : _vm.items,"loading":_vm.omniClient.awaitingResponse,"server-items-length":_vm.omniClient.meta.pagination.total_count,"options":{
      multiSort: false,
      page: parseInt(_vm.omniClient.meta.pagination.current || 1),
      itemsPerPage: parseInt(_vm.omniClient.meta.pagination.page_size || 15),
      sortBy: [_vm.omniClient.sortBy],
      sortDesc: [_vm.omniClient.sortDesc],
    },"footer-props":{
      itemsPerPageOptions: [5, 10, 15, 30],
      disableItemsPerPage: _vm.omniClient.awaitingResponse,
      disablePagination: _vm.omniClient.awaitingResponse,
    }},on:{"update:page":function($event){_vm.omniClient.page.number = $event;
      _vm.omniClient.fetch({ debounce: 200 });},"update:items-per-page":function($event){_vm.omniClient.page.size = $event;
      _vm.omniClient.fetch({ debounce: 200 });},"update:sort-by":function($event){_vm.omniClient.sortBy = $event;
      _vm.omniClient.fetch({ debounce: 200 });},"update:sort-desc":function($event){_vm.omniClient.sortDesc = $event;
      _vm.omniClient.fetch({ debounce: 200 });}},scopedSlots:_vm._u([{key:"item.score_danger",fn:function({ item }){return [(
          Number.isInteger(item.agreement_importance) &&
            item.agreement_importance < 70
        )?_c('v-tooltip',{attrs:{"right":"","transition":"slide-x-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v(" mdi-alert-circle ")])]}}],null,true)},[_vm._v(" Score below 70 ")]):_vm._e()]}},{key:"item.taken_date",fn:function({value}){return [_vm._v(_vm._s(value ? _vm.toMDY(value) : "N/A"))]}},{key:"item.agreement_importance",fn:function({ item }){return [(!item.agreement_importance)?_c('span',[_vm._v("––")]):_c('span',[_vm._v(" "+_vm._s(item.agreement_importance)+" ")])]}},{key:"item.full_name",fn:function({ item }){return [(
          item.full_name === 'null null' &&
            item.survey_name === 'Community Follow Up Survey'
        )?_c('span',[_vm._v(" Prospect ")]):_c('span',[_vm._v(" "+_vm._s(item.full_name)+" "),(item.full_name == 'Confidential')?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-shield-account-outline ")]):_vm._e()],1)]}},{key:"item.likely_to_renew",fn:function({ item }){return [(!item.likely_to_renew)?_c('span',[_vm._v("––")]):_c('span',[_c('likelihood-emoticon',{attrs:{"likelihood":item.likely_to_renew}})],1)]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{attrs:{"rounded":"","text":"","color":"primary","small":""},on:{"click":function($event){return _vm.openModalAndRedirect(item)}}},[_vm._v(" Details "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }