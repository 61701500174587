<template>
  <div>
    <score-bar
      class="company-color"
      :value="scores.client.agreement_importance"
      :label="scores.client.text"
      icon="mdi-office-building"
      :route="{
        name: 'regions',
      }"
    />

    <score-bar
      v-if="scores.district && $store.getters.userAccessLevel == 'district'"
      class="district-color"
      :class="{
        'is-hidden': !scores.district.agreement_importance,
      }"
      :value="scores.district.agreement_importance || '–'"
      :label="scores.district.text || 'District'"
      icon="mdi-map-marker-radius"
      :route="{
        name: 'regions',
        params: {
          districtSlug: scores.district.slug,
        },
      }"
    />

    <score-bar
      v-if="['district', 'region'].includes($store.getters.userAccessLevel)"
      class="region-color"
      :class="{
        'is-hidden': !scores.region.agreement_importance,
      }"
      :value="scores.region.agreement_importance || '–'"
      :label="scores.region.text || 'Region'"
      icon="mdi-home-group"
      :route="{
        name: 'locations',
        params: {
          regionSlug: scores.region.slug,
        },
      }"
    />

    <score-bar
      class="location-color"
      :class="{
        'is-hidden': !scores.location.agreement_importance,
      }"
      :value="scores.location.agreement_importance || '–'"
      :label="scores.location.text || 'Property'"
      icon="mdi-home"
      :route="{
        name: 'surveys',
        params: {
          regionSlug: scores.region.slug,
          locationSlug: scores.location.slug,
        },
      }"
    />

    <v-divider class="mb-1 mt-2" />

    <score-bar
      class="sbx-color"
      :value="scores.index.agreement_importance"
      :label="scores.index.text"
      icon="$vuetify.icons.SwiftBunnyLogo"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      scores: (state) => state.scores,
    }),
  },
};
</script>

<style lang="scss" scoped>
.is-hidden {
  opacity: 0.15;
  pointer-events: none;
}
</style>
