export default {
  collection: [
    {
      id: 1,
      name: "Shady Elms",
      slug: "shady-elms",
      score: 85,
      reporting_groups: ["pets_allowed", "stu_housing", "new_ren"],
    },
    {
      id: 2,
      name: "Lakefront View",
      slug: "lakefront-view",
      score: 78,
      reporting_groups: ["pets_allowed", "mil_housing"],
    },
    {
      id: 3,
      name: "Avana",
      slug: "avana",
      score: 78,
      reporting_groups: ["cov_housing"],
    },
    {
      id: 4,
      name: "Cherry Ridge",
      slug: "cherry-ridge",
      score: 78,
      reporting_groups: ["pets_not_allowed", "cov_housing", "new_ren"],
    },
  ],

  instance: {
    id: 2,
    name: "Lakefront View",
    slug: "lakefront-view",
    score: 78,
    contact: {
      name: "Michael Craig",
      email: "michael@swiftbunny.com",
    },
  },
};
