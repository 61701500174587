import { Api } from "@cp/lib";
import store from "@cp/store/appStore";
import timeline from "../lib/timeline";
import { toMDY, date } from "@cp/utils/dateUtils";

export default class {
  constructor({
    data = {},
    meta = {
      filters: {
        confidential_only: {},
        date_range: {},
      },
      structures: {
        date_interval: {},
      },
      pagination: {
        current: null,
        total_page: null,
      },
    },
    filters = {
      confidential_only: null,
      reporting_group: [],
      date_range: date()
        .subtract(4, "month")
        .startOf("month")
        .format("YYYY-MM-DD"),
    },
    structures = {
      date_interval: "month",
    },
    page = {
      number: 1,
      size: 15,
    },
    q_text = null,
    callback = null,
    sortBy = null,
    sortDesc = false,
    awaitingResponse = false,
    chartData = [],
    chartInterval = "Monthly",
  } = {}) {
    this.data = data;
    this.meta = meta;
    this.filters = {
      ...filters,
    };
    this.structures = structures;
    this.page = {
      number: 1,
      size: 15,
      ...page,
    };
    this.sortBy = sortBy;
    this.sortDesc = sortDesc;
    this.q_text = q_text;
    this.callback = callback;
    this.awaitingResponse = awaitingResponse;
    this.chartData = chartData;
    this.chartInterval = chartInterval;

    this.bApi = new Api(`${process.env.VUE_APP_ENGAGE_API_PATH}/en/v1`);
    this.fetchTimeout = null;
  }

  fetch({ debounce = 0, callback = null } = {}) {
    // if the fetch should wait an amount of time before firing
    if (debounce && debounce > 0) {
      clearTimeout(this.fetchTimeout);
      this.fetchTimeout = setTimeout(() => {
        return this.fetch({ callback });
      }, debounce);

      return this.fetchTimeout;
    }

    this.awaitingResponse = true;

    // these two are to get around an annoyance with how vuetify handles multisort
    if (Array.isArray(this.sortBy)) {
      this.sortBy = this.sortBy[0];
    }

    if (Array.isArray(this.sortDesc)) {
      this.sortDesc = this.sortDesc[0];
    }

    let scrubbedStart = store.state.filters.date_start.replace(/-/g, "/");
    let scrubbedEnd = store.state.filters.date_end.replace(/-/g, "/");
    this.filters.date_range = [
      date(scrubbedStart).format("YYYY-MM-DD"),
      date(scrubbedEnd).format("YYYY-MM-DD"),
    ];
    this.structures.date_interval = "month";

    if (store.state.groups.selected) {
      this.filters.reporting_group = store.state.groups.selected;
    }

    return this.bApi
      .authorize()
      .get("intouch/dashboard", {
        // clear out any filters that are null or empty arrays
        filters: Object.entries(this.filters).reduce(
          (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
          {}
        ),
        structures: this.structures,
        page: this.page,
        q_text: this.q_text,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
      })
      .then(response => {
        this.awaitingResponse = false;

        this.data = response.data;
        this.meta = response.meta;

        if (callback) callback(response);

        return response;
      });
  }

  download({
    filename = "export.xlsx",
    params = {},
    endpoint = "intouch/dashboard.xlsx",
  } = {}) {
    this.awaitingResponse = true;

    return this.bApi
      .authorize()
      .download(endpoint, filename, {
        filters: Object.entries(this.filters).reduce(
          (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
          {}
        ),
        structures: this.structures,
        ...params,
        page: {
          size: "all",
        },
      })
      .then(response => {
        this.awaitingResponse = false;
        return response;
      });
  }

  shareOverEmail({
    emails = [],
    message = null,
    link = null,
    endpoint = "intouch/dashboard.xlsx",
    params = {},
  }) {
    this.awaitingResponse = true;

    if (
      this.filters.reporting_group &&
      this.filters.reporting_group.length <= 0
    ) {
      delete this.filters.reporting_group;
    }

    return this.bApi
      .authorize()
      .post(endpoint, {
        filters: Object.entries(this.filters).reduce(
          (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
          {}
        ),
        structures: this.structures,
        q_text: this.q_text,
        emails: emails,
        message: message,
        link: link,
        ...params,
        page: {
          size: "all",
        },
      })
      .then(response => {
        this.awaitingResponse = false;
        return response;
      });
  }

  previousPage() {
    if (this.onFirstPage()) return;

    this.page.number = this.meta.pagination.current - 1;

    this.fetch();
  }

  nextPage() {
    if (this.onLastPage()) return;

    this.page.number = this.meta.pagination.current + 1;

    this.fetch();
  }

  goToPage(pageNumber) {
    if (pageNumber <= 0 || pageNumber > this.meta.pagination.total_pages) {
      return;
    }

    this.page.number = pageNumber;

    this.fetch();
  }

  onFirstPage() {
    return this.meta.pagination.current == 1;
  }

  onLastPage() {
    return this.meta.pagination.current == this.meta.pagination.total_pages;
  }

  paginationOptions() {
    let options = [];

    for (var i = 1; i <= this.meta.pagination.total_pages; i++) {
      options.push({
        text: `Page ${i}`,
        value: i,
      });
    }

    return options;
  }

  reset() {
    this.filters = {
      reporting_group: [],
      date_range: store.state.filters.date_range,
    };
    this.structures = {
      date_interval: store.state.structures.date_interval,
    };
    this.page = {};
    this.q_text = null;
  }

  forItemDetail() {
    if (!this.data.results) return {};

    return this.data.results[0];
  }

  forDashboard() {
    if (!this.data.results) return [];

    return this.data.results.map(x => ({
      id: x.m_id,
      trendies: this.forCompChart(x),
      trends: {
        item: x.timeline ? x.timeline.map(y => y.agreement_importance) : [],
        sbx: this.data.sbx.timeline
          ? this.data.sbx.timeline.map(y => y.agreement_importance)
          : [],
        company: this.data.client.timeline
          ? this.data.client.timeline.map(y => y.agreement_importance)
          : [],
      },
      response_rate:
        x.surveys_sent > 0
          ? Math.floor(((x.surveys_started || 0) / x.surveys_sent) * 100)
          : "–",
      ...x,
      name: x.name || "Unassigned",
    }));
  }

  forQuestionList({ onlyBadScores = false } = {}) {
    if (!this.data.results) return [];

    let results = this.data.results;

    if (onlyBadScores) {
      results = results.filter(
        x => x.agreement_importance != null && x.agreement_importance < 70
      );
    }

    return results
      .filter(x => x.m_id)
      .map(x => ({
        ...x,
        ...store.state.questions.getScoreValues(x),
        id: x.m_id,
        historic: timeline.getHistoricTimelineScores(
          x,
          this.structures.date_interval
        ),
        question_type_slug: x.question_type_slug.replace(/-/g, "_"),
      }));
  }

  forRespondentsList({ onlyBadScores = false } = {}) {
    if (!this.data.results) return [];

    let results = this.data.results;

    if (onlyBadScores) {
      results = results.filter(
        x => x.agreement_importance != null && x.agreement_importance < 70
      );
    }

    return results.map(x => ({
      ...x,
      id: x.m_id,
      full_name: x.is_confidential
        ? "Confidential"
        : `${x.first_name} ${x.last_name}`,
      unit_number: x.is_confidential ? "NA" : x.unit_number,
      created_at: toMDY(x.created_at),
      surveys: x.surveys,
    }));
  }

  forCompChart(item = null) {
    if (this.data.results.length <= 0) {
      return {};
    }

    let chartItem = item || this.data.results[0];
    let interval = this.structures.date_interval;
    let itemTimeline = [];
    let clientTimeline = [];
    let sbxTimeline = [];

    if (chartItem.timeline) {
      itemTimeline = chartItem.timeline.filter(
        x => x.agreement_importance != null
      );
    }

    if (this.data.client.timeline) {
      clientTimeline = this.data.client.timeline.filter(
        x => x.agreement_importance != null
      );
    }

    if (this.data.sbx.timeline) {
      sbxTimeline = this.data.sbx.timeline.filter(
        x => x.agreement_importance != null
      );
    }

    let dateBuckets = [];
    let startDate = date(this.filters.date_range[0]);
    let endDate = date(this.filters.date_range[1]);
    let intervalDiff = endDate.diff(startDate, interval);
    let yMin = 0;
    let yMax = 100;
    let labels = [];

    for (var i = 0; i <= intervalDiff; i++) {
      dateBuckets.unshift(
        endDate
          .subtract(i, interval)
          .startOf(interval)
          .format("YYYY-MM-DD")
      );

      switch (interval) {
        case "month":
          let month = endDate.subtract(i, "month");
          labels.unshift(`${month.format("MMM")}/${month.format("YY")}`);
          break;
        case "quarter":
          let quarter = endDate.subtract(i, "quarter");
          labels.unshift(`Q${quarter.quarter()}/${quarter.format("YY")}`);
          break;
        case "year":
          labels.unshift(endDate.subtract(i, "year").year());
          break;
      }
    }

    let allScores = [
      ...itemTimeline.map(x => x.agreement_importance),
      ...clientTimeline.map(x => x.agreement_importance),
      ...sbxTimeline.map(x => x.agreement_importance),
    ];

    let scoreMin = Math.min(...allScores) - 5;
    let scoreMax = Math.max(...allScores) + 5;

    let data = {
      yMin: scoreMin < 0 ? 0 : scoreMin,
      yMax: scoreMax > 100 ? 100 : scoreMax,
      labels: labels,
      item: dateBuckets.map((date, i) => ({
        x: i,
        y: itemTimeline.find(x => x.t_date == date)
          ? itemTimeline.find(x => x.t_date == date).agreement_importance
          : null,
      })),
      client: dateBuckets.map((date, i) => ({
        x: i,
        y: clientTimeline.find(x => x.t_date == date)
          ? clientTimeline.find(x => x.t_date == date).agreement_importance
          : null,
      })),
      sbx: dateBuckets.map((date, i) => ({
        x: i,
        y: sbxTimeline.find(x => x.t_date == date)
          ? sbxTimeline.find(x => x.t_date == date).agreement_importance
          : null,
      })),
    };

    return data;
  }
}
