import { date } from "@cp/utils/dateUtils";
import store from "@cp/store/appStore";

export default {
  getTimelineScore: function(timelineItem, questionTypeSlug) {
    let score = "–";

    switch (questionTypeSlug) {
      case "agreement-importance":
        score = timelineItem.agreement_importance;
        break;
      case "likelihood":
      case "agreement":
      case "importance":
        score = timelineItem[questionTypeSlug];
        break;
      case "yes-no":
        score = timelineItem.yes_no
          ? `Y/${Math.round(timelineItem.yes_no * 100)}%`
          : "–";
        break;
    }

    return score;
  },

  getHistoricTimelineScores: function(question, interval) {
    if (!question.timeline) {
      return ["–", "–", "–", "–", "–"];
    }

    let dateBuckets = [];
    let startDate = date(store.state.filters.date_start);
    let endDate = date(store.state.filters.date_end);
    let intervalDiff = endDate.diff(startDate, interval);

    for (var i = 0; i <= intervalDiff; i++) {
      dateBuckets.push(
        endDate
          .subtract(i, interval)
          .startOf(interval)
          .format("YYYY-MM-DD")
      );
    }

    return dateBuckets.map(date =>
      question.timeline.find(x => x.t_date == date)
        ? this.getTimelineScore(
            question.timeline.find(x => x.t_date == date),
            question.question_type_slug
          )
        : "–"
    );
  }
};
