<template>
  <div>
    <cp-select
      v-if="omniClient && omniClient.meta.filters.surveys"
      v-model="omniClient.filters.surveys"
      :items="omniClient.meta.filters.surveys.values"
      item-text="label"
      single-line
      clearable
      multiple
      class="int-card-action card-header-actions mr-4"
      label="Filter by Survey"
      dense
      hide-details
      @input="omniClient.fetch({ debounce: 200 })"
      :loading="omniClient && omniClient.awaitingResponse"
    />

    <v-btn
      v-if="['locations', 'allLocations'].includes($route.name)"
      @click="$store.dispatch('groups/openReportingGroupsFilterDialog')"
      rounded
      class="int-card-action"
      color="primary"
      small
    >
      <v-icon small>mdi-filter</v-icon> Filter by Reporting Groups
    </v-btn>

    <cp-combo-date-filter
      v-if="omniClient"
      offset-y
      left
      :start-date="$store.state.filters.date_start"
      :end-date="$store.state.filters.date_end"
      :preset="$store.state.filters.date_preset"
      :loading="omniClient.awaitingResponse"
      @selectionUpdated="
        $store.commit('setDateFilter', $event);
        omniClient.fetch();
      "
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    omniClient: { type: Object, default: () => {} },
  },

  data() {
    return {
      dateFilterOpen: false,
    };
  },

  computed: {
    ...mapState({
      groups: state => state.groups,
    }),
  },
};
</script>

<style lang="scss" scoped>
.card-header-actions {
  &::v-deep .v-select__selections {
    flex-wrap: nowrap;
  }
}
</style>
