<template>
  <router-link :to="route" class="score-bar">
    <h1 class="score-bar__label">
      <v-icon v-if="icon" small class="mr-1">
        {{ icon }}
      </v-icon>
      {{ label }}
    </h1>

    <v-tooltip right transition="slide-x-reverse-transition">
      <template #activator="{ on }">
        <h1 class="score-bar__score text-center" v-on="on">
          {{ value }}
        </h1>
      </template>
      {{ label }}
    </v-tooltip>

    <v-progress-linear
      class="score-bar__bar"
      :value="valueAsInt || 0"
      height="10"
    />
  </router-link>
</template>

<script>
export default {
  props: {
    value: { type: [Number, String], default: null },
    label: { type: String, default: "Score" },
    icon: { type: String, default: null },
    color: { type: String, default: "#0070A8" },
    route: {
      type: Object,
      default: () => ({
        name: "regions",
      }),
    },
  },

  computed: {
    valueAsInt() {
      if (!this.value) return;
      return parseInt(this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.score-bar {
  display: block;
  text-decoration: none;
  transition: 0.48s height cubic-bezier(0.16, 1, 0.3, 1),
    0.48s opacity cubic-bezier(0.16, 1, 0.3, 1);
  padding: 0 10px;
  height: 38px;
  border-bottom: 0;
  cursor: default;
  color: #000000;

  .sidebar-closed & {
    height: 28px;
  }

  &:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }

  .score-bar__label {
    color: $primary;
    font-weight: 500;
  }

  .score-bar__label {
    position: relative;
    left: 44px;
    margin-bottom: -8px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 44px);
  }

  .score-bar__score {
    transition: 0.48s top cubic-bezier(0.16, 1, 0.3, 1);
    position: relative;
    display: inline-block;
    font-size: 24px;
    width: 40px;
    z-index: 1;
    left: -2px;
    top: -1px;
    pointer-events: none;

    .sidebar-closed & {
      top: -10px;
      pointer-events: all;
    }
  }

  .score-bar__bar {
    width: calc(100% - 40px);
    display: inline-block;

    .sidebar-closed & {
      opacity: 0;
      pointer-events: none;
    }
  }

  &:first-child {
    margin-top: 12px;
  }

  &:last-child {
    margin-bottom: 12px;
  }
}
</style>
