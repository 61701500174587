var render = function render(){var _vm=this,_c=_vm._self._c;return _c('cp-card',{staticStyle:{"height":"100%"},attrs:{"collapse-actions-always":"","actions-icon":"mdi-filter","actions-header-text":"Filters","show-actions-activator-label":"","flush-body":"","dense":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":_vm.returnLink}},[_c('v-icon',[_vm._v("mdi-keyboard-return")])],1),_c('div',{staticClass:"page-title"},[(_vm.surveys.instance.name)?_c('h4',[_vm._v(" "+_vm._s(_vm.surveys.instance.name)+" ")]):_c('v-skeleton-loader',{attrs:{"type":"text","height":"22"}}),_c('div',{staticClass:"page-title__area"},[_vm._v(" Location Summary: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$store.state.scores.location.text))])])],1)],1)]},proxy:true},{key:"persistentActions",fn:function(){return [(_vm.questions.omniClients.tableSummary && !_vm.respondents.detailsOpen)?_c('share',{staticStyle:{"margin-top":"-2px"},attrs:{"omni-client":_vm.currentOmniClient,"filename":`Survey Export ${_vm.toMDY()}.xlsx`,"title":"Share Survey Details","endpoint":"intouch/multi_dashboard_export.xlsx","params":{
        filters: _vm.questions.omniClients.tableSummary.filters,
        structures: {
          date_interval:
            _vm.questions.omniClients.tableSummary.structures.date_interval,
          addon_options:
            _vm.questions.omniClients.tableSummary.structures.addon_options,
        },
        scopes: ['questions', 'residents_surveys', 'comments'],
      }}}):_vm._e()]},proxy:true},{key:"actions",fn:function(){return [(_vm.currentOmniClient && _vm.$route.query.tab == 0)?_c('cp-search',{staticClass:"card-header-actions",attrs:{"clearable":"","hide-details":"","dense":"","single-line":"","label":_vm.$store.state.searchLabels[_vm.$route.query.tab]},model:{value:(_vm.questions.localSearch),callback:function ($$v) {_vm.$set(_vm.questions, "localSearch", $$v)},expression:"questions.localSearch"}}):(_vm.currentOmniClient)?_c('cp-search',{staticClass:"card-header-actions",attrs:{"clearable":"","hide-details":"","dense":"","single-line":"","label":_vm.$store.state.searchLabels[_vm.$route.query.tab]},on:{"input":function($event){_vm.currentOmniClient.page.number = 1;
        _vm.currentOmniClient.fetch({ debounce: 250 });}},model:{value:(_vm.currentOmniClient.q_text),callback:function ($$v) {_vm.$set(_vm.currentOmniClient, "q_text", $$v)},expression:"currentOmniClient.q_text"}}):_vm._e(),_c('v-switch',{staticClass:"card-header-actions",attrs:{"label":"Only Low Scores","hide-details":""},model:{value:(_vm.surveys.onlyShowLowScores),callback:function ($$v) {_vm.$set(_vm.surveys, "onlyShowLowScores", $$v)},expression:"surveys.onlyShowLowScores"}}),(_vm.$route.query.tab == 1 && _vm.respondents.omniClients.tableSummary)?_c('v-switch',{staticClass:"mb-0",attrs:{"label":"Display Confidential Results","loading":_vm.currentOmniClient.awaitingResponse},on:{"change":function($event){return _vm.updateResults()}},model:{value:(_vm.respondents.omniClients.tableSummary.filters.confidential_only),callback:function ($$v) {_vm.$set(_vm.respondents.omniClients.tableSummary.filters, "confidential_only", $$v)},expression:"respondents.omniClients.tableSummary.filters.confidential_only"}}):_vm._e(),(
        _vm.surveys.omniClients.itemDetail && _vm.surveys.omniClients.itemDetail.meta
      )?_c('cp-combo-date-filter',{attrs:{"offset-y":"","left":"","start-date":_vm.$store.state.filters.date_start,"end-date":_vm.$store.state.filters.date_end,"preset":_vm.$store.state.filters.date_preset,"loading":_vm.surveys.omniClients.itemDetail &&
          _vm.surveys.omniClients.itemDetail.awaitingResponse},on:{"selectionUpdated":function($event){_vm.$store.commit('setDateFilter', $event);
        _vm.updateResults();}}}):_vm._e()]},proxy:true}])},[(_vm.surveys.omniClients.itemDetail)?_c('survey-details-body',{attrs:{"omni-client-survey":_vm.surveys.omniClients.itemDetail,"omni-client-questions":_vm.questions.omniClients.tableSummary,"omni-client-respondents":_vm.respondents.omniClients.tableSummary,"omni-client-comments":_vm.surveys.omniClients.comments}}):_vm._e(),_c('survey-result-detail-modal',{attrs:{"omni-client":_vm.respondents.omniClients.tableSummary}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }