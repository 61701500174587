<template>
  <cp-card class="bun-row-link" dense flush-body :class="{ belowScore }">
    <div class="bun-row-link__score">
      <h3 class="text-h6 text-truncate">
        {{ item.name }}
        <span v-if="item.name === 'Unassigned'" class="text-capitalize">
          {{ nextDepth }}s</span
        >
        <v-tooltip
          v-if="item.name == 'Unassigned'"
          right
          transition="slide-x-reverse-transition"
          max-width="250"
        >
          <template #activator="{ on }">
            <v-icon v-on="on">
              mdi-alert-circle
            </v-icon>
          </template>
          These are survey responses from residents who are not connected to a
          {{ nextDepth }}.
        </v-tooltip>
      </h3>
      <div v-if="item.surveys_sent > 0" class="text-truncate">
        <v-progress-linear
          :value="item.agreement_importance"
          height="14"
          :color="model.color"
        />
        Score:
        <span class="font-weight-bold mr-6">{{
          item.agreement_importance
        }}</span>
      </div>
      <div v-else class="font-weight-bold mr-6 text-truncate">
        No surveys have been sent yet.
      </div>
    </div>

    <div class="bun-row-link__chart">
      <v-btn
        large
        icon
        :color="trendDirection.color"
        class="mt-1"
        @click="trendModalOpen = true"
      >
        <v-icon x-large :color="trendDirection.color">{{
          trendDirection.icon
        }}</v-icon>
        <div
          v-if="trendDirection.delta != 0"
          class="bun-row-link__chart__delta"
        >
          {{ trendDirection.delta }}
        </div>
      </v-btn>
      <h4 class="text-caption">Trend</h4>
    </div>

    <div class="bun-row-link__responses">
      <table>
        <tr>
          <td>Sent:</td>
          <td>{{ item.surveys_sent }}</td>
        </tr>
        <tr>
          <td>Responded:</td>
          <td>{{ item.surveys_responded || 0 }}</td>
        </tr>
        <tr>
          <td>Response Rate:</td>
          <td>{{ item.response_rate }}%</td>
        </tr>
      </table>
    </div>

    <div class="bun-row-link__navigate">
      <v-btn
        icon
        :to="link"
        color="primary"
        v-if="link && item.name != 'Unassigned'"
        :disabled="!item.surveys_responded || item.surveys_responded <= 0"
      >
        <v-icon x-large>mdi-chevron-right</v-icon>
      </v-btn>
    </div>

    <cp-dialog
      header-background
      :show-model.sync="trendModalOpen"
      max-width="500px"
      fixed-header-footer
      hide-scroll-indicator
    >
      <template #title> {{ model.singularName }} Trend Details</template>

      <template #body>
        <div class="bun-survey-chart">
          <!-- <cp-select
            v-model="$store.state.structures.date_interval"
            :items="[
              { text: 'Month', value: 'month' },
              { text: 'Quarter', value: 'quarter' },
              { text: 'Year', value: 'year' }
            ]"
            @input="omniClient.fetch()"
          /> -->
          <cp-combo-chart
            v-if="trendModalOpen"
            class="bun-survey-chart__chart"
            :height="200"
            :chart-data="chartData"
            :y-min="chartData.yMin"
            :y-max="chartData.yMax"
            :date-interval="$store.state.structures.date_interval"
            :options="{
              elements: {
                line: {
                  spanGaps: true,
                },
                point: {
                  spanGaps: true,
                },
              },
            }"
            show-details
          />

          <div class="bun-survey-chart__legend">
            <div>
              <v-icon :color="model.color" small>mdi-circle</v-icon>
              {{ item.name }}
            </div>
            <div><v-icon color="#685478" small>mdi-circle</v-icon> Company</div>
            <div>
              <v-icon color="#009cde" small>mdi-circle</v-icon> SB Index
            </div>
          </div>
        </div>
      </template>
    </cp-dialog>
  </cp-card>
</template>

<script>
import { nonEmpty } from "@cp/utils/itertees";

export default {
  props: {
    item: { type: Object, default: () => {} },
    model: { type: Object, default: () => {} },
    omniClient: { type: Object, default: () => {} },
  },

  data() {
    return {
      trendModalOpen: false,
    };
  },

  computed: {
    chartIcon() {
      switch (this.$route.name) {
        case "districts":
          return {
            icon: "mdi-map-radius-marker",
            color: "District",
            colorRGB: "121, 86, 68",
          };
        case "regions":
          return {
            icon: "mdi-home-group",
            color: "Region",
            colorRGB: "0, 53, 148",
          };
        case "locations":
          return {
            icon: "mdi-home",
            color: "Location",
            colorRGB: "0, 138, 9",
          };
        case "surveys":
          return {
            icon: "mdi-file-document-edit",
            color: "Survey",
            colorRGB: "230, 126, 34",
          };
      }
    },
    belowScore() {
      return nonEmpty(this.item.agreement_importance)
        ? this.item.agreement_importance < 70
        : false;
    },
    trendDirection() {
      let previous = this.item.trends.item[0];
      let current = this.item.trends.item[this.item.trends.item.length - 1];

      if (previous == current || this.item.trends.item.includes(null)) {
        return { icon: "mdi-minus", color: "#888888", delta: 0 };
      } else if (previous < current) {
        return {
          icon: "mdi-trending-up",
          color: "success",
          delta: current - previous,
        };
      } else {
        return {
          icon: "mdi-trending-down",
          color: "error",
          delta: previous - current,
        };
      }
    },

    chartData() {
      return {
        yMin: this.item.trendies.yMin,
        yMax: this.item.trendies.yMax,
        labels: this.item.trendies.labels,
        datasets: [
          {
            fill: true,
            borderColor: `rgba(${this.chartIcon.colorRGB}, 1)`,
            backgroundColor: `rgba(${this.chartIcon.colorRGB}, .1)`,
            pointBackgroundColor: `rgba(${this.chartIcon.colorRGB}, 1)`,
            borderWidth: 2,
            tension: 0.2,
            order: 2,
            data: this.item.trendies.item,
            label: this.item.name,
          },
          {
            fill: false,
            borderColor: "rgba(104, 84, 120, 1)",
            pointBackgroundColor: "rgba(104, 84, 120, 1)",
            tension: 0.2,
            order: 1,
            data: this.item.trendies.client,
            label: "Company",
          },
          {
            fill: false,
            borderColor: "rgba(0, 156, 222, 1)",
            pointBackgroundColor: "rgba(0, 156, 222, 1)",
            tension: 0.2,
            order: 0,
            borderWidth: 2,
            borderDash: [4, 4],
            data: this.item.trendies.sbx,
            label: "SB Index",
          },
        ],
      };
    },

    link() {
      let params = {};

      if (this.$route.name == "allLocations") {
        params.locationSlug = this.item.slug;

        return {
          name: "surveysAllLocations",
          params: params,
        };
      } else if (this.$route.name == "surveysAllLocations") {
        params.locationSlug = this.$route.params.locationSlug;
        params.surveySlug = this.item.slug;

        return {
          name: "surveyDetailAllLocations",
          params: params,
        };
      }

      switch (this.$route.meta.model) {
        case "districts":
          params.districtSlug = this.item.slug;

          return {
            name: "regions",
            params: params,
          };
        case "regions":
          params.regionSlug = this.item.slug;

          if (this.$store.getters.userAccessLevel == "district") {
            params.districtSlug = this.$route.params.districtSlug;
          }

          return {
            name: "locations",
            params: params,
          };
        case "locations":
          params.locationSlug = this.item.slug;

          if (this.$store.getters.userAccessLevel == "district") {
            params.districtSlug = this.$route.params.districtSlug;
          }

          if (
            ["district", "region"].includes(this.$store.getters.userAccessLevel)
          ) {
            params.regionSlug = this.$route.params.regionSlug;
          }

          return {
            name: "surveys",
            params: params,
          };
        case "surveys":
          params.surveySlug = this.item.slug;

          if (this.$store.getters.userAccessLevel == "district") {
            params.districtSlug = this.$route.params.districtSlug;
          }

          if (
            ["district", "region"].includes(this.$store.getters.userAccessLevel)
          ) {
            params.regionSlug = this.$route.params.regionSlug;
          }

          if (
            ["district", "region", "location"].includes(
              this.$store.getters.userAccessLevel
            )
          ) {
            params.locationSlug = this.$route.params.locationSlug;
          }

          return {
            name: "survey",
            params: params,
          };
        default:
          return null;
      }
    },
    nextDepth() {
      switch (this.$route.meta.model) {
        case "districts":
          return "region";
        case "regions":
          return "location";
        case "locations":
          return "resident";
      }
    },
  },
};
</script>

<style lang="scss">
.bun-survey-chart {
  width: 100%;
  max-width: 750px;
  text-align: center;
  margin: 0 auto;
  padding: 16px;

  .bun-survey-chart__title {
    font-size: 18px;
    font-weight: 500;
    margin-left: 8px;
  }

  .bun-survey-chart__legend {
    text-align: center;
    margin-top: 0;
    text-transform: capitalize;
    > div {
      font-weight: 600;
      display: inline-block;
      margin: 0 4px;
    }
  }
}

.bun-row-link {
  &:not(:last-child) {
    margin-bottom: 18px;
  }

  .v-card__text {
    border-left: 10px solid #f1f1f1;
    font-size: 0;
    max-height: 84px;

    > div {
      display: inline-block;
      vertical-align: middle;
      font-size: 1rem;
      padding: 0 8px;
    }
  }

  &.belowScore {
    .v-card__text {
      border-left: 10px solid $error;
    }
  }

  .bun-row-link__score {
    transition: 0.48s width cubic-bezier(0.16, 1, 0.3, 1);
    width: calc(100% - 320px);
    font-size: 20px;
    font-weight: 500;
    margin-top: -1px;
  }

  .bun-row-link__chart {
    transition: 0.48s width cubic-bezier(0.16, 1, 0.3, 1);
    width: 80px;
    height: 76px;
    top: 3px;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    position: relative;
    text-align: center;

    .bun-row-link__chart__delta {
      width: 20px;
      height: 20px;
      padding: 2px;
      text-align: center;
      font-size: 14px;
      position: absolute;
      top: 0;
      margin: auto;
      right: -16px;
      bottom: 0;
      border-radius: 100%;
      pointer-events: none;
      letter-spacing: 0;
    }
  }

  .bun-row-link__responses {
    width: 185px;

    tr td:first-child {
      text-align: right;
    }

    tr td:last-child {
      text-align: left;
      padding-left: 4px;
      font-weight: 700;
    }
  }

  .bun-row-link__navigate {
    width: 55px;
  }
}

.dialog-content__title {
  text-transform: capitalize;
}

.bun-survey-chart__chart {
  min-height: 200px;
}
</style>
