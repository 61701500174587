<template>
  <div class="mc-responses">
    <div v-if="!total || total <= 0" class="font-weight-bold text-center mt-3">
      No Responses
    </div>

    <template v-else>
      <h5 class="mc-response-header">
        Total Responses: <span class="font-weight-bold">{{ total }}</span>
      </h5>
      <div
        class="mc-response-wrapper"
        v-for="(response, index) in responses"
        :key="index"
      >
        <h5 class="mc-response-text">
          <span class="font-weight-bold mr-2">{{ response.text }}</span>
          {{ response.value }}
        </h5>
        <div
          class="mc-response-bar"
          :style="
            `width: ${percentage(
              response.value
            )}%; background-color: ${getColor(response)};`
          "
        ></div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    item: { type: Object, default: () => {} }
  },

  computed: {
    ...mapState({
      questions: state => state.questions
    }),

    responses() {
      if (!this.item.response_summary) return [];

      return this.item.response_summary[
        this.questions.typeMap[this.item.question_type_id].slug
      ];
    },

    userDefinedResponses() {
      return this.item.response_summary.others;
    },

    max() {
      return this.responses[0].value;
    },

    total() {
      let values = this.responses.map(x => x.value);
      return values.reduce((partialSum, val) => partialSum + val, 0);
    }
  },

  methods: {
    percentage(value) {
      return (value / this.max) * 100;
    },

    getColor(response) {
      let percentage = this.percentage(response.value);
      let amount = 50 * (percentage / 100);
      let final = 240 - Math.round(amount);
      return `rgb(${final}, ${final}, ${final * 1.45})`;
    }
  }
};
</script>

<style lang="scss" scoped>
.mc-responses {
  text-align: left;
  margin-bottom: 12px;

  .mc-response-header {
    position: relative;
    left: 6px;
  }

  .mc-response-wrapper {
    position: relative;
    height: 28px;
    margin-top: 2px;
    margin-bottom: 2px;

    .mc-response-text {
      position: relative;
      top: 6px;
      left: 6px;
      z-index: 2;
    }

    .mc-response-bar {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
    }
  }
}
</style>
