import TempAccess from "@/views/TempAccess";

export default [
  {
    path: "/temp-access",
    name: "tempAccess",
    component: TempAccess,
    meta: {
      model: "temp",
      pageTitle: "Response Details",
      clearTokenUrl() {
        const url = new URL(
          `${window.location.origin}/sign-in?return_to=/temp-access`
        );
        const signInSearch = new URLSearchParams(url.search);
        const currentUrlSearch = new URLSearchParams(window.location.search);
        for (const [key, value] of currentUrlSearch.entries()) {
          signInSearch.append(key, value);
        }
        url.search = signInSearch;
        return url.toString();
      },
    },
  },
];
