<template>
  <div>
    <v-navigation-drawer
      v-model="questions.textEntriesPanelOpen"
      right
      fixed
      hide-overlay
      class="side-drawer"
      width="90%"
    >
      <div
        v-if="questions.omniClients.textEntries"
        class="side-drawer__wrapper"
      >
        <div class="side-drawer__title">
          <h5 class="side-drawer__title-subtitle">
            {{ surveys.instance.name }}
          </h5>
          <h5 class="side-drawer__title-question">
            "{{ questions.instance.body }}"
          </h5>

          <h5 class="side-drawer__count">
            {{ questions.omniClients.textEntries.meta.pagination.total_count }}
            Total Entries
          </h5>

          <paginav
            class="side-drawer__actions"
            :omni-client="questions.omniClients.textEntries"
          />
        </div>

        <v-container class="side-drawer__content">
          <template
            v-if="
              !questions.omniClients.textEntries.awaitingResponse &&
                questions.omniClients.textEntries.data.results.length
            "
          >
            <comment
              v-for="item in questions.omniClients.textEntries.data.results"
              :key="item.id"
              :comment="item"
            />
          </template>

          <div v-else class="text-center pt-4">
            <v-progress-circular
              color="primary"
              indeterminate
              :size="80"
              :width="10"
            />
          </div>
        </v-container>
      </div>
    </v-navigation-drawer>

    <!-- TODO figure out why the default overlay from vuetify doesnt work for the comment drawer -->
    <div
      class="int-overlay"
      :class="{ 'is-visible': questions.textEntriesPanelOpen }"
      @click="questions.textEntriesPanelOpen = false"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      surveys: (state) => state.surveys,
      questions: (state) => state.questions,
    }),
  },
};
</script>

<style lang="scss" scoped>
.side-drawer {
  max-width: 700px;
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 53;

  .side-drawer__wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .cp-modal & {
      width: 100%;
      height: calc(100vh - 60px);
      position: absolute;
      bottom: 0;
    }

    .side-drawer__title {
      background-color: rgba($primary, 0.12);
      padding: 12px;
      flex-grow: 0;
      flex-shrink: 0;

      .side-drawer__title-subtitle {
        color: #555555;
        font-weight: 700;
      }

      .side-drawer__title-question {
        font-size: 18px;
      }
    }

    .side-drawer__count {
      margin-top: 8px;
      float: left;
      font-weight: bold;
    }

    .side-drawer__actions {
      float: right;
      max-width: 200px;
    }

    .side-drawer__content {
      flex-grow: 1;
      flex-shrink: 1;
      overflow-y: auto;
    }
  }
}

// TODO figure out why the default overlay from vuetify doesnt work for the comment drawer
.int-overlay {
  transition: all 0.25s ease-in-out;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  z-index: 51;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;

  &.is-visible {
    opacity: 0.5;
    visibility: visible;
    pointer-events: all;
  }
}
</style>
