<template>
  <cp-card class="header-breadcrumb-nav" dense>
    <v-btn text rounded small class="breadcrumb-item has-divider">
      <v-icon class="mr-1">mdi-home</v-icon>
    </v-btn>

    <v-btn text rounded small class="breadcrumb-item has-divider">
      Group 1
    </v-btn>

    some place / another place / whatever

    <v-btn text rounded small class="breadcrumb-item has-divider">
      Region A
    </v-btn>

    <v-btn text rounded small class="breadcrumb-item has-divider">
      Property Epsilon
    </v-btn>

    <v-btn text rounded small class="breadcrumb-item"
      >Diversity & Inclusion</v-btn
    >
  </cp-card>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.header-breadcrumb-nav {
  &::v-deep .v-card__text {
    position: relative;
    overflow: hidden;
  }

  &::v-deep .v-icon {
    margin-top: -2px;
  }

  .breadcrumb-item {
    display: inline-block;
    position: relative;
    margin-left: 24px;
    text-transform: uppercase;

    &.has-divider:after {
      display: block;
      content: "";
      width: 50px;
      height: 50px;
      border-right: 3px solid #bbbbbb;
      border-top: 3px solid #bbbbbb;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -10px;
      margin: auto;
      transform: rotate(45deg);
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      pointer-events: none;
    }
  }
}
</style>
