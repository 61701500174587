var render = function render(){var _vm=this,_c=_vm._self._c;return _c('cp-fullscreen-modal',{attrs:{"show":_vm.surveys.instanceDetailsOpen,"title":"Survey Details","back-text":"Close","back-icon":"close","persistent":false},on:{"update:show":function($event){return _vm.$set(_vm.surveys, "instanceDetailsOpen", $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(
        _vm.questions.omniClients.tableSummary &&
          !_vm.omniClientRespondents.detailsOpen
      )?_c('share',{staticStyle:{"margin-top":"-2px"},attrs:{"color":"dark","omni-client":_vm.currentOmniClient,"filename":`Survey Export ${_vm.toMDY()}.xlsx`,"title":"Share Survey Details","endpoint":"intouch/multi_dashboard_export.xlsx","params":{
        filters: _vm.questions.omniClients.tableSummary.filters,
        structures: {
          date_interval:
            _vm.questions.omniClients.tableSummary.structures.date_interval,
          addon_options:
            _vm.questions.omniClients.tableSummary.structures.addon_options,
        },
        scopes: ['questions', 'residents_surveys', 'comments'],
      }}}):_vm._e()]},proxy:true}])},[_c('comment-drawer'),_c('cp-card',{attrs:{"flush-body":"","collapse-actions-always":"","filter-count":_vm.filterCount,"actions-icon":"mdi-filter","actions-header-text":"Survey Filters","show-actions-activator-label":""},scopedSlots:_vm._u([(_vm.surveys.instance)?{key:"title",fn:function(){return [_c('div',{staticClass:"title-text-wrapper"},[_vm._v(" "+_vm._s(_vm.surveys.instance.name)+" "),_c('h5',{domProps:{"innerHTML":_vm._s(_vm.regionFilterText)}})])]},proxy:true}:null,(_vm.omniClientSurvey)?{key:"actions",fn:function(){return [(_vm.currentOmniClient && _vm.currentTab == 0)?_c('cp-search',{staticClass:"mr-5",attrs:{"clearable":"","hide-details":"","dense":"","single-line":"","label":_vm.$store.state.searchLabels[_vm.currentTab]},model:{value:(_vm.questions.localSearch),callback:function ($$v) {_vm.$set(_vm.questions, "localSearch", $$v)},expression:"questions.localSearch"}}):(_vm.currentOmniClient)?_c('cp-search',{staticClass:"mr-5",attrs:{"clearable":"","hide-details":"","dense":"","single-line":"","label":_vm.$store.state.searchLabels[_vm.currentTab]},on:{"input":function($event){_vm.currentOmniClient.page.number = 1;
          _vm.currentOmniClient.fetch({ debounce: 250 });}},model:{value:(_vm.currentOmniClient.q_text),callback:function ($$v) {_vm.$set(_vm.currentOmniClient, "q_text", $$v)},expression:"currentOmniClient.q_text"}}):_vm._e(),(_vm.omniClientSurvey.meta.filters.regions)?_c('cp-select',{staticClass:"card-header-actions",attrs:{"items":_vm.omniClientSurvey.meta.filters.regions.values,"clearable":"","multiple":"","label":"Regions","dense":"","hide-details":"","loading":_vm.omniClientQuestions && _vm.omniClientQuestions.awaitingResponse},on:{"input":function($event){return _vm.omniClientSurvey.fetch()}},model:{value:(_vm.omniClientSurvey.filters.regions),callback:function ($$v) {_vm.$set(_vm.omniClientSurvey.filters, "regions", $$v)},expression:"omniClientSurvey.filters.regions"}}):_vm._e(),(_vm.omniClientSurvey.meta.filters.locations)?_c('cp-select',{staticClass:"card-header-actions",attrs:{"items":_vm.omniClientSurvey.meta.filters.locations.values,"clearable":"","multiple":"","label":"Locations","dense":"","hide-details":"","loading":_vm.omniClientQuestions && _vm.omniClientQuestions.awaitingResponse},on:{"input":function($event){return _vm.omniClientSurvey.fetch()}},model:{value:(_vm.omniClientSurvey.filters.locations),callback:function ($$v) {_vm.$set(_vm.omniClientSurvey.filters, "locations", $$v)},expression:"omniClientSurvey.filters.locations"}}):_vm._e(),(_vm.omniClientSurvey)?_c('cp-combo-date-filter',{attrs:{"offset-y":"","left":"","start-date":_vm.$store.state.filters.date_start,"end-date":_vm.$store.state.filters.date_end,"preset":_vm.$store.state.filters.date_preset,"loading":_vm.omniClientQuestions && _vm.omniClientQuestions.awaitingResponse,"activator-props":{
          class: 'mb-3',
        }},on:{"selectionUpdated":function($event){_vm.$store.commit('setDateFilter', $event);
          _vm.omniClientSurvey.fetch();}}}):_vm._e(),_c('v-btn',{staticClass:"mb-1",attrs:{"rounded":"","color":"primary","small":""},on:{"click":function($event){return _vm.$store.dispatch('groups/openReportingGroupsFilterDialog')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-filter")]),_vm._v(" Filter by Reporting Groups ")],1),_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Only Show Low Scores","hide-details":""},model:{value:(_vm.surveys.onlyShowLowScores),callback:function ($$v) {_vm.$set(_vm.surveys, "onlyShowLowScores", $$v)},expression:"surveys.onlyShowLowScores"}})]},proxy:true}:null],null,true)},[_c('internal-testing-link'),(_vm.$route.name != 'surveys')?_c('active-groups-banner',{attrs:{"omni-client":_vm.omniClientSurvey}}):_vm._e(),(_vm.omniClientSurvey && !_vm.omniClientSurvey.awaitingResponse)?_c('survey-details-body',{attrs:{"omni-client-survey":_vm.omniClientSurvey,"omni-client-questions":_vm.omniClientQuestions,"omni-client-respondents":_vm.omniClientRespondents,"omni-client-comments":_vm.omniClientComments},on:{"tab-updated":function($event){_vm.currentTab = $event}}}):_c('div',{staticClass:"text-center pa-4"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":80,"width":10}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }