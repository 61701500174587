export default {
  collection: [
    {
      id: 1,
      name: "Southeast",
      slug: "region-one",
      score: 85,
      reporting_groups: ["pets_allowed", "stu_housing", "new_ren"],
    },
    {
      id: 2,
      name: "Region Two",
      slug: "region-two",
      score: 78,
      reporting_groups: ["pets_allowed", "mil_housing"],
    },
    {
      id: 3,
      name: "Upstate",
      slug: "region-three",
      score: 38,
      reporting_groups: ["cov_housing"],
    },
    {
      id: 4,
      name: "Region Four",
      slug: "region-four",
      score: 52,
      reporting_groups: ["pets_not_allowed", "cov_housing", "new_ren"],
    },
    {
      id: 5,
      name: "Midwest",
      slug: "region-midwest",
      score: 27,
      reporting_groups: ["pets_not_allowed", "stu_housing", "new_ren"],
    },
  ],

  instance: {
    id: 3,
    name: "Upstate",
    slug: "region-three",
    score: 38,
    contact: {
      name: "Rebecca Bachman",
      email: "rebecca@swiftbunny.com",
    },
  },
};
