import groups from "./groups.js";
import regions from "./regions.js";
import locations from "./locations.js";
import surveys from "./surveys.js";
import respondents from "./respondents.js";

export default {
  groups: groups,
  regions: regions,
  locations: locations,
  surveys: surveys,
  respondents: respondents
};
