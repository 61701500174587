<template>
  <div>
    <cp-dialog
      header-background
      :show-model.sync="$store.state.shareOpen"
      max-width="500px"
      @modalClosed="resetShare"
      fixed-header-footer
      hide-scroll-indicator
    >
      <template #title>{{ title }}</template>

      <template #body>
        <v-sheet class="px-2 py-1 mb-2" color="#666666" rounded dense>
          <h4 class="font-weight-bold white--text">Share Link</h4>
        </v-sheet>
        <v-banner color="#f1f1f1" dense rounded single-line class="mt-2">
          {{ shareURL }}
          <v-btn
            class="share-copy-button font-weight-bold"
            text
            rounded
            color="primary"
            @click="copyLink"
          >
            <v-icon small>mdi-content-copy</v-icon>Copy Link
          </v-btn>
        </v-banner>

        <span
          class="caption font-italic font-weight-bold"
          v-if="shareURL.includes('temp_token')"
        >
          This page will be viewable by anyone with the link!
        </span>
        <span class="caption font-italic font-weight-bold" v-else>
          Recipients will need to be able to log into the system to view the
          link
        </span>

        <div
          v-show="linkCopied"
          class="link-copied-success-text font-weight-bold success--text mr-4 mt-1"
        >
          Link Copied!
        </div>

        <v-divider class="my-6 mt-1" />

        <template>
          <v-sheet class="px-2 py-1 mb-2" color="#666666" rounded dense>
            <h4 class="font-weight-bold white--text">Download Options</h4>
          </v-sheet>
          <v-btn
            rounded
            @click="downloadFile"
            color="primary"
            text
            :loading="
              respondents.detailsBusy ||
                (omniClient && omniClient.awaitingResponse)
            "
          >
            <v-icon class="mr-2">mdi-download</v-icon>Excel File
          </v-btn>

          <v-divider class="mt-2 mb-6" />
        </template>

        <v-sheet class="px-2 py-1 mb-2" color="#666666" rounded dense>
          <h4 class="font-weight-bold white--text">
            Share Excel via Email <span class="error--text">*</span>
          </h4>
        </v-sheet>
        <v-autocomplete
          :error="inError"
          v-model="selectedUsers"
          :search-input.sync="userSearchText"
          label="Recipients"
          class="share-autocomplete"
          placeholder="Type to Search Company"
          chips
          deletable-chips
          hide-selected
          multiple
          dense
          single-line
          auto-select-first
          cache-items
          :items="users"
          item-text="full_name"
          item-value="email"
          :loading="searching"
          @input="clearEnteredText"
        >
          <template #item="{item}">
            <v-row class="py-1">
              <v-col cols="6">
                <h4 class="font-weight-bold">{{ item.full_name }}</h4>
                <h4>{{ item.email }}</h4>
              </v-col>

              <v-col cols="6">
                <h4>
                  Position:
                  <span class="font-weight-bold">{{ item.position }}</span>
                </h4>
                <h4>
                  Location:
                  <span class="font-weight-bold">{{ item.location }}</span>
                </h4>
              </v-col>
            </v-row>
          </template>
        </v-autocomplete>

        <v-textarea
          v-model="message"
          label="Email Message (optional)"
          outlined
          hide-details
          placeholder="Message to be included in the email"
        />
      </template>

      <template #actions>
        <v-spacer />
        <v-btn
          color="primary"
          @click="sendEmail"
          :disabled="selectedUsers.length <= 0"
          :loading="
            respondents.detailsBusy ||
              (omniClient && omniClient.awaitingResponse)
          "
        >
          Send Email <v-icon small class="ml-1">mdi-send</v-icon>
        </v-btn>
      </template>
    </cp-dialog>

    <slot
      :on="{
        click() {
          $store.state.shareOpen = true;
        },
      }"
    >
      <v-btn
        :disabled="disabled"
        :color="color"
        small
        rounded
        v-bind="$attrs"
        @click="$store.state.shareOpen = true"
      >
        <span v-if="!icon" class="mr-2">Share</span><v-icon>mdi-share</v-icon>
      </v-btn>
    </slot>
  </div>
</template>

<script>
import { Api } from "@cp/lib";
import { mapState } from "vuex";

const mApi = new Api(`${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1`);

export default {
  props: {
    icon: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    color: { type: String, default: "primary" },
    title: { type: String, default: "Share" },
    endpoint: { type: String, default: "intouch/dashboard.xlsx" },
    filename: { type: String, default: "export.xlsx" },
    params: { type: Object, default: () => {} },
    omniClient: { type: Object, default: () => {} },
  },

  data() {
    return {
      linkCopied: false,
      userSearchText: null,
      debounceSearch: null,
      selectedUsers: [],
      users: [],
      message: null,
      searching: false,
      inError: false,
    };
  },

  computed: {
    ...mapState({
      respondents: state => state.respondents,
    }),

    shareURL() {
      let url = process.env.VUE_APP_REDSTONE_UI_PATH + this.$route.fullPath;
      let tempToken = this.$store.state.respondents.instance.temp_token;

      if (tempToken != null) {
        url += `${url.includes("?") ? "&" : "?"}temp_token=${tempToken}`;
      }

      return url;
    },
  },

  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.shareURL);
      this.linkCopied = true;
    },

    searchUsers(text) {
      this.searching = true;
      return mApi
        .authorize()
        .get("users/autocomplete", {
          page: {
            size: 50,
          },
          q_text: text,
          filters: [{ general: "active" }],
        })
        .then(({ data }) => {
          this.users = data.map(x => ({
            id: x.id,
            ...x.attributes,
          }));
        })
        .finally(() => {
          this.searching = false;
        });
    },

    sendEmail() {
      if (this.selectedUsers.length <= 0) {
        this.inError = true;
        return;
      }

      let promise;
      if (this.$route.query.showResidentSurvey != null) {
        promise = this.$store.dispatch("respondents/shareOverEmail", {
          residentsUserID: this.$route.query.showResidentSurvey,
          emails: this.selectedUsers,
          message: this.message,
          link: this.shareURL,
        });
      } else {
        promise = this.omniClient.shareOverEmail({
          emails: this.selectedUsers,
          message: this.message,
          params: this.params,
          endpoint: this.endpoint,
          link: this.shareURL,
        });
      }

      promise
        .then(() => {
          this.$CpEvent.$emit("snackAlert", {
            message: "Email Sent!",
            color: "success",
            timeout: 3000,
          });
        })
        .catch(() => {
          this.$CpEvent.$emit("snackAlert", {
            message: "There was a problem sending the email",
            color: "error",
            timeout: 3000,
          });
        })
        .finally(() => {
          this.$store.state.shareOpen = false;
        });
    },

    resetShare() {
      this.linkCopied = false;
      this.userSearchText = null;
      this.debounceSearch = null;
      this.selectedUsers = [];
      this.users = [];
      this.searching = false;
      this.message = null;
      this.inError = false;
    },

    downloadFile() {
      if (this.$route.query.showResidentSurvey != null) {
        this.$store.dispatch(
          "respondents/download",
          this.$route.query.showResidentSurvey
        );
      } else {
        this.omniClient.download({
          filename: this.filename,
          params: this.params,
          endpoint: this.endpoint,
        });
      }
    },

    clearEnteredText() {
      this.userSearchText = null;
    },
  },

  watch: {
    userSearchText(val) {
      this.inError = false;
      clearTimeout(this.debounceSearch);
      this.debounceSearch = setTimeout(() => {
        this.searchUsers(val);
      }, 250);
    },
  },
};
</script>

<style lang="scss">
.v-menu__content.v-autocomplete__content {
  max-height: 240px !important;
}

.share-copy-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #f1f1f1;
}

.link-copied-success-text {
  float: right;
}
</style>
