<template>
  <div>
    <cp-h1>
      Survey Overview
      <template #right>
        <v-menu
          offset-y
          bottom
          left
          origin="top right"
          :close-on-content-click="false"
        >
          <template #activator="{on, attrs}">
            <v-btn outlined color="primary" v-on="on" v-bind="attrs">
              <v-icon>mdi-filter</v-icon>
              Survey Sort and Filter
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="showLowestFirst = !showLowestFirst">
              <v-list-item-icon>
                <v-icon>{{ sort.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Sorting by {{ sort.text }} First
              </v-list-item-content>
            </v-list-item>

            <cp-combo-date-filter
              v-if="surveys.omniClients.indexCollection"
              offset-y
              left
              :start-date="$store.state.filters.date_start"
              :end-date="$store.state.filters.date_end"
              :preset="$store.state.filters.date_preset"
              :loading="surveys.omniClients.indexCollection.awaitingResponse"
              @selectionUpdated="
                $store.commit('setDateFilter', $event);
                filtersChanged();
              "
            >
              <template #default="{toggleMenu, selectionDisplay}">
                <v-list-item v-on="toggleMenu">
                  <v-list-item-icon>
                    <v-icon>mdi-calendar-month-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ selectionDisplay }}
                  </v-list-item-content>
                </v-list-item>
              </template>
            </cp-combo-date-filter>

            <v-list-item @click="openReportingGroupsFilterDialog">
              <v-list-item-icon>
                <v-icon>mdi-filter-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Filter by Reporting Groups
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </cp-h1>
    <cp-card
      dense
      flush-body
      collapse-actions-always
      :filter-count="filterCount"
      actions-icon="mdi-filter"
      actions-header-text="Survey Sort and Filter"
      show-actions-activator-label
    >
      <active-groups-banner
        :omni-client="surveys.omniClients.indexCollection"
        @change="filtersChanged"
      />

      <div
        v-if="
          surveys.omniClients.indexCollection &&
            surveys.omniClients.indexCollection.awaitingResponse
        "
        class="text-center pa-4"
      >
        <v-progress-circular
          color="primary"
          indeterminate
          :size="80"
          :width="10"
        />
      </div>

      <template v-else>
        <v-container
          v-if="
            surveys.omniClients.indexCollection &&
              surveys.omniClients.indexCollection.data &&
              surveys.omniClients.indexCollection.data.results.length > 0
          "
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
              v-for="survey in surveys.omniClients.indexCollection.data.results"
              :key="survey.m_id"
            >
              <summary-card
                :survey="survey"
                :show-lowest-first="showLowestFirst"
              />
            </v-col>
          </v-row>
        </v-container>

        <v-alert v-else dense text icon="mdi-information">
          No results found for the given filters.
        </v-alert>
      </template>
    </cp-card>

    <survey-details-modal
      :omni-client-survey="surveys.omniClients.itemDetail"
      :omni-client-questions="questions.omniClients.tableSummary"
      :omni-client-respondents="respondents.omniClients.tableSummary"
      :omni-client-comments="surveys.omniClients.comments"
    />
    <survey-result-detail-modal
      :omni-client="respondents.omniClients.tableSummary"
    />

    <groups-filter :omni-client="omniClientToFilter" @change="filtersChanged" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      showLowestFirst: false,
      updateTimeout: null,
    };
  },

  computed: {
    ...mapState({
      surveys: state => state.surveys,
      questions: state => state.questions,
      respondents: state => state.respondents,
    }),

    omniClientToFilter() {
      switch (this.$route.name) {
        case "surveyIndex":
          return this.surveys.omniClients.indexCollection;
        case "surveyDetail":
          return this.surveys.omniClients.itemDetail;
      }
    },

    filterCount() {
      if (!this.surveys.omniClients.indexCollection) return 0;

      if (!this.surveys.omniClients.indexCollection.filters) return 0;

      let count = 0;

      if (this.surveys.omniClients.indexCollection.q_text) {
        count++;
      }

      count += this.$store.state.groups.selected.length > 0 ? 1 : 0;

      return count;
    },
    sort() {
      return this.showLowestFirst
        ? {
            text: "Lowest Scores",
            icon: "mdi-sort-numeric-ascending",
          }
        : {
            text: "Highest Scores",
            icon: "mdi-sort-numeric-descending",
          };
    },
  },

  methods: {
    ...mapActions("groups", ["openReportingGroupsFilterDialog"]),
    filtersChanged() {
      if (this.$route.name != "surveyIndex") return;

      if (this.surveys.omniClients.indexCollection.data.results.length <= 0) {
        this.$store
          .dispatch("surveys/initOmniClient", {
            namespace: "indexCollection",
            structures: {
              date_interval: this.$store.state.structures.date_interval,
              scope: ["surveys"],
              addon_options: ["defaults", "swift_bunny_index", "company_index"],
            },
          })
          .then(() => {
            this.getResultsForEachSurvey();
          });
      } else {
        this.getResultsForEachSurvey();
      }
    },

    getResultsForEachSurvey() {
      this.surveys.omniClients.indexCollection.data.results.forEach(
        ({ m_id }) => {
          if (
            this.$store.getters.userAccessLevel == "district" &&
            this.surveys.omniClients[`districts_${m_id}`]
          )
            this.surveys.omniClients[`districts_${m_id}`].fetch();

          if (
            ["district", "region"].includes(
              this.$store.getters.userAccessLevel
            ) &&
            this.surveys.omniClients[`regions_${m_id}`]
          )
            this.surveys.omniClients[`regions_${m_id}`].fetch();

          if (this.surveys.omniClients[`locations_${m_id}`])
            this.surveys.omniClients[`locations_${m_id}`].fetch();
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.card-header-actions {
  &::v-deep .v-select__selections {
    flex-wrap: nowrap;
  }
}
</style>
