<template>
  <cp-card
    style="height: 100%"
    collapse-actions-always
    actions-icon="mdi-filter"
    actions-header-text="Filters"
    show-actions-activator-label
    flush-body
    dense
  >
    <template #title>
      <div class="d-flex align-center">
        <v-btn class="mr-2" icon :to="returnLink">
          <v-icon>mdi-keyboard-return</v-icon>
        </v-btn>
        <div class="page-title">
          <h4 v-if="surveys.instance.name">
            {{ surveys.instance.name }}
          </h4>
          <v-skeleton-loader v-else type="text" height="22" />
          <div class="page-title__area">
            Location Summary:
            <span class="font-weight-bold">{{
              $store.state.scores.location.text
            }}</span>
          </div>
        </div>
      </div>
    </template>

    <template #persistentActions>
      <share
        v-if="questions.omniClients.tableSummary && !respondents.detailsOpen"
        style="margin-top: -2px;"
        :omni-client="currentOmniClient"
        :filename="`Survey Export ${toMDY()}.xlsx`"
        title="Share Survey Details"
        endpoint="intouch/multi_dashboard_export.xlsx"
        :params="{
          filters: questions.omniClients.tableSummary.filters,
          structures: {
            date_interval:
              questions.omniClients.tableSummary.structures.date_interval,
            addon_options:
              questions.omniClients.tableSummary.structures.addon_options,
          },
          scopes: ['questions', 'residents_surveys', 'comments'],
        }"
      />
    </template>

    <template #actions>
      <cp-search
        v-if="currentOmniClient && $route.query.tab == 0"
        v-model="questions.localSearch"
        clearable
        hide-details
        dense
        single-line
        :label="$store.state.searchLabels[$route.query.tab]"
        class="card-header-actions"
      />

      <cp-search
        v-else-if="currentOmniClient"
        v-model="currentOmniClient.q_text"
        clearable
        hide-details
        dense
        single-line
        :label="$store.state.searchLabels[$route.query.tab]"
        class="card-header-actions"
        @input="
          currentOmniClient.page.number = 1;
          currentOmniClient.fetch({ debounce: 250 });
        "
      />

      <v-switch
        v-model="surveys.onlyShowLowScores"
        label="Only Low Scores"
        class="card-header-actions"
        hide-details
      />

      <v-switch
        v-if="$route.query.tab == 1 && respondents.omniClients.tableSummary"
        v-model="respondents.omniClients.tableSummary.filters.confidential_only"
        label="Display Confidential Results"
        class="mb-0"
        @change="updateResults()"
        :loading="currentOmniClient.awaitingResponse"
      />

      <cp-combo-date-filter
        v-if="
          surveys.omniClients.itemDetail && surveys.omniClients.itemDetail.meta
        "
        offset-y
        left
        :start-date="$store.state.filters.date_start"
        :end-date="$store.state.filters.date_end"
        :preset="$store.state.filters.date_preset"
        :loading="
          surveys.omniClients.itemDetail &&
            surveys.omniClients.itemDetail.awaitingResponse
        "
        @selectionUpdated="
          $store.commit('setDateFilter', $event);
          updateResults();
        "
      />
    </template>

    <survey-details-body
      v-if="surveys.omniClients.itemDetail"
      :omni-client-survey="surveys.omniClients.itemDetail"
      :omni-client-questions="questions.omniClients.tableSummary"
      :omni-client-respondents="respondents.omniClients.tableSummary"
      :omni-client-comments="surveys.omniClients.comments"
    />
    <survey-result-detail-modal
      :omni-client="respondents.omniClients.tableSummary"
    />
  </cp-card>
</template>

<script>
import { mapState } from "vuex";
import { toMDY } from "@cp/utils/dateUtils";

export default {
  computed: {
    ...mapState({
      surveys: state => state.surveys,
      questions: state => state.questions,
      respondents: state => state.respondents,
    }),

    currentOmniClient() {
      switch (parseInt(this.$route.query.tab)) {
        case 0:
          return this.questions.omniClients.tableSummary;
        case 1:
          return this.respondents.omniClients.tableSummary;
        case 2:
          return this.surveys.omniClients.comments;
        default:
          return {};
      }
    },

    returnLink() {
      if (this.$route.name == "surveyDetailAllLocations") {
        return {
          name: "surveysAllLocations",
          locationSlug: this.$route.params.locationSlug,
        };
      }

      return {
        name: "surveys",
        regionSlug: this.$route.params.regionSlug,
        locationSlug: this.$route.params.locationSlug,
        surveySlug: this.$route.params.surveySlug,
      };
    },
  },

  methods: {
    toMDY,
    updateResults() {
      this.surveys.omniClients.itemDetail.fetch();
      this.questions.omniClients.tableSummary.fetch();
      this.respondents.omniClients.tableSummary.fetch();
      this.surveys.omniClients.comments.fetch();
    },
  },

  mounted() {
    if (this.$route.query.showResidentSurvey) {
      this.$store.dispatch(
        "respondents/openDetailsModal",
        this.$route.query.showResidentSurvey
      );
    }
  },
};
</script>

<style lang="scss">
.tabs-header {
  .v-tabs-bar {
    height: 36px;
  }
}

.expandable-table tr:not(.v-data-table__expanded__content) {
  cursor: pointer;
}

.question-comment-activator {
  .question-comment-count {
    position: absolute;
    top: 48%;
    left: 9.7%;
    letter-spacing: normal;
    font-size: 12px;
    transform: translate(-50%, -50%);
  }
}

.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: inset 0 3px 5px rgba(#000000, 0.15);
  background-color: #f7f7f7;
}

.thin-column {
  width: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.user-survey-breakdown {
  table-layout: fixed;
  width: calc(100% - 75px);
  margin-left: auto;

  td {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  tr:not(:last-child) td {
    border-bottom: 1px solid #cccccc;
  }
}

.page-title {
  line-height: 1.1;
  padding: 12px 0;

  .page-title__area {
    text-transform: capitalize;
    margin-top: 4px;
    font-size: 16px;
    font-weight: 400;
  }
}

.card-header-actions {
  &::v-deep .v-select__selections {
    flex-wrap: nowrap;
  }
}
</style>
