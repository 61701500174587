<template>
  <cp-card flush-body dense class="mb-3">
    <template #title>
      <v-btn
        class="mr-2"
        small
        icon
        :to="{
          name: 'queue'
        }"
      >
        <v-icon>mdi-keyboard-return</v-icon>
      </v-btn>
      Notification Details
    </template>

    <v-expansion-panels v-if="notifications.instanceReady">
      <v-expansion-panel
        v-for="message in notifications.instance"
        :key="message.id"
      >
        <v-expansion-panel-header>
          <div>
            <h5 class="text-subtitle font-weight-bold">
              {{ message.subject }}
            </h5>
            <span class="text-subtitle-1">Sent: {{ message.send_date }}</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <v-tabs v-model="message.tab" background-color="#f7f7f7" class="mb-4">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab>Queue Info</v-tab>
            <v-tab>Email Info</v-tab>
          </v-tabs>

          <v-tabs-items v-model="message.tab">
            <v-tab-item>
              <h6
                v-for="[field, value] in Object.entries(message)"
                v-if="!['tab', 'emailDetails'].includes(field)"
              >
                {{ field }}: <span class="font-weight-bold">{{ value }}</span>
              </h6>
            </v-tab-item>

            <v-tab-item>
              <template v-if="message.emailDetails">
                <h6
                  v-for="[field, value] in Object.entries(
                    message.emailDetails[0]
                  )"
                >
                  {{ field }}:
                  <span class="font-weight-bold">{{ value }}</span>
                </h6>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="text-center" v-else>
      <v-progress-circular
        indeterminate
        class="ma-6"
        size="50"
        color="primary"
      />
    </div>
  </cp-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      tab: 0
    };
  },

  computed: {
    ...mapState({
      notifications: state => state.notifications
    })
  }
};
</script>

<style lang="scss" scoped>
.details-row:not(:last-child) {
  border-bottom: 1px solid #e6e6e6;
}
</style>
