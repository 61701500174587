<template>
  <div class="int-paginav">
    <v-btn
      class="mr-2"
      icon
      small
      color="primary"
      :disabled="omniClient.awaitingResponse || omniClient.onFirstPage()"
      @click="
        omniClient.previousPage();
        $emit('pageChange', $event);
      "
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>

    <v-select
      label="Page"
      hide-details
      dense
      single-line
      solo
      :items="omniClient.paginationOptions()"
      :loading="omniClient.awaitingResponse"
      :value="omniClient.meta.pagination.current"
      @input="
        omniClient.goToPage($event);
        $emit('pageChange', $event);
      "
    />

    <v-btn
      class="ml-2"
      icon
      small
      color="primary"
      :disabled="omniClient.awaitingResponse || omniClient.onLastPage()"
      @click="
        omniClient.nextPage();
        $emit('pageChange', $event);
      "
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    omniClient: { type: Object, default: () => {} },
  },
};
</script>

<style lang="scss" scoped>
.int-paginav {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
