<template>
  <v-tooltip right transition="slide-x-reverse-transition" max-width="250">
    <template #activator="{ on }">
      <v-icon :color="item.color" v-on="on">
        {{ item.icon }}
      </v-icon>
    </template>
    {{ item.tooltip }}
  </v-tooltip>
</template>

<script>
export default {
  props: {
    likelihood: { type: [Number, String], default: null }
  },

  computed: {
    item() {
      let data = {};
      let value = parseInt(this.likelihood);

      if (value >= 4) {
        data.icon = "mdi-emoticon-excited";
        data.color = "success";
        data.tooltip = "Likely to Renew";
      } else if (value <= 2) {
        data.icon = "mdi-emoticon-sad";
        data.color = "error";
        data.tooltip = "Unlikely to Renew";
      } else {
        data.icon = "mdi-emoticon-neutral";
        data.color = null;
        data.tooltip = "Neutral to Renew";
      }

      return data;
    }
  }
};
</script>
