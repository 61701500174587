export default {
  collection: [
    { id: 1, text: "Conventional Housing", value: "cov_housing" },
    { id: 2, text: "Student Housing", value: "stu_housing" },
    { id: 3, text: "Military Housing", value: "mil_housing" },
    { id: 4, text: "Pets Allowed", value: "pets_allowed" },
    { id: 5, text: "Pets Not Allowed", value: "pets_not_allowed" },
    { id: 6, text: "Newly Renovated", value: "new_ren" },
  ],

  instance: {},
};
