<template>
  <cp-card
    dense
    flush-body
    title-color="#f1f1f1"
    class="survey-summary-card"
    style="height: 100%;"
  >
    <template #title>
      <h5 class="summary-card-title">
        {{ survey.name }}
      </h5>
    </template>

    <template #actions>
      <v-btn
        small
        rounded
        text
        color="primary"
        @click="
          $router.replace({
            name: 'surveyDetail',
            params: {
              surveySlug: survey.slug
            }
          })
        "
      >
        Details <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </template>

    <v-tabs class="tabs-header" v-model="tab" grow>
      <v-tab v-if="$store.getters.userAccessLevel == 'district'">
        Districts
      </v-tab>
      <v-tab
        v-if="['district', 'region'].includes($store.getters.userAccessLevel)"
      >
        Regions
      </v-tab>
      <v-tab>
        Locations
      </v-tab>
    </v-tabs>

    <div
      v-if="
        !surveys.omniClients[`regions_${survey.m_id}`] ||
          surveys.omniClients[`regions_${survey.m_id}`].awaitingResponse
      "
      class="text-center pa-4"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        :size="80"
        :width="10"
      />
    </div>

    <div v-else class="survey-summary-tabs">
      <div
        v-if="survey.client_index"
        class="survey-summary-score-marker"
        :style="
          `left: calc(${survey.client_index.agreement_importance}% - 8px)`
        "
      >
        <tooltip-icon
          :tooltip="`Company Score ${survey.client_index.agreement_importance}`"
          icon="mdi-office-building"
          :icon-attrs="{ small: true, class: 'company-color' }"
        />
      </div>

      <div
        v-if="survey.sb_index"
        class="survey-summary-score-marker sb-score-marker"
        :style="`left: calc(${survey.sb_index.agreement_importance}% - 8px)`"
      >
        <tooltip-icon
          :tooltip="`SB Index ${survey.sb_index.agreement_importance}`"
          icon="$vuetify.icons.SwiftBunnyLogo"
          :icon-attrs="{ small: true, class: 'sbx-color' }"
        />
      </div>

      <div class="survey-summary-score-axis">
        <span
          v-for="n in 11"
          :style="`left: ${(n - 1) * 10}%`"
          :class="{ 'error--text': n - 1 < 7 }"
        >
          {{ (n - 1) * 10 }}
        </span>
      </div>

      <v-tabs-items v-model="tab">
        <v-tab-item v-if="$store.getters.userAccessLevel == 'district'">
          <summary-card-body
            v-if="surveys.omniClients[`districts_${survey.m_id}`]"
            :omni-client="surveys.omniClients[`districts_${survey.m_id}`]"
            :show-lowest-first="showLowestFirst"
            :survey="survey"
            color="#795644"
          />
        </v-tab-item>

        <v-tab-item
          v-if="['district', 'region'].includes($store.getters.userAccessLevel)"
        >
          <summary-card-body
            v-if="surveys.omniClients[`regions_${survey.m_id}`]"
            :omni-client="surveys.omniClients[`regions_${survey.m_id}`]"
            :show-lowest-first="showLowestFirst"
            :survey="survey"
            color="#003594"
          />
        </v-tab-item>

        <v-tab-item>
          <summary-card-body
            v-if="surveys.omniClients[`locations_${survey.m_id}`]"
            :omni-client="surveys.omniClients[`locations_${survey.m_id}`]"
            :show-lowest-first="showLowestFirst"
            :survey="survey"
            color="#008a09"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </cp-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    survey: { type: Object, default: () => {} },
    showLowestFirst: { type: Boolean, default: false }
  },

  data() {
    return {
      tab: 0
    };
  },

  computed: {
    ...mapState({
      surveys: state => state.surveys
    })
  }
};
</script>

<style lang="scss" scoped>
.survey-summary-card {
  &::v-deep .cp-card-actions-wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.summary-card-title {
  font-size: 18px;
  color: #555555;
}

.survey-summary-tabs {
  width: calc(100% - 16px);
  height: 216px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 44px;
}

.survey-summary-score-axis {
  width: 100%;
  position: absolute;
  bottom: 50px;
  right: 0;
  border-top: 2px solid #aaaaaa;

  span {
    width: 32px;
    height: 18px;
    padding-left: 2px;
    border-left: 1px solid #aaaaaa;
    position: absolute;
    top: 0;

    &:first-child {
      border-left: 0;
    }

    &:last-child {
      margin-left: -32px;
      border-left: 0;
    }
  }
}

.survey-summary-score-marker {
  position: absolute;
  top: 10px;
  left: 0;
  height: calc(100% - 64px);
  z-index: 2;

  &:after {
    content: "";
    width: 1px;
    height: calc(100% - 28px);
    border-left: 2px dashed $company-color;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2px;
    margin: auto;
    z-index: 1;
  }

  &.sb-score-marker {
    height: 50px;
    top: calc(100% - 24px);

    &:after {
      bottom: 100%;
      border-color: $sbx-color;
    }
  }
}
</style>
