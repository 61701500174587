import {
  Dashboard,
  Survey,
  SurveyIndex,
  Queue,
  QueueShow,
  Calculator,
  RespondentsIndex,
  Missing,
} from "../views";
import eventbus from "@cp/lib/CpEventBus";
import store from "@cp/store/appStore";
import router from "@cp/router";

router.beforeEach((to, from, next) => {
  if (to.name == "tempAccess" || to.name == null) {
    next();
  } else {
    let allowed = to.meta.roleRequired
      ? store.getters["auth/userIs"](to.meta.roleRequired)
      : true;

    if (allowed) {
      next();
    } else {
      if (from.name && from.name != to.name) {
        return false;
      } else {
        next("/");
        eventbus.$emit("SnackAlert", {
          icon: "mdi-account-alert",
          color: "error",
          message: "You do not have permission to access this page.",
        });
      }
    }
  }
});

export function buildRoutes() {
  const routes = [];

  const {
    rootPath,
    regionsPath,
    locationsPath,
    surveysPath,
    surveysDetailPath,
    allLocationsPath,
    surveysPathAllLocations,
    surveysDetailPathAllLocations,
  } = store.getters.userRoutes;

  if (!store.state.auth.hasCookie) {
    [
      {
        path: "*",
        redirect: to => {
          return { path: "/temp-access", query: to.query };
        },
      },
    ].forEach(route => {
      router.addRoute(route);
    });
    return;
  }

  // this adds our base/home routes given a user's access level - districts, regions, or locations
  routes.push(
    {
      path: "/",
      redirect: rootPath,
    },
    {
      path: `/${rootPath}`,
      name: rootPath,
      component: Dashboard,
      meta: {
        displaySidebar: true,
        model: rootPath,
        pageTitle: rootPath.charAt(0).toUpperCase() + rootPath.slice(1),
      },
      beforeEnter: (to, from, next) => {
        store.commit(`${rootPath}/reset`);
        delete store.state[rootPath].omniClients.dashboard;
        store.dispatch("fetchSidebarScores");
        store.dispatch(`${rootPath}/fetchDashboardCollection`, {
          page: {
            size: "all",
          },
        });
        next();
      },
    }
  );

  // dashboard list of regions
  if (["districts", "regions"].includes(rootPath)) {
    routes.push({
      path: regionsPath,
      name: "regions",
      component: Dashboard,
      meta: {
        displaySidebar: true,
        model: "regions",
        pageTitle: "Regions",
      },
      beforeEnter: (to, from, next) => {
        store.commit("regions/reset");
        store.commit("locations/reset");
        delete store.state.regions.omniClients.dashboard;
        delete store.state.locations.omniClients.dashboard;
        store.dispatch("fetchSidebarScores", {
          districtSlug: to.params.districtSlug,
          regionSlug: to.params.regionSlug,
        });

        if (rootPath == "districts" && to.params.districtSlug) {
          store
            .dispatch("districts/fetchInstance", to.params.districtSlug)
            .then(data => {
              store.dispatch("regions/fetchDashboardCollection", {
                districtID: data.id,
                page: {
                  size: "all",
                },
              });
            });
        }

        next();
      },
    });
  }

  // dashboard list of ALL locations
  routes.push({
    path: allLocationsPath,
    name: "allLocations",
    component: Dashboard,
    meta: {
      displaySidebar: true,
      model: "locations",
      pageTitle: "All Locations",
    },
    beforeEnter: (to, from, next) => {
      store.commit("locations/reset");
      delete store.state.locations.omniClients.dashboard;
      store.dispatch("fetchSidebarScores");

      store.dispatch("locations/fetchDashboardCollection", {
        page: {
          size: "all",
        },
      });
      next();
    },
  });

  // dashboard list of locations
  routes.push({
    path: locationsPath,
    name: "locations",
    component: Dashboard,
    meta: {
      displaySidebar: true,
      model: "locations",
      pageTitle: "Locations",
    },
    beforeEnter: (to, from, next) => {
      store.commit("locations/reset");
      delete store.state.locations.omniClients.dashboard;
      store.dispatch("fetchSidebarScores", {
        districtSlug: to.params.districtSlug,
        regionSlug: to.params.regionSlug,
      });

      store
        .dispatch("regions/fetchInstance", to.params.regionSlug)
        .then(data => {
          store.dispatch("locations/fetchDashboardCollection", {
            regionID: data.id,
            page: {
              size: "all",
            },
          });
        });
      next();
    },
  });

  // dashboard list of surveys
  routes.push({
    path: surveysPath,
    name: "surveys",
    component: Dashboard,
    meta: {
      displaySidebar: true,
      model: "surveys",
      pageTitle: `Surveys`,
    },
    beforeEnter: (to, from, next) => {
      if (rootPath != "locations") {
        store.dispatch("regions/fetchInstance", to.params.regionSlug);
      }
      store.commit("surveys/reset");
      store.dispatch("fetchSidebarScores", {
        regionSlug: to.params.regionSlug,
        locationSlug: to.params.locationSlug,
      });
      delete store.state.surveys.omniClients.dashboard;

      store
        .dispatch("locations/fetchInstance", to.params.locationSlug)
        .then(data => {
          store.dispatch("surveys/fetchDashboardCollection", {
            locationID: data.id,
          });
        });
      next();
    },
  });

  routes.push({
    path: surveysPathAllLocations,
    name: "surveysAllLocations",
    component: Dashboard,
    meta: {
      displaySidebar: true,
      model: "surveys",
      pageTitle: `Surveys`,
    },
    beforeEnter: (to, from, next) => {
      store.commit("surveys/reset");
      store.dispatch("fetchSidebarScores", {
        locationSlug: to.params.locationSlug,
      });
      delete store.state.surveys.omniClients.dashboard;

      store
        .dispatch("locations/fetchInstance", to.params.locationSlug)
        .then(data => {
          store.dispatch("surveys/fetchDashboardCollection", {
            locationID: data.id,
          });
        });
      next();
    },
  });

  routes.push({
    path: surveysDetailPathAllLocations,
    name: "surveyDetailAllLocations",
    component: Survey,
    meta: {
      displaySidebar: true,
      model: "surveys",
      pageTitle: "Survey Details",
    },
    beforeEnter: (to, from, next) => {
      store.commit("surveys/reset");
      store.commit("questions/reset");
      store.commit("respondents/reset");
      store
        .dispatch("fetchSidebarScores", {
          locationSlug: to.params.locationSlug,
          surveySlug: to.params.surveySlug,
        })
        .then(({ location }) => {
          store.dispatch("surveys/fetchInstance", {
            surveySlug: to.params.surveySlug,
            filters: {
              locations: [location.id],
              date_range: store.state.filters.date_range,
            },
            structures: {
              date_interval: store.state.structures.date_interval,
            },
          });
        });
      next();
    },
  });

  // dashboard survey detail
  routes.push({
    path: surveysDetailPath,
    name: "survey",
    component: Survey,
    meta: {
      displaySidebar: true,
      model: "surveys",
      pageTitle: "Survey Details",
    },
    beforeEnter: (to, from, next) => {
      store.commit("surveys/reset");
      store.commit("questions/reset");
      store.commit("respondents/reset");
      store
        .dispatch("fetchSidebarScores", {
          districtSlug: to.params.districtSlug,
          regionSlug: to.params.regionSlug,
          locationSlug: to.params.locationSlug,
          surveySlug: to.params.surveySlug,
        })
        .then(({ location }) => {
          store.dispatch("surveys/fetchInstance", {
            surveySlug: to.params.surveySlug,
            filters: {
              locations: [location.id],
              date_range: store.state.filters.date_range,
            },
            structures: {
              date_interval: store.state.structures.date_interval,
            },
          });
        });
      next();
    },
  });

  // non-dashboard routes
  routes.push(
    {
      path: "/surveys",
      name: "surveyIndex",
      component: SurveyIndex,
      meta: {
        pageTitle: "Surveys",
      },
      beforeEnter: (to, from, next) => {
        store.dispatch("surveys/closeSurveyDetails");
        store
          .dispatch("surveys/initOmniClient", {
            namespace: "indexCollection",
            structures: {
              date_interval: store.state.structures.date_interval,
              scope: ["surveys"],
              addon_options: ["defaults", "swift_bunny_index", "company_index"],
            },
          })
          .then(({ data }) => {
            data.results.forEach(survey => {
              store.dispatch("surveys/initOmniClient", {
                namespace: `districts_${survey.m_id}`,
                filters: {
                  surveys: [survey.m_id],
                },
                page: {
                  size: "all",
                },
                structures: {
                  scope: ["areas"],
                  addon_options: [
                    "defaults",
                    "swift_bunny_index",
                    "company_index",
                  ],
                  date_interval: store.state.structures.date_interval,
                },
              });

              store.dispatch("surveys/initOmniClient", {
                namespace: `regions_${survey.m_id}`,
                filters: {
                  surveys: [survey.m_id],
                },
                page: {
                  size: "all",
                },
                structures: {
                  scope: ["regions"],
                  addon_options: [
                    "defaults",
                    "swift_bunny_index",
                    "company_index",
                  ],
                  date_interval: store.state.structures.date_interval,
                },
              });

              store.dispatch("surveys/initOmniClient", {
                namespace: `locations_${survey.m_id}`,
                filters: {
                  surveys: [survey.m_id],
                },
                page: {
                  size: "all",
                },
                structures: {
                  scope: ["locations"],
                  addon_options: [
                    "defaults",
                    "swift_bunny_index",
                    "company_index",
                  ],
                  date_interval: store.state.structures.date_interval,
                },
              });
            });
          });
        next();
      },
    },
    {
      path: "/surveys/:surveySlug",
      name: "surveyDetail",
      component: SurveyIndex,
      meta: {
        pageTitle: "Survey Details",
      },
      beforeEnter: (to, from, next) => {
        store.dispatch("surveys/openSurveyDetails", {
          surveySlug: to.params.surveySlug,
          filters: to.params.filters,
        });
        store.commit("surveys/reset");
        store.commit("questions/reset");
        store.commit("respondents/reset");
        next();
      },
    },
    {
      path: "/residents",
      name: "respondentsIndex",
      component: RespondentsIndex,
      meta: {
        pageTitle: "Residents",
      },
      beforeEnter: (to, from, next) => {
        store.commit("respondents/reset");
        store.dispatch("respondents/initOmniClient", {
          namespace: "tableSummary",
          sortBy: ["last_taken_date"],
          sortDesc: true,
          structures: {
            scope: ["surveys_users"],
            addon_options: ["defaults", "swift_bunny_index", "company_index"],
            date_interval: store.state.structures.date_interval,
          },
        });
        next();
      },
    },
    {
      path: "/notifications",
      name: "queue",
      component: Queue,
      meta: {
        roleRequired: "swiftbunny-admin",
      },
      beforeEnter: (to, from, next) => {
        store.dispatch("notifications/fetchSummary");
        store.dispatch("notifications/autocomplete");
        next();
      },
    },
    {
      path: "/notifications/:id",
      name: "queueShow",
      component: QueueShow,
      meta: {
        roleRequired: "swiftbunny-admin",
      },
      beforeEnter: (to, from, next) => {
        store.dispatch("notifications/fetchInstance", to.params.id);
        next();
      },
    },
    {
      path: "/calculator",
      name: "calculator",
      component: Calculator,
      meta: {
        roleRequired: "swiftbunny-admin",
      },
      beforeEnter: (to, from, next) => {
        next();
      },
    },
    {
      path: "*",
      name: "missing",
      component: Missing,
      meta: {
        pageTitle: "Page not found",
      },
    }
  );

  routes.forEach(route => {
    router.addRoute(route);
  });
}
