<template>
  <main>
    <cp-app
      :navItems="links"
      :profileItems="profileItems"
      @ready="setupTempToken"
    >
      <template #modals>
        <comment-drawer />
      </template>

      <div
        class="intouchContainer"
        :class="{
          'sidebar-closed': sidebarClosed,
          'layout-container': $route.meta.displaySidebar,
        }"
      >
        <div class="layout-drawer-sidebar" v-if="$route.meta.displaySidebar">
          <cp-card flush-body>
            <v-btn
              block
              text
              small
              @click="sidebarClosed = !sidebarClosed"
              class="sidebar-collapse-button py-5"
            >
              <v-icon class="sidebar-collapse-icon"
                >mdi-chevron-double-left</v-icon
              >
              <span
                class="sidebar-heading"
                :class="{ 'is-hidden': sidebarClosed }"
              >
                Collapse Sidebar
              </span>
            </v-btn>

            <cp-card flat dense flush-body>
              <template #title>
                <v-tooltip right transition="slide-x-reverse-transition">
                  <template #activator="{ on }">
                    <v-icon
                      class="sidebar-heading__icon mr-2 ml-1 mb-1"
                      v-on="on"
                    >
                      mdi-chart-bar
                    </v-icon>
                  </template>
                  Current Score
                </v-tooltip>

                <span
                  class="sidebar-heading"
                  :class="{ 'is-hidden': sidebarClosed }"
                >
                  Current Score
                </span>
              </template>

              <score-bar-group />
            </cp-card>

            <cp-card flat dense flush-body>
              <template #title>
                <v-tooltip right transition="slide-x-reverse-transition">
                  <template #activator="{ on }">
                    <v-icon
                      class="mr-2 ml-1 mb-1"
                      v-on="on"
                      style="fill: rgba(0, 0, 0, 0.54)"
                    >
                      $vuetify.icons.Renew
                    </v-icon>
                  </template>
                  Likelihood to Renew
                </v-tooltip>

                <span
                  class="sidebar-heading"
                  :class="{ 'is-hidden': sidebarClosed }"
                >
                  Likelihood to Renew
                </span>
              </template>

              <renew-bar-group :sidebar-closed="sidebarClosed" />
            </cp-card>

            <cp-card flat dense flush-body>
              <template #title>
                <v-icon class="mr-2 ml-1 mb-1">mdi-account-group</v-icon>
                <span
                  class="sidebar-heading"
                  :class="{ 'is-hidden': sidebarClosed }"
                >
                  Organization
                </span>
              </template>

              <v-container class="organization-list">
                <v-row v-if="$route.params.regionSlug" dense align="center">
                  <v-col cols="auto">
                    <p
                      class="font-weight-bold d-block"
                      style="margin-bottom: -4px"
                    >
                      Regional Managers:
                    </p>
                    <template
                      v-if="$store.state.managers.regionManagers.length > 0"
                    >
                      <a
                        v-for="manager in $store.state.managers.regionManagers"
                        :key="manager.id"
                        :href="`mailto:${manager.email}`"
                        class="d-block"
                      >
                        - {{ manager.name }}
                      </a>
                    </template>
                    <span v-else>No Managers Assigned</span>
                  </v-col>
                </v-row>

                <v-row dense align="center" v-if="$route.params.locationSlug">
                  <v-col cols="auto">
                    <p
                      class="font-weight-bold d-block"
                      style="margin-bottom: -4px"
                    >
                      Location Managers:
                    </p>
                    <template
                      v-if="$store.state.managers.locationManagers.length > 0"
                    >
                      <a
                        v-for="(manager, i) in $store.state.managers
                          .locationManagers"
                        :key="manager.id"
                        :href="`mailto:${manager.email}`"
                        class="d-block"
                      >
                        - {{ manager.name }}
                      </a>
                    </template>
                    <span v-else>No Managers Assigned</span>
                  </v-col>
                </v-row>
              </v-container>
            </cp-card>
          </cp-card>
        </div>
        <div :class="{ 'layout-drawer-content': $route.meta.displaySidebar }">
          <router-view />
        </div>
      </div>
    </cp-app>
  </main>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { buildRoutes } from "@/router/dynamicRoutes";

import { isProduction } from "@cp/constants";
import syncToCookie from "@cp/mixins/syncToCookie";

const rolesWithAllLocations = [
  "intouch-admin",
  "area-manager",
  "regional-manager",

  // CRUFT - can remove when BE always sends kebab-case
  "area_manager",
  "regional_manager",
];

export default {
  name: "Intouch",
  data() {
    return {
      sidebarClosed: this.$vuetify.breakpoint.lgAndDown,
      ready: false,
    };
  },
  mixins: [syncToCookie("sidebarClosed", "intouch_sidebar_closed")],
  mounted() {
    document.addEventListener(
      "visibilitychange",
      this.checkImpersonationStatus
    );
  },

  computed: {
    ...mapState({
      auth: state => state.auth,
      group: state => state.groups.instance,
      region: state => state.regions.instance,
      location: state => state.locations.instance,
      survey: state => state.surveys,
      questions: state => state.questions,
    }),
    ...mapGetters("auth", ["userServices", "userIs"]),
    ...mapGetters(["userRoutes"]),
    ...mapGetters(["userAccessLevel"]),

    links() {
      if (!this.ready) return [];

      const routes = [
        {
          text: "Dashboard",
          to: { name: this.userRoutes.rootPath },
          icon: "view-dashboard",
        },
        {
          text: "Surveys",
          to: "/surveys",
          icon: "clipboard-check",
          exact: true,
        },
        {
          text: "Resident Surveys",
          to: "/residents",
          icon: "account-group",
          exact: true,
        },
        {
          text: "Notification Queue",
          to: "/notifications",
          icon: "tray-full",
          roleRequired: "swiftbunny-admin",
        },
        {
          text: "Michael's Calculator",
          to: "/calculator",
          icon: "calculator",
          roleRequired: "swiftbunny-admin",
        },
        {
          text: "Data Visuals",
          to: "/charts",
          icon: "chart-pie",
          roleRequired: "swiftbunny-admin",
        },
      ];

      if (this.userIs(rolesWithAllLocations)) {
        routes.splice(1, 0, {
          text: "Locations",
          to: "/all-locations",
          icon: "home-group",
        });
      }

      return routes;
    },

    profileItems() {
      if (isProduction) return [];
      if (
        !this.userIs("swiftbunny-admin") ||
        ![true, "true"].includes(process.env.VUE_APP_INTERNALLY_TESTING)
      )
        return [];
      return [
        {
          text: "Give Feedback",
          href: process.env.VUE_APP_INTERNAL_TESTING_URL,
          icon: "trello",
        },
      ];
    },
  },
  methods: {
    setupTempToken() {
      this.ready = true;
    },
  },
  mounted() {
    this.$CpEvent.$on("auth/signedIn", buildRoutes);
    this.$CpEvent.$on("auth/tokenAccepted", buildRoutes);
  },
  destroyed() {
    this.$CpEvent.$off("auth/signedIn", buildRoutes);
    this.$CpEvent.$off("auth/tokenAccepted", buildRoutes);
  },
};
</script>

<style lang="scss">
.v-application {
  min-width: 768px;

  .v-dialog {
    min-width: 768px;
  }

  .v-overlay {
    z-index: 52;
  }
}
</style>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,700,800|Lato:300,300i,400,700,900|Roboto+Condensed:300,400,700&display=swap");

.layout-container {
  display: flex;
  margin-top: 16px;

  .layout-drawer-sidebar {
    transition: 0.48s width cubic-bezier(0.16, 1, 0.3, 1);
    width: 300px;
    margin-right: 8px;

    * {
      white-space: nowrap;
    }

    &::v-deep > .cp-card > .v-card__text {
      overflow: hidden;
    }

    &::v-deep .v-card__title {
      background-color: #f1f1f1;
    }
  }

  .layout-drawer-content {
    transition: 0.48s width cubic-bezier(0.16, 1, 0.3, 1);
    width: calc(100% - 300px);
    margin-left: 8px;
  }

  .sidebar-collapse-button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    justify-content: flex-start;
  }

  &.sidebar-closed {
    .layout-drawer-sidebar {
      width: 52px;
    }

    .layout-drawer-content {
      width: calc(100% - 52px);
    }

    .sidebar-collapse-icon {
      transform: scaleX(-1);
    }
  }

  .sidebar-heading {
    transition: 0.48s all cubic-bezier(0.16, 1, 0.3, 1);
    position: relative;
    right: 0;

    &.is-hidden {
      right: -36px;
      opacity: 0;
      pointer-events: none;
    }
  }

  .sidebar-heading__icon {
    pointer-events: none;
  }

  &.sidebar-closed {
    .sidebar-heading__icon {
      pointer-events: all;
    }
  }
}

.cp-banner {
  z-index: 51 !important;
}

.app-footer {
  width: calc(100% - 24px);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.organization-list {
  overflow-x: hidden;

  > *:not(:last-child) {
    border-bottom: 1px solid #e6e6e6;
  }

  .sidebar-closed & {
    opacity: 0;
    display: none;
  }
}
</style>
