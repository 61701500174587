<template>
  <div class="queue-index">
    <cp-h1>Client Summary</cp-h1>
    <cp-card flush-body dense class="mb-3">
      <v-container v-if="notifications.instanceReady">
        <v-row>
          <v-col
            v-for="[header, fields] in Object.entries(notifications.instance)"
            :key="header"
          >
            <h4 class="font-weight-bold text-uppercase">{{ header }}</h4>
            <h5 v-for="[field, value] in Object.entries(fields)" :key="field">
              {{ field }}: {{ value }}
            </h5>
          </v-col>
        </v-row>
      </v-container>

      <div class="text-center" v-else>
        <v-progress-circular
          indeterminate
          class="ma-6"
          size="50"
          color="primary"
        />
      </div>
    </cp-card>

    <cp-card flush-body dense>
      <template #title> Queue </template>

      <template #actions>
        <cp-search
          v-model="notifications.tableMeta.search"
          class="card-header-actions mr-2"
          label="Search"
          dense
          hide-details
          single-line
          :loading="!notifications.collectionReady"
        />

        <v-select
          v-model="notifications.tableMeta.activeFilters"
          multiple
          class="card-header-actions mr-2"
          label="Queue Status"
          dense
          hide-details
          single-line
          :items="$store.getters['notifications/filterOptions'].queue_statuses"
          @input="updateTable"
          :loading="!notifications.collectionReady"
        />

        <v-select
          v-model="notifications.tableMeta.activeFilters"
          multiple
          class="card-header-actions mr-2"
          label="Email Status"
          dense
          hide-details
          single-line
          :items="
            $store.getters['notifications/filterOptions'].delivery_statuses
          "
          @input="updateTable"
          :loading="!notifications.collectionReady"
        />

        <v-btn
          small
          rounded
          color="primary"
          @click="$store.dispatch('notifications/export')"
          :loading="notifications.exporting"
        >
          Export
        </v-btn>
      </template>

      <v-data-table
        :loading="!notifications.collectionReady"
        :headers="notifications.tableHeaders"
        :items="notifications.collection"
        :server-items-length="notifications.tableMeta.pagination.itemsLength"
        :options="{
          multiSort: false,
          itemsPerPage: parseInt(
            notifications.tableMeta.pagination.itemsPerPage
          ),
          sortBy: notifications.tableMeta.sortBy,
          sortDesc: notifications.tableMeta.sortDesc,
        }"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 20, 30],
        }"
        @update:options="
          notifications.tableMeta.pagination.page = $event.page;
          notifications.tableMeta.pagination.itemsPerPage = $event.itemsPerPage;
          notifications.tableMeta.sortBy = $event.sortBy;
          notifications.tableMeta.sortDesc = $event.sortDesc;
          updateTable();
        "
        @click:row="
          if ($event.first_sent) {
            $router.push({ name: 'queueShow', params: { id: $event.id } });
          }
        "
      >
        <template #item.details="{ item }">
          <h5 class="font-weight-bold">
            {{ item.survey_name }}
          </h5>
          {{ item.primary_email || item.overwrite_email || "-" }}
        </template>

        <template #item.actions="{ item }">
          <v-menu>
            <template #activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list dense nav>
              <v-list-item-group>
                <v-list-item v-if="item.actions.length <= 0" disabled>
                  <v-list-item-title> No Actions Available </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-for="(action, index) in item.actions"
                  :key="index"
                  @click="
                    $store.dispatch('notifications/execQueueAction', {
                      link: action.link,
                      method: action.method,
                    })
                  "
                >
                  <v-list-item-title>{{ action.label }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </cp-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      searchTimeout: null,
    };
  },

  computed: {
    ...mapState({
      notifications: state => state.notifications,
    }),
  },

  methods: {
    updateTable(options) {
      if (this.notifications.collectionReady) {
        this.$store.dispatch("notifications/autocomplete");
      }
    },
  },

  watch: {
    "notifications.tableMeta.search"(value) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.$store.dispatch("notifications/autocomplete");
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-header-actions {
  width: 200px;
}

.queue-index::v-deep .v-data-table tbody tr {
  cursor: pointer;
}
</style>
