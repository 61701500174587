import Common from "../common.js";
let common = new Common("location", "locations", "locations");

export default {
  namespaced: true,

  state: {
    ...common.state,
    color: "#008A09"
  },

  getters: {
    ...common.getters
  },

  actions: {
    ...common.actions
  },

  mutations: {
    ...common.mutations
  }
};
