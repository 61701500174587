var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('cp-h1',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('v-menu',{attrs:{"offset-y":"","bottom":"","left":"","origin":"top right","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({on, attrs}){return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")]),_vm._v(" Survey Sort and Filter ")],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.showLowestFirst = !_vm.showLowestFirst}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.sort.icon))])],1),_c('v-list-item-content',[_vm._v(" Sorting by "+_vm._s(_vm.sort.text)+" First ")])],1),(_vm.surveys.omniClients.indexCollection)?_c('cp-combo-date-filter',{attrs:{"offset-y":"","left":"","start-date":_vm.$store.state.filters.date_start,"end-date":_vm.$store.state.filters.date_end,"preset":_vm.$store.state.filters.date_preset,"loading":_vm.surveys.omniClients.indexCollection.awaitingResponse},on:{"selectionUpdated":function($event){_vm.$store.commit('setDateFilter', $event);
              _vm.filtersChanged();}},scopedSlots:_vm._u([{key:"default",fn:function({toggleMenu, selectionDisplay}){return [_c('v-list-item',_vm._g({},toggleMenu),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-calendar-month-outline")])],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(selectionDisplay)+" ")])],1)]}}],null,false,2667683258)}):_vm._e(),_c('v-list-item',{on:{"click":_vm.openReportingGroupsFilterDialog}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-filter-outline")])],1),_c('v-list-item-content',[_vm._v(" Filter by Reporting Groups ")])],1)],1)],1)]},proxy:true}])},[_vm._v(" Survey Overview ")]),_c('cp-card',{attrs:{"dense":"","flush-body":"","collapse-actions-always":"","filter-count":_vm.filterCount,"actions-icon":"mdi-filter","actions-header-text":"Survey Sort and Filter","show-actions-activator-label":""}},[_c('active-groups-banner',{attrs:{"omni-client":_vm.surveys.omniClients.indexCollection},on:{"change":_vm.filtersChanged}}),(
        _vm.surveys.omniClients.indexCollection &&
          _vm.surveys.omniClients.indexCollection.awaitingResponse
      )?_c('div',{staticClass:"text-center pa-4"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":80,"width":10}})],1):[(
          _vm.surveys.omniClients.indexCollection &&
            _vm.surveys.omniClients.indexCollection.data &&
            _vm.surveys.omniClients.indexCollection.data.results.length > 0
        )?_c('v-container',[_c('v-row',_vm._l((_vm.surveys.omniClients.indexCollection.data.results),function(survey){return _c('v-col',{key:survey.m_id,attrs:{"cols":"12","md":"6"}},[_c('summary-card',{attrs:{"survey":survey,"show-lowest-first":_vm.showLowestFirst}})],1)}),1)],1):_c('v-alert',{attrs:{"dense":"","text":"","icon":"mdi-information"}},[_vm._v(" No results found for the given filters. ")])]],2),_c('survey-details-modal',{attrs:{"omni-client-survey":_vm.surveys.omniClients.itemDetail,"omni-client-questions":_vm.questions.omniClients.tableSummary,"omni-client-respondents":_vm.respondents.omniClients.tableSummary,"omni-client-comments":_vm.surveys.omniClients.comments}}),_c('survey-result-detail-modal',{attrs:{"omni-client":_vm.respondents.omniClients.tableSummary}}),_c('groups-filter',{attrs:{"omni-client":_vm.omniClientToFilter},on:{"change":_vm.filtersChanged}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }