import Vue from "vue";

const RedstoneComponents = require.context(
  "@/components",
  true,
  /\.(js|vue)$/i
);

RedstoneComponents.keys().map(path => {
  let componentName = path.substring(path.lastIndexOf("/") + 1).split(".")[0];
  return Vue.component(componentName, RedstoneComponents(path).default);
});
