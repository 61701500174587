import Vue from "vue";
import App from "./App.vue";
import { CarrotPatchV2, store, router, vuetify } from "@cp/install";
import VueGtm from "@gtm-support/vue2-gtm";
import "./styles/redstone.scss";

Vue.use(CarrotPatchV2);
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID,
  queryParams: {
    gtm_auth: process.env.VUE_APP_GTM_AUTH,
    gtm_preview: process.env.VUE_APP_GTM_PREVIEW,
    gtm_cookies_win: process.env.VUE_APP_GTM_COOKIES_WIN,
  },
  defer: false,
  compatibility: false,
  enabled: process.env.VUE_APP_GTM_ENABLED,
  debug: process.env.VUE_APP_MODE != "production",
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
});

new Vue({
  render: h => h(App),
  router,
  store,
  vuetify,
}).$mount("#app");
