<template>
  <div>
    <div class="bun-survey-chart">
      <cp-combo-chart
        v-if="omniClientSurvey.data.results"
        class="bun-survey-chart__chart"
        :height="200"
        :chart-data="chartData"
        :y-min="chartData.yMin"
        :y-max="chartData.yMax"
        :date-interval="$store.state.structures.date_interval"
        :options="{
          elements: {
            line: {
              spanGaps: true,
            },
            point: {
              spanGaps: true,
            },
          },
        }"
        show-details
      />

      <div class="bun-survey-chart__legend">
        <div><v-icon color="#e67e22" small>mdi-circle</v-icon> Survey</div>
        <div><v-icon color="#685478" small>mdi-circle</v-icon> Company</div>
        <div><v-icon color="#009cde" small>mdi-circle</v-icon> SB Index</div>
      </div>
    </div>

    <v-divider />

    <v-tabs
      class="tabs-header"
      v-model="tab"
      grow
      height="48"
      background-color="#d5e2ed"
      slider-color="#ffffff"
      slider-size="3"
    >
      <v-tab
        ><v-icon color="primary" class="mr-1">mdi-format-list-bulleted</v-icon>
        Questions</v-tab
      >
      <v-tab
        ><v-icon color="primary" class="mr-1">mdi-home-account</v-icon>
        Residents</v-tab
      >
      <v-tab
        ><v-icon color="primary" class="mr-1"
          >mdi-comment-text-multiple-outline</v-icon
        >
        Comments</v-tab
      >
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <questions-tab
          v-if="omniClientQuestions"
          :omni-client="omniClientQuestions"
        />
      </v-tab-item>

      <v-tab-item>
        <respondents-tab
          v-if="omniClientRespondents"
          :omni-client="omniClientRespondents"
        />
      </v-tab-item>

      <v-tab-item>
        <comments-tab
          v-if="omniClientComments"
          :omni-client="omniClientComments"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    omniClientSurvey: { type: Object, default: () => {} },
    omniClientQuestions: { type: Object, default: () => {} },
    omniClientRespondents: { type: Object, default: () => {} },
    omniClientComments: { type: Object, default: () => {} },
  },

  data() {
    return {
      tab: 0,
    };
  },

  computed: {
    ...mapState({
      surveys: state => state.surveys,
      questions: state => state.questions,
      respondents: state => state.respondents,
    }),

    queryTab() {
      return parseInt(this.$route.query.tab);
    },

    chartData() {
      return {
        yMin: this.omniClientSurvey.forCompChart().yMin,
        yMax: this.omniClientSurvey.forCompChart().yMax,
        labels: this.omniClientSurvey.forCompChart().labels,
        datasets: [
          {
            fill: true,
            borderColor: "rgba(230, 126, 34, 1)",
            backgroundColor: "rgba(230, 126, 34, .1)",
            pointBackgroundColor: "rgba(230, 126, 34, 1)",
            borderWidth: 2,
            tension: 0.2,
            order: 2,
            data: this.omniClientSurvey.forCompChart().item,
            label: "Survey",
          },
          {
            fill: false,
            borderColor: "rgba(104, 84, 120, 1)",
            pointBackgroundColor: "rgba(104, 84, 120, 1)",
            tension: 0.2,
            order: 1,
            data: this.omniClientSurvey.forCompChart().client,
            label: "Company",
          },
          {
            fill: false,
            borderColor: "rgba(0, 156, 222, 1)",
            pointBackgroundColor: "rgba(0, 156, 222, 1)",
            tension: 0.2,
            order: 0,
            borderWidth: 2,
            borderDash: [4, 4],
            data: this.omniClientSurvey.forCompChart().sbx,
            label: "SB Index",
          },
        ],
      };
    },
  },

  mounted() {
    if ([0, 1, 2].includes(this.queryTab)) {
      this.tab = this.queryTab;
    } else {
      this.$router.replace({
        query: { ...this.$route.query, tab: 0 },
      });
    }

    this.$store.dispatch("questions/initOmniClient", {
      namespace: "tableSummary",
      filters: this.omniClientSurvey.filters,
      page: {
        size: "all",
      },
      structures: {
        scope: ["questions"],
        date_interval: this.$store.state.structures.date_interval,
        addon_options: [
          "defaults",
          "swift_bunny_index",
          "company_index",
          "timeline",
        ],
      },
    });

    this.$store.dispatch("respondents/initOmniClient", {
      namespace: "tableSummary",
      filters: {
        ...this.omniClientSurvey.filters,
      },
      sortBy: ["created_at"],
      sortDesc: true,
      structures: {
        scope: ["surveys_users"],
        date_interval: this.$store.state.structures.date_interval,
        addon_options: [
          "defaults",
          "swift_bunny_index",
          "company_index",
          "timeline",
        ],
      },
    });

    this.$store.dispatch("surveys/initOmniClient", {
      namespace: "comments",
      filters: {
        ...this.omniClientSurvey.filters,
        questions: [590],
      },
      structures: {
        scope: ["responses"],
        date_interval: this.$store.state.structures.date_interval,
        addon_options: ["defaults", "swift_bunny_index", "company_index"],
      },
    });
  },

  watch: {
    tab(tab) {
      if (this.queryTab != tab) {
        this.$router.replace({
          query: { ...this.$route.query, tab: tab },
        });
      }

      this.$emit("tab-updated", tab);

      switch (tab) {
        case 0:
          if (!this.omniClientQuestions.data.results) {
            this.omniClientQuestions.fetch();
          }
          break;
        case 1:
          if (!this.omniClientRespondents.data.results) {
            this.omniClientRespondents.fetch();
          }
          break;
        case 2:
          if (!this.omniClientComments.data.results) {
            this.omniClientComments.filters = {
              ...this.omniClientSurvey.filters,
              questions: [590],
            };

            this.omniClientComments.fetch();
          }
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bun-survey-chart {
  width: 100%;
  max-width: 750px;
  text-align: center;
  margin: 0 auto;
  padding: 16px;

  .bun-survey-chart__title {
    font-size: 18px;
    font-weight: 500;
    margin-left: 8px;
  }

  .bun-survey-chart__chart {
    min-height: 200px;
  }

  .bun-survey-chart__legend {
    text-align: center;
    margin-top: 0;

    > div {
      font-weight: 600;
      display: inline-block;
      margin: 0 4px;
    }
  }
}

.tabs-header {
  &:deep {
    .v-tab {
      color: $primary !important;
      border-right: 1px solid #ffffff;

      &:last-child {
        border-right: 0;
      }
    }

    .v-tab--active {
      background-color: $primary;
      color: #ffffff !important;

      .v-icon {
        color: #ffffff !important;
      }
    }
  }
}
</style>
