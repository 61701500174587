import Vue from "vue";
import eventbus from "@cp/lib/CpEventBus.js";
import Common from "../common.js";
import Omnirequest from "../../lib/omnirequest";
import { toMDY } from "@cp/utils/dateUtils";

let common = new Common("respondent", "respondents", "respondents");

export default {
  namespaced: true,

  state: {
    ...common.state,
    detailsOpen: false,
    detailsBusy: false,
  },

  getters: {
    ...common.getters,

    forRespondents(state) {
      if (!state.omniClients.respondents || !state.omniClients.respondents.data)
        return [];

      return state.omniClients.respondents.data.results.map(x => ({
        id: x.m_id,
        full_name: `${x.first_name} ${x.last_name}`,
        ...x,
      }));
    },

    forSurveys(state) {
      if (!state.omniClients.surveys || !state.omniClients.surveys.data)
        return [];

      return state.omniClients.surveys.data.results.map(x => ({
        id: x.m_id,
        ...x,
      }));
    },
  },

  actions: {
    ...common.actions,

    fetchCollection(
      { commit, state, rootState },
      { locationID = null, surveyID = null } = {}
    ) {
      Vue.set(
        state.omniClients,
        "respondents",
        state.omniClients.respondents ||
          new Omnirequest({
            filters: {
              surveys: [surveyID],
              locations: [locationID],
            },
            structures: {
              scope: ["residents"],
              addon_options: ["defaults", "swift_bunny_index", "company_index"],
            },
          })
      );

      state.omniClients.respondents.fetch().then(() => {
        state.collection = state.omniClients.respondents.data.results.map(
          x => ({
            ...x,
            id: x.m_id,
            full_name: `${x.first_name} ${x.last_name}`,
            surveys: [],
          })
        );
      });
    },

    fetchInstance({ commit, state, rootState }, residentsUserID) {
      common.bApi
        .authorize()
        .get(`intouch/resident_survey/${residentsUserID}`)
        .then(data => {
          data.id = residentsUserID;
          commit("setInstance", data);
        });
    },

    download({ state }, residentsUserID) {
      state.detailsBusy = true;

      common.bApi
        .authorize()
        .download(
          `intouch/resident_survey/${residentsUserID}.xlsx`,
          "survey_result.xlsx",
          null
        )
        .finally(() => {
          state.detailsBusy = false;
        });
    },

    shareOverEmail(
      { state },
      { residentsUserID = null, emails = [], message = null, link = null }
    ) {
      state.detailsBusy = true;

      common.bApi
        .authorize()
        .post(`intouch/resident_survey/${residentsUserID}`, {
          emails: emails,
          message: message,
          link: link,
        })
        .finally(() => {
          state.detailsBusy = false;
        });
    },

    fetchSurveysForUser(
      { commit, state, rootState },
      {
        locationID = [],
        surveyID = [],
        onlyShowConfidential = false,
        userID = [],
      } = {}
    ) {
      Vue.set(
        state.omniClients,
        "surveys",
        new Omnirequest({
          filters: {
            surveys: surveyID,
            locations: locationID,
            residents: userID,
            confidential_only: onlyShowConfidential,
          },
          sortBy: ["created_at"],
          sortDesc: true,
          structures: {
            scope: ["surveys_users"],
            addon_options: ["defaults", "swift_bunny_index", "company_index"],
          },
        })
      );

      return state.omniClients.surveys.fetch().then(data => {
        let user = state.omniClients.tableSummary.data.results.find(
          x => x.m_id == userID
        );

        if (user) {
          Vue.set(
            user,
            "surveys",
            data.data.results.map(x => ({
              ...x,
              id: x.m_id,
              full_name: user.full_name,
              updated_at: toMDY(x.updated_at),
            }))
          );
        }

        return data;
      });
    },

    openDetailsModal({ state, dispatch }, residentsUserID) {
      state.detailsOpen = true;
      dispatch("fetchInstance", residentsUserID);
    },
  },

  mutations: {
    ...common.mutations,
  },
};
