<template>
  <v-row dense class="int-comment">
    <v-col cols="auto">
      <v-avatar size="48" color="#f1f1f1">
        <v-icon
          v-if="comment.first_name == 'Confidential' || !comment.first_name"
        >
          mdi-shield-account-outline
        </v-icon>
        <span v-else class="text-h6 text-uppercase">
          {{ initials }}
        </span>
      </v-avatar>
    </v-col>
    <v-col>
      <div>
        <span class="font-weight-bold">
          {{ fullName }}
        </span>
        on
        <span class="font-weight-bold">
          {{ toMDY(comment.created_at) }}
        </span>
        &nbsp;&nbsp;
        <likelihood-emoticon :likelihood="comment.likely_to_renew" />
        <v-btn
          x-small
          rounded
          depressed
          style="float: right; margin-top: -2px"
          @click="
            $store.dispatch(
              'respondents/openDetailsModal',
              comment.residents_survey_id
            )
          "
        >
          <span style="color: #0070a8">View Survey</span>
        </v-btn>
      </div>
      <p class="comment-body">
        {{ comment.text_value_1 }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
import { toMDY } from "@cp/utils/dateUtils";

export default {
  props: {
    comment: { type: Object, default: () => {} },
  },

  computed: {
    fullName() {
      return `${this.comment.first_name} ${this.comment.last_name}`;
    },

    initials() {
      return `${this.comment.first_name[0]} ${this.comment.last_name[0]}`;
    },
  },
  methods: { toMDY },
};
</script>

<style lang="scss" scoped>
.int-comment {
  padding: 12px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #f1f1f1;
  }

  .comment-body {
    margin-top: 6px;
    line-height: 1.25;
    font-weight: 400;
    font-size: 1rem;
    color: rgba(#000000, 0.6);
  }
}
</style>
