export default {
  collection: [
    {
      id: 1,
      name: "Resident Satisfaction",
      slug: "resident-satisfaction",
      score: 85,
    },
    {
      id: 2,
      name: "New Resident Move-In",
      slug: "new-resident-move-in",
      score: 78,
    },
    {
      id: 3,
      name: "Service Request Follow Up",
      slug: "service-request-follow-up",
      score: 38,
    },
    {
      id: 4,
      name: "Prospective Resident Follow Up",
      slug: "prospective-resident-follow-up",
      score: 52,
    },
    {
      id: 5,
      name: "Resident Check-In",
      slug: "resident-check-in",
      score: 52,
    },
  ],

  instance: {
    id: 3,
    name: "Service Request Follow Up",
    slug: "service-request-follow-up",
    questions: [
      {
        id: 1,
        body: "The office staff is responsive",
        score: 62,
        company_score: 74,
        sbx: 78,
        question_type: 1,
        type_slug: "agreement",
        values: {
          agreement: [22, 10, 8, 20, 40],
        },
      },
      {
        id: 2,
        body: "My home is in good condition",
        score: 55,
        company_score: 54,
        sbx: 78,
        question_type: 2,
        type_slug: "agreement-importance",
        values: {
          agreement: [22, 10, 8, 20, 40],
          importance: [22, 10, 8, 20, 40],
        },
      },
      {
        id: 3,
        body: "The maintenance staff work quality is good",
        score: 78,
        company_score: 64,
        sbx: 78,
        question_type: 3,
        type_slug: "importance",
        values: {
          importance: [22, 10, 8, 20, 40],
        },
      },
      {
        id: 4,
        body: "Please share any suggestions or comments",
        score: null,
        company_score: null,
        sbx: null,
        question_type: 4,
        type_slug: "text",
        values: {
          response_count: 3,
          responses: [
            {
              id: 1,
              created_at: "01/02/2021",
              body: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor.",
              user: {
                id: 1,
                full_name: "Jonathan Bowman",
                initials: "JB",
              },
            },
            {
              id: 2,
              created_at: "01/02/2021",
              body: "Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor.",
              user: {
                id: 2,
                full_name: "Rebecca Bachman",
                initials: "RB",
              },
            },
            {
              id: 3,
              created_at: "01/02/2021",
              body: "Sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam.",
              user: {
                id: 3,
                full_name: "Michael Craig",
                initials: "MC",
              },
            },
          ],
        },
      },
    ],
    respondents: [
      {
        id: 1,
        name: "Jonathan Bowman",
        date: "08/12/2020",
        score: 48,
        unit: 1104,
        survey_count: 3,
        surveys: [
          {
            id: 10,
            date: "03/22/2021",
            score: 52,
            rating: 2,
          },
          {
            id: 11,
            date: "05/12/2021",
            score: 42,
            rating: 1,
          },
          {
            id: 14,
            date: "06/18/2021",
            score: 82,
            rating: 3,
          },
        ],
      },
      {
        id: 2,
        name: "Joe Bailey",
        date: "11/12/2021",
        score: 80,
        unit: 1123,
        survey_count: 2,
        surveys: [],
      },
    ],
    comments: [
      {
        id: 1,
        body: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur.",
        created_at: "01/05/2021",
        score: 1,
        user: {
          id: 1,
          full_name: "Jonathan Bowman",
          initials: "JB",
        },
      },
      {
        id: 2,
        body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        created_at: "01/05/2021",
        score: 0,
        user: {
          id: 2,
          full_name: "Rebecca Bachman",
          initials: "RB",
        },
      },
      {
        id: 3,
        body: "I am a comment.",
        created_at: "01/05/2021",
        score: 2,
        user: {
          id: 3,
          full_name: "Confidential",
          initials: "MC",
        },
      },
      {
        id: 4,
        body: "I am another comment.",
        created_at: "01/05/2021",
        score: 4,
        user: {
          id: 4,
          full_name: "Brett Harris",
          initials: "BH",
        },
      },
      {
        id: 5,
        body: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur.",
        created_at: "01/05/2021",
        score: 1,
        user: {
          id: 1,
          full_name: "Jonathan Bowman",
          initials: "JB",
        },
      },
      {
        id: 6,
        body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        created_at: "01/05/2021",
        score: 0,
        user: {
          id: 2,
          full_name: "Rebecca Bachman",
          initials: "RB",
        },
      },
      {
        id: 7,
        body: "I am a comment.",
        created_at: "01/05/2021",
        score: 2,
        user: {
          id: 3,
          full_name: "Michael Craig",
          initials: "MC",
        },
      },
      {
        id: 8,
        body: "I am another comment.",
        created_at: "01/05/2021",
        score: 4,
        user: {
          id: 4,
          full_name: "Brett Harris",
          initials: "BH",
        },
      },
      {
        id: 9,
        body: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur.",
        created_at: "01/05/2021",
        score: 1,
        user: {
          id: 1,
          full_name: "Jonathan Bowman",
          initials: "JB",
        },
      },
      {
        id: 10,
        body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        created_at: "01/05/2021",
        score: 0,
        user: {
          id: 2,
          full_name: "Rebecca Bachman",
          initials: "RB",
        },
      },
      {
        id: 11,
        body: "I am a comment.",
        created_at: "01/05/2021",
        score: 2,
        user: {
          id: 3,
          full_name: "Michael Craig",
          initials: "MC",
        },
      },
      {
        id: 12,
        body: "I am another comment.",
        created_at: "01/05/2021",
        score: 4,
        user: {
          id: 4,
          full_name: "Brett Harris",
          initials: "BH",
        },
      },
    ],
  },
};
