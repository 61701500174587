<template>
  <v-btn
    v-if="internallyTesting && !onProduction"
    :href="internalTestingURL"
    target="_blank"
    class="open-testing-page"
    color="primary"
    rounded
  >
    <v-icon class="mr-2">mdi-trello</v-icon> Open Review Board
  </v-btn>
</template>

<script>
export default {
  computed: {
    onProduction() {
      return process.env.VUE_APP_MODE == "production";
    },

    internallyTesting() {
      return [true, "true"].includes(process.env.VUE_APP_INTERNALLY_TESTING);
    },

    internalTestingURL() {
      return process.env.VUE_APP_INTERNAL_TESTING_URL;
    },
  },
};
</script>

<style lang="scss">
.open-testing-page {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100;

  &:after {
    content: "ONLY IN TESTING";
    position: absolute;
    top: -60%;
    background-color: none;
    box-shadow: none;
    color: red;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
    font-size: 12px;
  }
}
.CpAppNotices ~ .open-testing-page {
  bottom: 130px;
}
</style>
