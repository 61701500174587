import Vue from "vue";
import Omnirequest from "../../lib/omnirequest";
import Common from "../common.js";
let common = new Common("survey", "surveys", "intouch/surveys");

export default {
  namespaced: true,

  state: {
    ...common.state,
    color: "#e67e22",
    instance: {
      results: [],
      respondents: [],
      comments: [],
      name: ""
    },
    onlyShowLowScores: false,
    instanceDetailsOpen: false
  },

  getters: {
    ...common.getters
  },

  actions: {
    ...common.actions,

    fetchInstance(
      { state, dispatch, rootState },
      { surveySlug = null, surveyID = null, filters = {}, structures = {} }
    ) {
      if (surveySlug) {
        return common.bApi
          .authorize()
          .get(`intouch/surveys/${surveySlug}`)
          .then(({ survey }) => {
            state.instance.name = survey.name;

            return dispatch("fetchInstance", {
              surveyID: survey.id,
              structures: {
                scope: ["surveys"]
              },
              filters: filters
            });
          });
      } else if (surveyID) {
        return dispatch("initOmniClient", {
          namespace: "itemDetail",
          filters: {
            surveys: [surveyID],
            ...filters
          },
          structures: {
            ...rootState.structures,
            scope: ["surveys"],
            addon_options: [
              "defaults",
              "swift_bunny_index",
              "company_index",
              "timeline"
            ]
          }
        }).then(() => {
          return surveyID;
        });
      }
    },

    openSurveyDetails(
      { state, dispatch, commit, rootState },
      { surveySlug = null, surveyID = null, filters = {} }
    ) {
      state.instanceDetailsOpen = true;

      return dispatch("fetchInstance", {
        surveySlug: surveySlug,
        surveyID: surveyID,
        filters: {
          regions: [],
          locations: [],
          questions: [],
          ...filters
        },
        structures: {
          scope: ["surveys"],
          date_interval: rootState.structures.date_interval
        }
      });
    },

    closeSurveyDetails({ state }) {
      state.instanceDetailsOpen = false;
    }
  },

  mutations: {
    ...common.mutations
  }
};
