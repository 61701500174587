import Common from "../common.js";
let common = new Common("area", "areas", "geographical_groups");

export default {
  namespaced: true,

  state: {
    ...common.state,
    color: "#795644"
  },

  getters: {
    ...common.getters
  },

  actions: {
    ...common.actions
  },

  mutations: {
    ...common.mutations
  }
};
