<template>
  <v-tooltip v-bind="tooltipDefaults">
    <template #activator="{ on }">
      <div class="tooltip-icon" v-on="{ ...on, ...$listeners }">
        <v-icon v-bind="iconAttrs">
          {{ icon }}
        </v-icon>
        <span v-if="iconLabel" class="pl-1">{{ iconLabel }}</span>
      </div>
    </template>
    {{ tooltip }}
  </v-tooltip>
</template>

<script>
export default {
  props: {
    tooltip: { type: String, default: "", required: true },
    icon: { type: String, default: "", required: true },
    iconLabel: { type: [String, Number], default: "" },
    iconAttrs: { type: Object, default: () => {} }
  },

  computed: {
    tooltipDefaults() {
      return {
        top: true,
        transition: "slide-x-transition",
        ...this.$attrs
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip-icon {
  display: flex;
}
</style>
