<template>
  <div class="survey-summary-body" :style="cssVars">
    <div v-for="item in resultsSorted" :key="item.m_id" class="summary-bar">
      <v-btn
        text
        small
        rounded
        color="primary"
        class="summary-bar__text"
        @click="openRowDetails(item)"
        :disabled="item.surveys_sent <= 0 || !item.surveys_responded"
      >
        {{ item.rolling_year || 0 }} • {{ item.name }}
        <span v-if="item.surveys_sent <= 0">(no surveys sent)</span>
        <span v-else-if="item.surveys_responded <= 0">(no responses)</span>
      </v-btn>
      <div
        class="summary-bar__bar"
        :style="`width: ${item.rolling_year || 0}%;`"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    omniClient: { type: Object, default: () => {} },
    survey: { type: Object, default: () => {} },
    color: { type: String, default: "#003594" },
    showLowestFirst: { type: Boolean, default: false },
  },

  computed: {
    resultsSorted() {
      if (!this.omniClient.data.results) return [];

      if (this.showLowestFirst) {
        return this.omniClient.data.results;
        // commenting out, to remove filter and sort from the FE
        // .filter(
        //   (x) =>
        //     x.m_id != null && ![null, 0, "0"].includes(x.agreement_importance)
        // )
        // .sort((a, b) => (a.rolling_year > b.rolling_year ? 1 : -1))
      } else {
        return this.omniClient.data.results;
        // commenting out, to remove filter and sort from the FE
        // .filter(
        //   (x) =>
        //     x.m_id != null && ![null, 0, "0"].includes(x.agreement_importance)
        // )
        // .sort((a, b) => (a.rolling_year > b.rolling_year ? -1 : 1))
      }
    },

    cssVars() {
      return {
        "--color": this.color,
      };
    },
  },

  methods: {
    openRowDetails(item) {
      let filters = {};
      if (this.color == "#003594") {
        filters.regions = [item.m_id];
      } else {
        filters.locations = [item.m_id];
      }

      this.$router.replace({
        name: "surveyDetail",
        params: {
          surveySlug: this.survey.slug,
          filters: filters,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.survey-summary-body {
  background-color: #f7f7f7;
  padding-bottom: 6px;
  height: 134px;
  overflow-y: auto;
  margin-top: 30px;

  .summary-bar {
    height: 24px;
    width: 100%;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    .summary-bar__text {
      font-weight: 700;
      position: relative;
      top: 2px;
      padding-left: 6px;
      z-index: 3;
    }

    .summary-bar__bar {
      transition: width 0.25s cubic-bezier(0.16, 1, 0.3, 1);
      height: 3px;
      background-color: var(--color);
      opacity: 1;
      z-index: 2;

      &:after {
        content: "";
        width: 100%;
        height: 3px;
        background-color: var(--color);
        opacity: 0.15;
        position: absolute;
        z-index: 1;
        bottom: -5s px;
        left: 0;
      }
    }
  }
}
</style>
