<template>
  <cp-card
    style="height: 100%"
    collapse-actions
    actions-icon="mdi-filter-variant"
    actions-header-text="Filters"
    dense
    flush-body
  >
    <template #title>
      <div class="d-flex align-center">
        <v-btn
          color="primary"
          v-if="returnLink"
          class="mr-2"
          icon
          :to="{
            name: returnLink,
          }"
        >
          <v-icon>mdi-keyboard-return</v-icon>
        </v-btn>
        <div class="page-title">
          <h4 v-if="title">{{ title }}</h4>
          <v-skeleton-loader v-else type="text" height="22" />
          <div class="page-title__area">
            <v-icon small :class="`${model.singularName}-color mb-1`">
              {{ titleIcon }}
            </v-icon>
            {{ model.pluralName == "areas" ? "Districts" : model.pluralName }}
          </div>
        </div>
      </div>
    </template>

    <template #actions>
      <dashboard-header-actions
        v-if="model"
        :omni-client="model.omniClients.dashboard"
      />
    </template>

    <dashboard-content
      v-if="model"
      :omni-client="model.omniClients.dashboard"
      :model="model"
    />
  </cp-card>
</template>

<script>
export default {
  computed: {
    model() {
      return this.$store.state[this.$route.meta.model];
    },

    title() {
      if (this.$route.path == "/all-locations") {
        return this.$store.state.auth.identity.client.name;
      }

      switch (this.$route.meta.model) {
        case "districts":
          return this.$store.state.auth.identity.client.name;
        case "regions":
          return this.$store.getters.userAccessLevel == "district"
            ? this.$store.state.districts.instance.name
            : this.$store.state.auth.identity.client.name;
        case "locations":
          return this.$store.getters.userAccessLevel == "location"
            ? this.$store.state.auth.identity.client.name
            : this.$store.state.regions.instance.name;
        case "surveys":
          return this.$store.state.locations.instance.name;
        default:
          return "Parent";
      }
    },

    returnLink() {
      if (this.$route.name == "allLocations") {
        return null;
      } else if (this.$route.name == "surveysAllLocations") {
        return "allLocations"
      }

      switch (this.$route.meta.model) {
        case "districts":
          return null;
        case "regions":
          return this.$store.getters.userAccessLevel == "district"
            ? "districts"
            : null;
        case "locations":
          return this.$store.getters.userAccessLevel == "location"
            ? null
            : "regions";
        case "surveys":
          return "locations";
        default:
          return null;
      }
    },

    titleIcon() {
      switch (this.$route.meta.model) {
        case "districts":
          return "mdi-map-marker-radius";
        case "regions":
          return "mdi-home-group";
        case "locations":
          return "mdi-home";
        case "surveys":
          return "mdi-file-document-edit";
        default:
          return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  line-height: 1.1;
  padding: 12px 0;

  .page-title__area {
    text-transform: capitalize;
    margin-top: 4px;
    font-size: 16px;
    font-weight: 400;
  }
}
</style>
