import { Api } from "@cp/lib";
import Vue from "vue";
import Omnirequest from "../lib/omnirequest";
import StaticData from "./static_data";
import store from "@cp/store/appStore";
const useStaticData =
  false && ["development", "qa"].includes(process.env.VUE_APP_MODE);

export default class {
  constructor(singularName, pluralName, apiPath) {
    const defaultState = () => {
      return {
        omniClients: {},
        singularName: singularName,
        pluralName: pluralName,
        collection: [],
        instance: {},
        collectionReady: false,
        instanceReady: false,
        tableMeta: {
          search: null,
          activeFilters: [],
          filterOptions: {},
          sortBy: ["scheduled_date"],
          sortDesc: [true],
          pagination: {
            page: 1,
            itemsPerPage: 15,
            pageStart: 1,
            pageStop: 15,
            pageCount: null,
            itemsLength: null,
          },
        },
      };
    };

    const bApi = new Api(`${process.env.VUE_APP_ENGAGE_API_PATH}/en/v1`);
    const mApi = new Api(`${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1`);
    const plainApi = new Api(process.env.VUE_APP_MARIGOLD_API_PATH);

    this.bApi = bApi;
    this.mApi = mApi;
    this.plainApi = plainApi;

    this.defaultState = defaultState();
    this.state = defaultState();

    this.getters = {
      filterOptions(state) {
        if (!state.tableMeta || !state.tableMeta.filterOptions) return {};

        let filterOptions = {};
        Object.keys(state.tableMeta.filterOptions).forEach(key => {
          filterOptions[key] = state.tableMeta.filterOptions[key].values.map(
            value => ({
              text: value.text,
              value: { [key]: value.value },
            })
          );
        });

        return filterOptions;
      },
    };

    this.actions = {
      fetchDashboardCollection(
        { commit, state, rootState },
        {
          districtID = null,
          regionID = null,
          locationID = null,
          surveyID = null,
          filters = {},
          page = null,
        } = {}
      ) {
        Vue.set(
          state.omniClients,
          "dashboard",
          state.omniClients.dashboard || new Omnirequest()
        );

        state.omniClients.dashboard.structures = {
          scope: [pluralName],
          addon_options: [
            "defaults",
            "swift_bunny_index",
            "company_index",
            "timeline",
          ],
          date_interval: "month",
        };

        if (page) {
          state.omniClients.dashboard.page = page;
        }

        if (districtID) {
          state.omniClients.dashboard.filters = {
            areas: [districtID],
          };
        } else if (regionID) {
          state.omniClients.dashboard.filters = {
            regions: [regionID],
          };
        } else if (locationID) {
          state.omniClients.dashboard.filters = {
            locations: [locationID],
          };
        } else if (surveyID) {
          state.omniClients.dashboard.filters = {
            surveys: [surveyID],
          };
          state.omniClients.dashboard.structures.addon_options = [
            "defaults",
            "swift_bunny_index",
            "company_index",
          ];
        }

        state.omniClients.dashboard.filters = {
          ...state.omniClients.dashboard.filters,
          ...filters,
          date_range: store.state.filters.date_range,
        };

        state.omniClients.dashboard.sortBy = ["name"];

        return state.omniClients.dashboard.fetch();
      },

      initOmniClient(
        { state },
        {
          namespace = null,
          filters = {},
          structures = {},
          page = {},
          sortBy = [],
          sortDesc = null,
        }
      ) {
        Vue.set(
          state.omniClients,
          namespace,
          new Omnirequest({
            filters: filters,
            structures: structures,
            page: page,
            sortBy: sortBy,
            sortDesc: sortDesc,
          })
        );

        return state.omniClients[namespace].fetch().then(data => {
          return data;
        });
      },

      fetchCollection({ commit, state }) {
        state.collectionReady = false;

        if (useStaticData) {
          commit("setCollection", [...StaticData[apiPath].collection]);
          return;
        }

        return bApi
          .authorize()
          .get(apiPath)
          .then(({ data }) => {
            commit("setCollection", data);
          });
      },

      fetchInstance({ commit, state }, slug) {
        state.instanceReady = false;

        if (useStaticData) {
          commit(
            "setInstance",
            Object.assign({}, StaticData[apiPath].instance)
          );
          return;
        }

        return mApi
          .authorize()
          .get(`${apiPath}/${slug}`, { slug: slug })
          .then(({ data }) => {
            commit("setInstance", {
              id: data.id,
              ...data.attributes,
            });

            return data;
          });
      },

      autocomplete({ commit, state }) {
        state.collectionReady = false;

        return bApi
          .authorize()
          .get(`${apiPath}/autocomplete`, {
            page: {
              number: state.tableMeta.pagination.page,
              size: state.tableMeta.pagination.itemsPerPage,
            },
            filters: state.tableMeta.activeFilters,
            q_text: state.tableMeta.search,
            sortBy: state.tableMeta.sortBy[0],
            sortDesc: state.tableMeta.sortDesc[0],
          })
          .then(({ data, meta }) => {
            commit("setCollection", data);
            commit("setMeta", meta);
          });
      },
    };

    this.mutations = {
      setCollection(state, collection) {
        state.collection = collection;
        state.collectionReady = true;
      },

      setMeta(state, meta) {
        Object.assign(state.tableMeta, {
          ...meta,
          filterOptions: meta.filter_options,
          pagination: {
            page: meta.pagination.current,
            itemsPerPage: meta.pagination.page_size,
            pageStart:
              meta.pagination.current * meta.pagination.page_size -
              meta.pagination.page_size,
            pageStop: meta.pagination.current * meta.pagination.page_size,
            pageCount: meta.pagination.total_pages,
            itemsLength: meta.pagination.total_count,
          },
        });
        state.collectionReady = true;
      },

      setInstance(state, instance) {
        state.instance = instance;
        state.instanceReady = true;
      },

      reset(state) {
        state = Object.assign(state, defaultState());
      },

      resetCollection(state) {
        state.collection = [];
      },

      resetInstance(state) {
        state.instance = {};
        state.instanceReady = false;
      },
    };
  }
}
