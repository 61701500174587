var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bun-survey-chart"},[(_vm.omniClientSurvey.data.results)?_c('cp-combo-chart',{staticClass:"bun-survey-chart__chart",attrs:{"height":200,"chart-data":_vm.chartData,"y-min":_vm.chartData.yMin,"y-max":_vm.chartData.yMax,"date-interval":_vm.$store.state.structures.date_interval,"options":{
        elements: {
          line: {
            spanGaps: true,
          },
          point: {
            spanGaps: true,
          },
        },
      },"show-details":""}}):_vm._e(),_c('div',{staticClass:"bun-survey-chart__legend"},[_c('div',[_c('v-icon',{attrs:{"color":"#e67e22","small":""}},[_vm._v("mdi-circle")]),_vm._v(" Survey")],1),_c('div',[_c('v-icon',{attrs:{"color":"#685478","small":""}},[_vm._v("mdi-circle")]),_vm._v(" Company")],1),_c('div',[_c('v-icon',{attrs:{"color":"#009cde","small":""}},[_vm._v("mdi-circle")]),_vm._v(" SB Index")],1)])],1),_c('v-divider'),_c('v-tabs',{staticClass:"tabs-header",attrs:{"grow":"","height":"48","background-color":"#d5e2ed","slider-color":"#ffffff","slider-size":"3"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v("mdi-format-list-bulleted")]),_vm._v(" Questions")],1),_c('v-tab',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v("mdi-home-account")]),_vm._v(" Residents")],1),_c('v-tab',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v("mdi-comment-text-multiple-outline")]),_vm._v(" Comments")],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[(_vm.omniClientQuestions)?_c('questions-tab',{attrs:{"omni-client":_vm.omniClientQuestions}}):_vm._e()],1),_c('v-tab-item',[(_vm.omniClientRespondents)?_c('respondents-tab',{attrs:{"omni-client":_vm.omniClientRespondents}}):_vm._e()],1),_c('v-tab-item',[(_vm.omniClientComments)?_c('comments-tab',{attrs:{"omni-client":_vm.omniClientComments}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }