<template>
  <div>
    <cp-h1>
      Resident Surveys
      <template #right>
        <share
          v-if="omniClient && !respondents.detailsOpen"
          class="mr-5"
          :omni-client="omniClient"
          title="Share Resident Surveys"
          filename="residents_surveys_list.xlsx"
          :params="{
            export_type: 'residents_surveys_list',
          }"
          outlined
        >
          <template #default="{on}">
            <v-btn outlined color="primary" v-on="on">
              <v-icon class="mr-2">mdi-share</v-icon> Share
            </v-btn>
          </template>
        </share>

        <v-menu
          offset-y
          bottom
          left
          origin="top right"
          :close-on-content-click="false"
        >
          <template #activator="{on, attrs}">
            <v-btn outlined color="primary" v-on="on" v-bind="attrs">
              <v-icon class="mr-2">mdi-filter</v-icon>
              Filter Residents
              <v-chip
                v-if="filterCount"
                x-small
                color="primary"
                class="px-1 ml-3"
              >
                {{ filterCount }}
              </v-chip>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-if="!omniClient.filters.confidential_only">
              <v-list-item-icon>
                <v-icon>mdi-magnify</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-text-field
                  v-model="omniClient.q_text"
                  clearable
                  hide-details
                  dense
                  single-line
                  label="Search Users"
                  @input="updateResults()"
                />
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="omniClient.meta.filters.surveys">
              <v-list-item-icon>
                <v-icon>mdi-clipboard-check-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <cp-select
                  v-model="omniClient.filters.surveys"
                  :items="omniClient.meta.filters.surveys.values"
                  item-text="label"
                  single-line
                  clearable
                  multiple
                  class="card-header-actions"
                  label="Surveys"
                  dense
                  hide-details
                  @input="updateResults()"
                  :loading="omniClient && omniClient.awaitingResponse"
                />
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="omniClient.meta.filters.regions">
              <v-list-item-icon>
                <v-icon>mdi-home-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <cp-select
                  v-model="omniClient.filters.regions"
                  :items="omniClient.meta.filters.regions.values"
                  single-line
                  clearable
                  multiple
                  class="card-header-actions"
                  label="Regions"
                  dense
                  hide-details
                  @input="updateResults()"
                  :loading="omniClient && omniClient.awaitingResponse"
                />
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="omniClient.meta.filters.locations">
              <v-list-item-icon>
                <v-icon>mdi-home-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <cp-select
                  v-model="omniClient.filters.locations"
                  :items="omniClient.meta.filters.locations.values"
                  single-line
                  clearable
                  multiple
                  class="card-header-actions"
                  label="Locations"
                  dense
                  hide-details
                  @input="updateResults()"
                  :loading="omniClient && omniClient.awaitingResponse"
                />
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="omniClient.meta.filters.confidential_only">
              <v-list-item-icon>
                <v-icon>mdi-account-question</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <cp-select
                  v-model="omniClient.filters.confidential_only"
                  :items="omniClient.meta.filters.confidential_only.values"
                  item-text="label"
                  single-line
                  class="card-header-actions"
                  label="Confidentiality"
                  dense
                  hide-details
                  @input="updateResults()"
                  :loading="omniClient && omniClient.awaitingResponse"
                />
              </v-list-item-content>
            </v-list-item>

            <cp-combo-date-filter
              v-if="omniClient && omniClient.meta.filters.date_range"
              offset-y
              left
              :start-date="$store.state.filters.date_start"
              :end-date="$store.state.filters.date_end"
              :preset="$store.state.filters.date_preset"
              :loading="omniClient && omniClient.awaitingResponse"
              @selectionUpdated="
                $store.commit('setDateFilter', $event);
                updateResults();
              "
            >
              <template #default="{toggleMenu, selectionDisplay}">
                <v-list-item v-on="toggleMenu">
                  <v-list-item-icon>
                    <v-icon>mdi-calendar-month-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ selectionDisplay }}
                  </v-list-item-content>
                </v-list-item>
              </template>
            </cp-combo-date-filter>

            <v-list-item @click="openReportingGroupsFilterDialog">
              <v-list-item-icon>
                <v-icon>mdi-filter-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Filter by Reporting Groups
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </cp-h1>
    <cp-card
      dense
      flush-body
      collapse-actions-always
      :filter-count="filterCount"
      actions-icon="mdi-filter"
      actions-header-text="Resident Filters"
      show-actions-activator-label
    >
      <active-groups-banner :omni-client="omniClient" />

      <respondents-tab
        v-if="omniClient"
        :omni-client="omniClient"
        ref="residentTable"
      />
    </cp-card>

    <survey-result-detail-modal v-if="omniClient" :omni-client="omniClient" />
    <groups-filter :omni-client="omniClient" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState({
      respondents: state => state.respondents,
      omniClient: state => state.respondents.omniClients.tableSummary,
    }),

    filterCount() {
      if (!this.omniClient.filters) return 0;

      let count = 0;

      if (this.omniClient.q_text) {
        count++;
      }

      let ignoredKeys = ["date_range", "confidential_only"];
      Object.entries(this.omniClient.filters).forEach(([x, y]) => {
        if (!ignoredKeys.includes(x) && y != null && y && y.length > 0) {
          count += 1;
        }
      });

      return count;
    },
  },

  methods: {
    ...mapActions("groups", ["openReportingGroupsFilterDialog"]),
    updateResults() {
      this.omniClient.page.number = 1;
      this.omniClient.fetch({
        debounce: 500,
      });
    },
  },

  mounted() {
    if (this.$route.query.showResidentSurvey) {
      this.$store.dispatch(
        "respondents/openDetailsModal",
        this.$route.query.showResidentSurvey
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.card-header-actions {
  &::v-deep .v-select__selections {
    flex-wrap: nowrap;
  }
}
</style>
