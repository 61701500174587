<template>
  <cp-card dense>
    <template #title>
      Experimental Data Visualiser
    </template>

    <template #actions>
      <cp-combo-date-filter
        v-if="$store.state.questions.omniClients.dataVisual"
        offset-y
        left
        :start-date="$store.state.filters.date_start"
        :end-date="$store.state.filters.date_end"
        :preset="$store.state.filters.date_preset"
        :loading="
          $store.state.questions.omniClients.dataVisual.awaitingResponse
        "
        @selectionUpdated="
          $store.commit('setDateFilter', $event);
          initChart();
        "
      />
    </template>

    <v-row>
      <v-col cols="4">
        <h3 class="mb-4">
          This chart shows a weighted display of agreement/importance for all
          questions, for all Regions/Locations.
        </h3>

        <h3>
          Each dot represents a question, and the size/opacity indicates the
          relative number of responses. Bigger, darker circles mean more
          responses.
        </h3>

        <v-divider class="mt-6" />

        <h3 class="mt-6">
          You can select one or more surveys from the dropdown below.
        </h3>
        <v-select
          v-model="surveys"
          multiple
          class="mt-3"
          :items="surveyOptions"
          label="Select Survey(s)"
          @change="initChart"
        />
      </v-col>

      <v-col cols="auto">
        <v-progress-circular
          v-if="
            $store.state.questions.omniClients.dataVisual &&
              $store.state.questions.omniClients.dataVisual.awaitingResponse
          "
          indeterminate
          :size="100"
          :width="10"
          color="primary"
        />
        <template v-else>
          <div id="tooltip">
            Mouse over a circle for more info
          </div>
          <div id="chart-container">
            <div class="graphic graphic--3">
              <div class="chart"></div>
              <div class="slider d-flex justify-center">
                <input type="range" min="0" max="100" value="50" class="mx-4" />
              </div>
            </div>
          </div>
        </template>
      </v-col>
    </v-row>
  </cp-card>
</template>

<script>
import * as d3 from "d3";

export default {
  data() {
    return {
      aiqData: [],
      surveys: [24],
      maxResponses: null,
      minResponses: null,
      surveyOptions: [
        { text: "Resident Satisfaction", value: 24 },
        { text: "Move In", value: 25 },
        { text: "Service Request Follow Up", value: 26 },
        { text: "Community Follow Up", value: 27 }
      ]
    };
  },

  mounted() {
    this.initChart();
  },

  methods: {
    scaleNumber(number) {
      let value =
        ((number - this.minResponses) * (20 - 1)) /
          (this.maxResponses - this.minResponses) +
        1;

      if (value < 0) {
        value = 0;
      }

      return value;
    },

    updateData() {
      this.aiqData = this.$store.state.questions.omniClients.dataVisual.data.results
        .filter(x => x.question_type_slug == "agreement-importance")
        .map((x, i) => ({
          index: i,
          x: Math.round((parseFloat(x.ai_importance) / 5.0) * 100),
          y: Math.round((parseFloat(x.ai_agreement) / 5.0) * 100),
          response_count: x.q_responded,
          label: x.body,
          survey: x.survey_name
        }));

      let responseCounts = this.aiqData
        .filter(x => x.response_count)
        .map(x => x.response_count);

      this.maxResponses = Math.max(...responseCounts);
      this.minResponses = Math.min(...responseCounts);

      this.renderChart();
    },

    initChart() {
      if (this.$store.state.questions.omniClients.dataVisual) {
        this.$store.state.questions.omniClients.dataVisual.filters.surveys = this.surveys;
        this.$store.state.questions.omniClients.dataVisual.fetch().then(() => {
          this.updateData();
        });
      } else {
        this.$store
          .dispatch("questions/initOmniClient", {
            namespace: "dataVisual",
            filters: {
              surveys: this.surveys
            },
            page: {
              size: "all"
            },
            structures: {
              scope: ["questions"],
              date_interval: "month",
              addon_options: ["defaults"]
            }
          })
          .then(() => {
            this.updateData();
          });
      }
    },

    renderChart() {
      let graphic = d3.select(".graphic--3");

      let COLORS = [
        "rgba(0, 156, 222, 1)",
        "rgba(0, 156, 222, .9)",
        "rgba(0, 156, 222, .75)",
        "rgba(0, 156, 222, .6)",
        "rgba(0, 156, 222, .5)",
        "rgba(0, 156, 222, .4)",
        "rgba(0, 156, 222, .3)"
      ];
      let FONT_SIZE = 11;
      let MAX_VAL = 100;
      let MIN_VAL = 65;
      let aiqData = this.aiqData;
      let scaleNumber = this.scaleNumber;

      let chart = scatterplot();
      let el = graphic.select(".chart");

      function weightData({ x, y }) {
        return aiqData
          .map(d => ({
            ...d,
            score: d.x * x + d.y * y
          }))
          .sort((a, b) => d3.descending(a.score, b.score))
          .map((d, i) => ({
            ...d,
            rank: Math.floor(scaleNumber(d.response_count))
          }))
          .reverse();
      }

      function getHypotenuse({ x, y }) {
        let x2 = x * x;
        let y2 = y * y;
        return Math.sqrt(x2 + y2);
      }

      function resize() {
        // let sz = Math.min(el.node().offsetWidth, window.innerHeight) * 0.8;
        chart.width(600).height(350);
        el.call(chart);
      }

      function scatterplot() {
        let margin = FONT_SIZE * 3;
        let scaleX = d3.scaleLinear();
        let scaleY = d3.scaleLinear();
        let scaleR = d3.scaleSqrt();
        let scaleC = d3.scaleQuantile();

        let width = 0;
        let height = 0;
        let chartWidth = 0;
        let chartHeight = 0;
        let weightX = 50;
        let weightY = 50;
        let hypotenuse = 0;

        function translate(x, y) {
          return `translate(${x}, ${y})`;
        }

        function enter({ container, data }) {
          let svg = container.selectAll("svg").data([data]);
          let svgEnter = svg.enter().append("svg");
          let gEnter = svgEnter.append("g");

          gEnter.append("g").attr("class", "g-plot");

          let axis = gEnter.append("g").attr("class", "g-axis");

          let x = axis.append("g").attr("class", "axis axis--x");

          let y = axis.append("g").attr("class", "axis axis--y");

          x.append("text")
            .attr("class", "axis__label")
            .attr("text-anchor", "start")
            .text("Importance");

          y.append("text")
            .attr("class", "axis__label")
            .attr("text-anchor", "end")
            .text("Agreement");
        }

        function exit({ container, data }) {}

        function updateScales({ data }) {
          hypotenuse = getHypotenuse({ x: weightX, y: weightY });
          let rangeX = (weightX / hypotenuse) * (chartWidth / 2) * 1.25;
          let rangeY = (weightY / hypotenuse) * chartHeight * 1.25;
          let maxR = Math.floor(FONT_SIZE * 1.5);

          scaleX.domain([MIN_VAL, MAX_VAL]).range([0, rangeX]);

          scaleY.domain([MIN_VAL, MAX_VAL]).range([rangeY, 0]);

          scaleR.domain([0, data.length]).range([maxR, 2]);

          scaleC.domain(data.map(d => d.rank)).range(COLORS);
        }

        function updateDom({ container, data }) {
          let svg = container.select("svg");

          svg.attr("width", width).attr("height", height);

          let g = svg.select("g");

          let maxY = scaleY.range()[0];
          let offsetX = chartWidth / 2;
          let offsetY = chartHeight - maxY;
          let rad = Math.acos(weightX / hypotenuse);
          let angle = 90 - (rad * 180) / Math.PI;
          let rotation = `rotate(${-angle} 0 ${scaleY.range()[0]})`;
          let translation = translate(margin * 1.5 + offsetX, margin + offsetY);
          let transform = `${translation} ${rotation}`;
          g.attr("transform", transform);

          let plot = g.select(".g-plot");

          let item = plot.selectAll(".item").data(
            d => d,
            d => d.index,
            d => d.response_weight
          );

          let tooltip = d3.select("#tooltip");

          let mouseenter = function(d) {
            tooltip.html(
              `
              <h4 class="font-weight-bold">${d.label}</h4>
              <h4>Survey: <span class="font-weight-bold">${d.survey}</span> | Agreement: <span class="font-weight-bold">${d.y}</span> | Importance: <span class="font-weight-bold">${d.x}</span></h4>
              <h4>Response Count: <span class="font-weight-bold">${d.response_count}</span></h4>
              `
            );
          };

          let mousemove = function(d) {
            tooltip.html(
              `
              <h4 class="font-weight-bold">${d.label}</h4>
              <h4>Survey: <span class="font-weight-bold">${d.survey}</span> | Agreement: <span class="font-weight-bold">${d.y}</span> | Importance: <span class="font-weight-bold">${d.x}</span></h4>
              <h4>Response Count: <span class="font-weight-bold">${d.response_count}</span></h4>
              `
            );
          };

          let mouseleave = function(d) {
            tooltip.text("Mouse over a circle for more info");
          };

          item
            .enter()
            .append("circle")
            .attr("class", "item")
            .on("mouseenter", mouseenter)
            .on("mousemove", mousemove)
            .on("mouseleave", mouseleave)
            .merge(item)
            .attr("x", 0)
            .attr("y", 0)
            .attr("r", d => (d.rank < 3 ? 3 : d.rank))
            .style("fill", d => scaleC(20 - d.rank))
            .style("stroke", d => d3.color(scaleC(20 - d.rank)).darker(0.7))
            .attr("transform", d => translate(scaleX(d.x), scaleY(d.y)));
        }

        function updateAxis({ container, data }) {
          let axis = container.select(".g-axis");

          let axisLeft = d3.axisLeft(scaleY);
          let axisBottom = d3.axisBottom(scaleX);

          axisLeft.ticks(Math.max(0, Math.floor(weightY / 10)));
          axisBottom.ticks(Math.max(0, Math.floor(weightX / 10)));
          let x = axis.select(".axis--x");

          let maxY = scaleY.range()[0];
          let offset = maxY;

          let buffer = Math.ceil(margin / 2 + 20);
          x.attr("transform", translate(0, buffer + offset)).call(axisBottom);

          let y = axis.select(".axis--y");

          y.attr("transform", translate(-buffer, 0)).call(axisLeft);

          x.select(".axis__label").attr("y", margin + 3);

          y.select(".axis__label")
            .attr("x", offset)
            .attr("y", margin + 3)
            .attr("transform", `rotate(90)`);
        }

        function chart(container) {
          let data = container.datum();

          enter({ container, data });
          exit({ container, data });
          updateScales({ container, data });
          updateDom({ container, data });
          updateAxis({ container, data });
        }

        chart.width = function(...args) {
          if (!args.length) return width;
          width = args[0];
          chartWidth = width - margin * 2.5;
          return chart;
        };

        chart.height = function(...args) {
          if (!args.length) return height;
          height = args[0];
          chartHeight = height - margin * 2.5;
          return chart;
        };

        chart.weight = function({ x, y }) {
          weightX = x;
          weightY = y;
          return chart;
        };

        return chart;
      }

      function handleInput() {
        let val = +this.value;
        let x = val;
        let y = 100 - val;
        let weighted = weightData({ x, y });

        chart.weight({ x, y });
        el.datum(weighted);
        el.call(chart);
      }

      function init() {
        el.datum(weightData({ x: 50, y: 50 }));
        el.call(chart);
        resize();
        graphic.select(".slider input").on("input", handleInput);
      }

      init();
    }
  }
};
</script>

<style lang="scss">
#chart-container {
  margin: 0 auto;
  text-align: center;

  .graphic,
  .chart {
    max-width: 100% !important;
  }
}

#tooltip {
  width: 700px;
  height: 80px;
  border-radius: 5px;
  border: 1px solid #999999;
  padding: 6px;
}

.graphic__hed {
  text-align: center;
  color: #333;
}
.chart {
  max-width: 40rem;
  margin: 0 auto;
  text-align: center;
}
.slider {
  max-width: 20rem;
  margin: 1rem auto;
  position: relative;
  padding-top: 16.5px;
  input {
    display: block;
    width: 100%;
  }
  &:before {
    content: "Agreement";
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.05em;
    color: #888;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
  }
  &:after {
    content: "Importance";
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.05em;
    color: #888;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
  }
}
.g-axis line,
.g-axis path {
  stroke: #ccc;
}
.g-axis {
  text {
    fill: #888;
  }
  .axis__label {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.05em;
    fill: #888;
  }
}
.item {
  fill-opacity: 0.75;
}
</style>
