<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,

  props: {
    chartData: { type: Object, default: () => {} },
    showDetails: { type: Boolean, default: false },
    dateInterval: { type: String, default: null }
  },

  computed: {
    localChartData() {
      return {
        labels: this.chartData.labels || [],
        datasets: [
          {
            fill: false,
            borderColor: `rgba(0, 156, 222, ${
              this.chartData.showSBX ? "1" : "0"
            })`,
            // pointBorderColor: "rgba(0, 156, 222, 0.0)",
            // pointBackgroundColor: "rgba(0, 156, 222, 0.0)",
            tension: 0.2,
            order: 0,
            borderWidth: 2,
            borderDash: [4, 4],
            data: this.chartData.sbx.data,
            label: this.chartData.sbx.label || null
          },
          {
            fill: false,
            borderColor: `rgba(185, 78, 142, ${
              this.chartData.showCompany ? "1" : "0"
            })`,
            // pointBorderColor: "rgba(185, 78, 142, 0)",
            tension: 0.2,
            order: 1,
            data: this.chartData.company.data,
            label: this.chartData.company.label || null
          },
          {
            fill: true,
            borderColor: `rgba(${this.chartData.chartColor}, 1)`,
            backgroundColor: `rgba(${this.chartData.chartColor}, .1)`,
            // pointBorderColor: "rgba(0, 138, 9, 0.0)",
            // pointBackgroundColor: "rgba(0, 138, 9, 0.0)",
            borderWidth: 2,
            tension: 0.2,
            order: 2,
            data: this.chartData.region.data,
            label: this.chartData.region.label || null
          }
        ]
      };
    },

    options() {
      let $this = this;
      return {
        elements: {
          point: {
            radius: this.showDetails ? 4 : 0,
            borderWidth: this.showDetails ? 1 : 0,
            borderColor: this.showDetails ? "rgba(0,0,0,.5)" : "rgba(0,0,0,0)",
            backgroundColor: "transparent"
          }
        },
        tooltips: {
          enabled: this.showDetails,
          mode: "index",
          intersect: false,
          titleFontSize: 16,
          bodyFontSize: 16,
          callbacks: {
            title: function(tooltipItems, data) {
              return data.labels[tooltipItems[0].index];
            }
          }
        },
        animation: {
          duration: 0
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              min: this.chartData.min || null,
              max: this.chartData.max || null,
              display: this.showDetails,
              type: "linear",
              ticks: {
                fontSize: 16,
                stepSize: 1,
                callback: function(value, index, ticks) {
                  return $this.chartData.labels
                    ? $this.chartData.labels[index]
                    : [];
                }
              }
            }
          ],
          yAxes: [
            {
              type: "linear",
              display: this.showDetails,
              ticks: {
                min: this.chartData.yMin || 0,
                max: this.chartData.yMax || 100
              }
            }
          ]
        }
      };
    }
  },

  watch: {
    chartData() {
      this.renderChart(this.localChartData, this.options);
    }
  },

  mounted() {
    this.renderChart(this.localChartData, this.options);
  }
};
</script>
