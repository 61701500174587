<template>
  <div>
    <div v-if="!omniClient" class="text-center pa-4">
      <v-progress-circular
        color="primary"
        indeterminate
        :size="80"
        :width="10"
      />
    </div>

    <v-data-table
      v-else
      dense
      must-sort
      item-key="id"
      :headers="headers"
      :items="omniClient.awaitingResponse ? [] : items"
      :loading="omniClient.awaitingResponse"
      :server-items-length="omniClient.meta.pagination.total_count"
      :options="{
        multiSort: false,
        page: parseInt(omniClient.meta.pagination.current || 1),
        itemsPerPage: parseInt(omniClient.meta.pagination.page_size || 15),
        sortBy: [omniClient.sortBy],
        sortDesc: [omniClient.sortDesc],
      }"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 30],
        disableItemsPerPage: omniClient.awaitingResponse,
        disablePagination: omniClient.awaitingResponse,
      }"
      @update:page="
        omniClient.page.number = $event;
        omniClient.fetch({ debounce: 200 });
      "
      @update:items-per-page="
        omniClient.page.size = $event;
        omniClient.fetch({ debounce: 200 });
      "
      @update:sort-by="
        omniClient.sortBy = $event;
        omniClient.fetch({ debounce: 200 });
      "
      @update:sort-desc="
        omniClient.sortDesc = $event;
        omniClient.fetch({ debounce: 200 });
      "
    >
      <template #item.score_danger="{ item }">
        <v-tooltip
          right
          transition="slide-x-reverse-transition"
          v-if="
            Number.isInteger(item.agreement_importance) &&
              item.agreement_importance < 70
          "
        >
          <template #activator="{ on }">
            <v-icon color="error" v-on="on">
              mdi-alert-circle
            </v-icon>
          </template>
          Score below 70
        </v-tooltip>
      </template>

      <template #item.taken_date="{value}">{{
        value ? toMDY(value) : "N/A"
      }}</template>

      <template #item.agreement_importance="{ item }">
        <span v-if="!item.agreement_importance">––</span>
        <span v-else>
          {{ item.agreement_importance }}
        </span>
      </template>

      <template #item.full_name="{ item }">
        <span
          v-if="
            item.full_name === 'null null' &&
              item.survey_name === 'Community Follow Up Survey'
          "
        >
          Prospect
        </span>
        <span v-else>
          {{ item.full_name }}
          <v-icon v-if="item.full_name == 'Confidential'" small color="primary">
            mdi-shield-account-outline
          </v-icon>
        </span>
      </template>

      <template #item.likely_to_renew="{ item }">
        <span v-if="!item.likely_to_renew">––</span>
        <span v-else>
          <likelihood-emoticon :likelihood="item.likely_to_renew" />
        </span>
      </template>

      <template #item.actions="{ item }">
        <v-btn
          rounded
          text
          color="primary"
          small
          @click="openModalAndRedirect(item)"
        >
          Details
          <v-icon small>mdi-chevron-right</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { toMDY } from "@cp/utils/dateUtils";

export default {
  props: {
    omniClient: { type: Object, default: () => {} },
  },

  data() {
    return {
      expanded: [],
      headers: [
        {
          text: "",
          value: "score_danger",
          width: "10px",
          class: "thin-column",
          cellClass: "thin-column",
        },
        { text: "Date", value: "taken_date", width: "140px" },
        {
          text: "Score",
          value: "agreement_importance",
          width: "80px",
          class: "thin-column",
          cellClass: "thin-column",
        },
        {
          text: "Renewal",
          value: "likely_to_renew",
          width: "80px",
          class: "thin-column",
          cellClass: "thin-column",
        },
        { text: "Survey", value: "survey_name" },
        { text: "Resident", value: "full_name" },
        { text: "Location", value: "location_name" },
        { text: "Unit #", value: "unit_number", width: "100px" },
        { text: "", value: "actions", width: "100px" },
      ],
    };
  },

  computed: {
    ...mapState({
      surveys: state => state.surveys,
      respondents: state => state.respondents,
    }),

    ...mapGetters("respondents", ["forRespondents", "forSurveys"]),

    items() {
      return this.omniClient.forRespondentsList({
        onlyBadScores: this.surveys.onlyShowLowScores,
      });
    },
  },

  methods: {
    toMDY,
    fetchSurveys(userID = null, locationID, surveyID) {
      this.$store.dispatch("respondents/fetchSurveysForUser", {
        locationID: this.omniClient.filters.locations,
        surveyID: this.omniClient.filters.surveys,
        userID: userID,
      });
    },
    openModalAndRedirect(item) {
      this.$store.dispatch("respondents/openDetailsModal", item.m_id);
      this.$CpEvent.$emit("redirect", {
        name: this.$route.name,
        params: this.$route.params,
        query: { showResidentSurvey: item.m_id },
      });
    },
  },
};
</script>
