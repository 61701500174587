import { Api } from "@cp/lib";
import { auth } from "@cp/lib/Client";
import { date } from "@cp/utils/dateUtils";
import { get } from "@cp/utils/objectUtils";
import eventbus from "@cp/lib/CpEventBus.js";

import groups from "./modules/groups.js";
import regions from "./modules/regions.js";
import districts from "./modules/districts.js";
import locations from "./modules/locations.js";
import surveys from "./modules/surveys.js";
import questions from "./modules/questions.js";
import respondents from "./modules/respondents.js";
import notifications from "./modules/notifications.js";

const bApi = new Api(`${process.env.VUE_APP_ENGAGE_API_PATH}/en/v1`);

export default {
  groups,
  regions,
  districts,
  locations,
  surveys,
  questions,
  respondents,
  notifications,
  root: {
    state: {
      shareOpen: false,
      filters: {
        date_start: date()
          .subtract(3, "month")
          .format("MM-DD-YYYY"),
        date_end: date().format("MM-DD-YYYY"),
        date_preset: "3_mo",
        date_range: date()
          .subtract(4, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        groups: [],
        underperforming_only: false,
      },
      structures: {
        scope: [],
        date_interval: "month",
        addon_options: [
          "defaults",
          "swift_bunny_index",
          "company_index",
          "timeline",
        ],
      },
      scores: {
        index: {},
        client: {},
        region: {},
        location: {},
      },
      managers: {
        regionManagers: [],
        locationManagers: [],
      },
      testingRoleSwitcher: "district",
      testingRoleSwitcherOptions: [
        { text: "District Manager", value: "district" },
        { text: "Regional Manager", value: "region" },
        { text: "Location Manager", value: "location" },
      ],
      searchLabels: [
        "Search Question Text",
        "Search Name, Email, Phone, etc.",
        "Search Comment Text, Resident",
      ],
    },

    actions: {
      fetchSidebarScores(
        { state, commit },
        {
          districtSlug = null,
          regionSlug = null,
          locationSlug = null,
          surveySlug = null,
        } = {}
      ) {
        return bApi
          .authorize()
          .get("intouch/instantiate", {
            area_slug: districtSlug,
            region_slug: regionSlug,
            location_slug: locationSlug,
            survey_slug: surveySlug,
          })
          .then(data => {
            commit("setScores", data.sidebar);
            commit("setManagers", data);

            return data;
          });
      },
    },

    mutations: {
      setScores(state, scores) {
        state.scores = {
          index: scores.sb_index || {},
          client: scores.client || {},
          district: scores.area || {},
          region: scores.region || {},
          location: scores.location || {},
        };
      },

      setManagers(state, managers) {
        state.managers = {
          regionManagers: managers.region_managers || {},
          locationManagers: managers.location_managers || {},
        };
      },

      setDateFilter(state, { preset, startDate, endDate }) {
        state.filters.date_preset = preset;
        state.filters.date_start = startDate;
        state.filters.date_end = endDate;
      },
    },

    getters: {
      userAccessLevel(state, getters, rootState, rootGetters) {
        // could be one of:
        // intouch-admin
        // area_manager
        // regional_manager
        // property_manager
        // maintenance_manager
        // temp

        // if we are loading a temp access page, set route access level as "temp"
        if (rootState.auth.hasCookie) {
          const intouchLevel = rootGetters["auth/thisAppAccessLevel"];
          const clientHasAreas = get(
            rootState,
            "auth.identity.client.has_areas"
          );

          switch (intouchLevel) {
            case "client-admin":
            case "intouch-admin":
            case "area_manager":
              return clientHasAreas ? "district" : "region";
            case "regional_manager":
              return "region";
            case "multi_property_manager":
              return "location";
            case "property_manager":
            case "maintenance_manager":
              return "location";
          }
        }

        const urlSearchParams = new URLSearchParams(window.location.search);
        if (urlSearchParams.has("temp_token")) return "temp";
        return "";
      },
      userRoutes(state, { userAccessLevel }, rootState, rootGetters) {
        switch (userAccessLevel) {
          case "district":
            return {
              surveysDetailPathAllLocations:
                "/all-locations/:locationSlug/:surveySlug",
              surveysPathAllLocations: "/all-locations/:locationSlug",
              allLocationsPath: "/all-locations",
              rootPath: "districts",
              regionsPath: "/districts/:districtSlug",
              locationsPath: "/districts/:districtSlug/:regionSlug",
              surveysPath: "/districts/:districtSlug/:regionSlug/:locationSlug",
              surveysDetailPath:
                "/districts/:districtSlug/:regionSlug/:locationSlug/:surveySlug",
            };
          case "region":
            return {
              surveysDetailPathAllLocations:
                "/all-locations/:locationSlug/:surveySlug",
              surveysPathAllLocations: "/all-locations/:locationSlug",
              allLocationsPath: "/all-locations",
              rootPath: "regions",
              regionsPath: "/regions",
              locationsPath: "/regions/:regionSlug",
              surveysPath: "/regions/:regionSlug/:locationSlug",
              surveysDetailPath:
                "/regions/:regionSlug/:locationSlug/:surveySlug",
            };
          case "location":
            return {
              surveysDetailPathAllLocations:
                "/all-locations/:locationSlug/:surveySlug",
              surveysPathAllLocations: "/all-locations/:locationSlug",
              allLocationsPath: "/all-locations",
              rootPath: "locations",
              regionsPath: null,
              locationsPath: "/locations",
              surveysPath: "/locations/:locationSlug",
              surveysDetailPath: "/locations/:locationSlug/:surveySlug",
            };
          default:
            return {
              rootPath: "missing",
            };
        }
      },
    },
  },
};
