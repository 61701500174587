<template>
  <cp-card
    flush-body
    dense
    header-background
    tile
    elevation="0"
    title-color="#f0f0f7"
  >
    <template #title>
      <cp-select
        :items="likelihoodItems"
        v-model="likelihood"
        label="Likelihood to Renew"
        @input="updateCommentFilters"
        style="margin-bottom: -12px; padding-top: 3px;"
      />
    </template>

    <template #actions>
      <span class="text-subtitle-1 mr-3">
        {{ omniClient.meta.pagination.total_count }} Comments
        {{ omniClient.q_text ? "(filtered)" : "" }}
      </span>
      <paginav class="int-comment-actions" :omni-client="omniClient" />
    </template>

    <div
      v-if="omniClient && omniClient.awaitingResponse"
      class="text-center pa-4"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        :size="80"
        :width="10"
      />
    </div>

    <v-container v-else style="max-height: 585px; overflow-y: auto">
      <comment
        v-for="comment in omniClient.data.results"
        :comment="comment"
        :key="comment.id"
      />
    </v-container>
  </cp-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    omniClient: { type: Object, default: () => {} }
  },

  data() {
    return {
      likelihoodItems: [
        { text: "All", value: "all" },
        { text: "Unlikely", value: "unlikely" },
        { text: "Neutral", value: "neutral" },
        { text: "Likely", value: "likely" }
      ],
      likelihood: "all"
    };
  },

  methods: {
    updateCommentFilters() {
      this.omniClient.filters.likelihood = this.likelihood;
      this.omniClient.fetch();
    }
  }
};
</script>

<style lang="scss" scoped>
.int-comment-actions {
  max-width: 200px;
}
</style>
