<template>
  <div class="pa-3 response-item">
    <div class="d-flex">
      <div class="icon-column">
        <v-tooltip
          right
          transition="slide-x-reverse-transition"
          v-if="
            response.question_type_slug == 'agreement-importance' &&
              Number.isInteger(response.resident) &&
              response.resident < 70
          "
        >
          <template #activator="{ on }">
            <v-icon color="error" v-on="on">
              mdi-alert-circle
            </v-icon>
          </template>
          Score below 70
        </v-tooltip>

        <template v-else-if="response.question_type_slug == 'text-entry'">
          <v-icon>mdi-comment-outline</v-icon>
        </template>
      </div>

      <div>{{ questionNumber }}.&nbsp;</div>

      <div class="response-item__body-icon">
        {{ response.body }}
      </div>

      <div
        v-for="(score, i) in comparableScores"
        :key="`score-${i}`"
        class="score-column"
        :class="comparableColors[i]"
      >
        <span :class="{ 'font-weight-bold': i == 0 }">{{ score }}</span>
      </div>
    </div>

    <div v-if="isType('text-entry')" class="extra">
      <div class="text-response">
        {{ response.resident || "No response given" }}
      </div>
    </div>

    <div v-if="isType('multiple-choice', 'multiple-select')" class="extra">
      <div class="multiple-choice-response">
        <template v-if="response.resident && response.resident.length">
          <span
            v-for="(choice, i) in response.resident"
            :key="`choice-${i}`"
            class="choice"
          >
            {{ choice }}
          </span>
        </template>
        <span v-else class="choice">No response given</span>
      </div>
    </div>
  </div>
</template>

<script>
import { formatYN } from "../../utils";
const uncomparableColumns = ["–", "–", "–", "–", "–"];
const comparableColors = [
  "",
  "location-color",
  "region-color",
  "company-color",
  "sbx-color",
];

export default {
  props: {
    response: { type: Object, default: () => {} },
    questionNumber: { type: Number, required: true },
  },

  data() {
    return {
      comparableColors,
    };
  },

  computed: {
    bodyIcon() {
      const defaultColor = "grey";
      switch (this.response.question_type_slug) {
        case "text-entry":
          return {
            icon: "mdi-comment-outline",
          };
        case "agreement-importance":
          if (this.response.resident < 70) {
            return {
              props: {
                color: "error",
              },
              icon: "mdi-alert-circle",
            };
          }
      }

      return null;
    },

    comparableScores() {
      switch (this.response.question_type_slug) {
        case "agreement-importance":
        case "agreement":
        case "importance":
        case "likelihood":
          // we do parseFloat to turn "5.0" to "5"
          return [
            parseFloat(this.response.resident) || "–",
            parseFloat(this.response.location) || "–",
            parseFloat(this.response.region) || "–",
            parseFloat(this.response.client_index) || "–",
            this.response.sb_index
              ? parseFloat(
                  this.response.sb_index[
                    this.response.question_type_slug.replace(/-/g, "_")
                  ]
                ) || "–"
              : "–",
          ];

        case "yes-no":
          return [
            this.response.resident
              ? parseFloat(this.response.resident) == 1.0
                ? "Yes"
                : "No"
              : "–",
            this.formatYN(parseFloat(this.response.location)),
            this.formatYN(parseFloat(this.response.region)),
            this.formatYN(parseFloat(this.response.client_index)),
            this.response.sb_index
              ? this.formatYN(parseFloat(this.response.sb_index.yes_no))
              : "-",
          ];
      }

      return uncomparableColumns;
    },
  },

  methods: {
    formatYN,
    isType(...types) {
      return types.includes(this.response.question_type_slug);
    },
  },
};
</script>

<style lang="scss" scoped>
.response-item {
  &:not(:last-child) {
    border-bottom: 1px solid #cccccc;
  }

  .response-item__body-icon {
    flex-grow: 1;
  }

  .response-item__comment {
    margin-left: 40px;
    margin-top: 12px;
  }

  .icon-column {
    width: 2rem;
    flex-shrink: 0;
  }

  .score-column {
    width: 5rem;
    text-align: center;
    flex-shrink: 0;
  }

  .extra {
    padding: 0.5rem 3.5rem;
  }

  .text-response {
    background: $white;
    border: 2px solid $gray-2;
    padding: 1rem;
  }

  .multiple-choice-response {
    .choice {
      display: inline-block;
      background: $white;
      border: 2px solid $gray-2;
      padding: 0.5rem 1rem;
      border-radius: 3rem;
      margin-right: 1rem;
    }
  }
}
</style>
