import Vue from "vue";
import Common from "../common.js";
import Omnirequest from "../../lib/omnirequest";
import store from "@cp/store/appStore";
let common = new Common("question", "questions", "questions");

export default {
  namespaced: true,

  state: {
    ...common.state,
    textEntriesPanelOpen: false,
    localSearch: null,
    typeMap: {
      1: {
        name: "Agreement/Importance",
        slug: "agreement_importance"
      },
      2: {
        name: "Likelihood",
        slug: "likelihood"
      },
      3: {
        name: "Yes/No",
        slug: "yes_no"
      },
      4: {
        name: "Text Entry",
        slug: "text_entry"
      },
      5: {
        name: "Multiple Choice",
        slug: "multiple_choice"
      },
      6: {
        name: "Multiple Select",
        slug: "multiple_select"
      },
      7: {
        name: "Identify",
        slug: "identify"
      },
      8: {
        name: "Agreement",
        slug: "agreement"
      },
      9: {
        name: "Numeric Entry",
        slug: "numeric_entry"
      },
      10: {
        name: "Rating",
        slug: "rating"
      },
      11: {
        name: "Employee List",
        slug: "employee_list"
      },
      12: {
        name: "Uploader",
        slug: "uploader"
      },
      13: {
        name: "Importance",
        slug: "importance"
      }
    },
    getScoreValues: item => {
      let values = {};
      item.client_index = item.client_index || {};
      item.sb_index = item.sb_index || {};

      switch (item.question_type_slug) {
        case "agreement-importance":
          values.score = item.agreement_importance;
          values.client_score = item.client_index.agreement_importance;
          values.sbx_score = item.sb_index.agreement_importance;
          break;
        case "likelihood":
        case "agreement":
        case "importance":
          values.score = item[item.question_type_slug];
          values.client_score = item.client_index[item.question_type_slug];
          values.sbx_score = item.sb_index[item.question_type_slug];
          break;
        case "yes-no":
          values.score = item.yes_no
            ? `Y/${Math.round(item.yes_no * 100)}%`
            : "–";
          values.client_score = item.client_index.yes_no
            ? `Y/${Math.round(item.client_index.yes_no * 100)}%`
            : "–";
          values.sbx_score = item.sb_index.yes_no
            ? `Y/${Math.round(item.sb_index.yes_no * 100)}%`
            : "–";
          break;
        default:
          values.score = "–";
          values.client_score = "–";
          values.sbx_score = "–";
      }

      return values;
    }
  },

  getters: {
    ...common.getters,

    forResults(state) {
      if (!state.omniClients.results || !state.omniClients.results.data.results)
        return [];

      return state.omniClients.results.data.results.map(x => ({
        ...x,
        ...state.getScoreValues(x),
        id: x.m_id,
        question_type_slug: x.question_type_slug.replace(/-/g, "_")
      }));
    }
  },

  actions: {
    ...common.actions,

    fetchInstance({ commit, state }, slug) {
      state.instanceReady = false;

      return common.bApi
        .authorize()
        .get(`intouch/questions/${slug}`)
        .then(({ data }) => {
          commit("setInstance", {
            id: data.id,
            ...data.attributes
          });

          return data;
        });
    },

    fetchCollection(
      { commit, state, rootState },
      { filters = {}, structures = {} } = {}
    ) {
      Vue.set(state.omniClients, "results", new Omnirequest());

      Object.assign(state.omniClients.results.filters, filters);
      Object.assign(state.omniClients.results.structures, structures);

      state.omniClients.results.fetch();
    },

    viewTextEntries(
      { state, dispatch, rootState },
      { locationID = null, surveyID = null, question = {} } = {}
    ) {
      state.textEntriesPanelOpen = true;

      dispatch("fetchInstance", question.slug);

      Vue.set(
        state.omniClients,
        "textEntries",
        new Omnirequest({
          filters: {
            locations: [locationID].flat(),
            surveys: [surveyID].flat(),
            questions: [question.m_id],
            date_range: rootState.filters.date_range
          },
          structures: {
            scope: ["responses"],
            date_interval: rootState.structures.date_interval,
            addon_options: ["defaults"]
          },
          sortBy: "created_at",
          sortDesc: true
        })
      );

      state.omniClients.textEntries.fetch();
    }
  },

  mutations: {
    ...common.mutations
  }
};
