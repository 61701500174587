<template>
  <div class="renew-scores-wrapper">
    <renew-bar
      class="company-color"
      :class="{ 'is-hidden': !scores.client.likely_to_renew }"
      :label="scores.client.text"
      :value="Math.round(scores.client.likely_to_renew) || '–'"
      icon="mdi-office-building"
      org-type="Company"
      color="#000000"
    />

    <renew-bar
      v-if="scores.district && $store.getters.userAccessLevel == 'district'"
      class="district-color"
      :class="{ 'is-hidden': !scores.district.likely_to_renew }"
      :label="scores.district.text || 'District'"
      :value="Math.round(scores.district.likely_to_renew) || '–'"
      icon="mdi-map-marker-radius"
      org-type="District"
      color="#000000"
    />

    <renew-bar
      v-if="['district', 'region'].includes($store.getters.userAccessLevel)"
      class="region-color"
      :class="{ 'is-hidden': !scores.region.likely_to_renew }"
      :label="scores.region.text || 'Region'"
      :value="Math.round(scores.region.likely_to_renew) || '–'"
      icon="mdi-home-group"
      org-type="Region"
      color="#000000"
    />

    <renew-bar
      class="location-color"
      :class="{ 'is-hidden': !scores.location.likely_to_renew }"
      :label="scores.location.text || 'Property'"
      :value="Math.round(scores.location.likely_to_renew) || '–'"
      icon="mdi-home"
      org-type="Location"
      color="#000000"
    />

    <v-divider
      :vertical="!sidebarClosed"
      :inset="!sidebarClosed"
      :class="{ 'my-1': sidebarClosed }"
    />

    <renew-bar
      class="sbx-color"
      :class="{ 'is-hidden': !scores.index.likely_to_renew }"
      :label="scores.index.text"
      :value="Math.round(scores.index.likely_to_renew) || '–'"
      icon="$vuetify.icons.SwiftBunnyLogo"
      org-type="SB Index"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueCookies from "vue-cookies";

export default {
  props: {
    sidebarClosed: {
      type: Boolean,
      default: VueCookies.get("sidebar_closed") === "true"
    }
  },

  computed: {
    ...mapState({
      scores: state => state.scores
    })
  }
};
</script>

<style lang="scss" scoped>
.renew-scores-wrapper {
  position: relative;
  width: 100%;
  height: 200px;
  display: flex;

  .is-hidden {
    opacity: 0.15;
  }

  .sidebar-closed & {
    height: auto;
    display: block;

    &::v-deep {
      .renewal-bar {
        display: block;
        height: auto;
        width: 100%;
        padding-top: 6px !important;
        padding-bottom: 0 !important;

        &:first-child {
          margin-top: 6px !important;
        }

        &:last-child {
          padding-bottom: 6px !important;
          margin-bottom: 6px;
        }

        .renewal-bar__icon,
        .renewal-bar__bar {
          display: none;
        }

        .renewal-bar__value {
          margin-left: -5px;
        }
      }
    }
  }
}
</style>
