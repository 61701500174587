<template>
  <v-dialog v-model="groups.filterModalOpen" max-width="500">
    <cp-card dense title-color="#e6e6e6">
      <template #title>Reporting Groups</template>
      <template v-if="groups.collectionReady">
        <div v-if="changeSelectionText.length">
          <template v-for="(change, i) in changeSelectionText">
            <span :key="`separator-${change.id}`" v-if="i > 0">,&nbsp;</span>
            <span :key="change.id" class="groupName" :style="change.style"
              >{{ change.symbol }}{{ change.name }}</span
            >
          </template>
          <v-divider />
        </div>
        <v-checkbox
          v-for="group in groups.collection"
          v-model="groups.formState"
          :value="group.id"
          :disabled="!groupsCounts[group.id]"
          dense
          hide-details
          :label="`${group.name} (${groupsCounts[group.id] || '0'})`"
          :key="group.id"
        />

        <v-divider class="my-2" />

        <v-btn color="primary" rounded @click="apply">
          Apply
        </v-btn>
        <v-btn class="ml-3" rounded @click="close()">
          Cancel
        </v-btn>
      </template>

      <template v-else>
        <div class="d-flex mb-2" v-for="n in 5" :key="n">
          <v-skeleton-loader type="image" width="20" height="20" class="mr-2" />
          <v-skeleton-loader type="image" width="80%" height="20" />
        </div>
      </template>
    </cp-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  props: {
    items: { type: Array, default: () => [] },
    omniClient: { type: Object, default: () => {} }
  },

  computed: {
    ...mapState({ groups: state => state.groups }),

    changeSelectionText() {
      return this.groups.collection
        .filter(x => this.groups.formState.includes(x.id))
        .map(({ id, name }) => {
          let style = {};
          let symbol = "";

          const wasAdded =
            !this.groups.selected.includes(id) &&
            this.groups.formState.includes(id);

          if (wasAdded) {
            symbol = "+";
            style = { color: "green" };
          }

          return {
            style,
            symbol,
            id,
            name
          };
        });
    },

    groupsCounts() {
      return this.items.reduce((r, item) => {
        if (item.reporting_group_ids && item.reporting_group_ids.length) {
          item.reporting_group_ids.forEach(id => {
            if (!r[id]) r[id] = 1;
            else r[id] += 1;
          });
        }
        return r;
      }, {});
    }
  },

  methods: {
    ...mapActions("groups", {
      close: "closeReportingGroupsFilterDialog"
    }),

    apply() {
      this.close(true);
      this.omniClient.fetch();
      this.$emit("change");
    }
  }
};
</script>

<style scoped>
.groupName {
  white-space: nowrap;
}
</style>
