<template>
  <div v-if="error" class="d-flex align-center justify-center ma-10">
    <v-alert type="error">{{ error }}</v-alert>
  </div>
  <survey-result-detail v-else class="mt-7" />
</template>

<script>
export default {
  data() {
    return {
      error: "",
    };
  },
  mounted() {
    const hasAuth =
      this.$route.query.temp_token || this.$store.state.auth.hasCookie;
    if (this.$route.query.showResidentSurvey && hasAuth) {
      this.$store.dispatch(
        "respondents/fetchInstance",
        this.$route.query.showResidentSurvey
      );
    } else {
      this.error = "Survey not found";
    }
  },
  beforeRouteLeave(to, from, next) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.has("temp_token")) {
      window.location = to.fullPath;
    }
  },
};
</script>
