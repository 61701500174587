<template>
  <div class="likert" :class="type">
    <div v-if="!values || values.length <= 0" class="font-weight-bold">
      No Responses
    </div>

    <template v-else>
      <div class="labels">
        <div class="label" v-for="(value, index) in values">
          <v-icon :color="getColor(index)" x-small>mdi-circle</v-icon>
          {{ value }}%
        </div>
      </div>
      <div class="values">
        <div class="d-flex">
          <div
            class="value"
            v-for="(value, index) in values"
            :style="`background-color: ${getColor(index)}; width: ${value}%`"
          ></div>
        </div>
        <div
          v-if="type != 'yes_no'"
          class="avg-value"
          :style="`left: calc(${(parseFloat(average) - 1) * 25}% - 16px);`"
        >
          <span>{{ average }}</span>
        </div>
      </div>
      <div>
        <span style="float: left">{{ labels[0] }}</span>
        <span style="float: right">{{ labels[labels.length - 1] }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    values: { type: Array, default: () => [] },
    average: { type: [Number, String], default: () => null },
    type: {
      type: [String, Number],
      default: "agreement"
    }
  },

  data() {
    return {
      valueColors: ["#eb4969", "#ee7850", "#f3aa47", "#99c061", "#68ab5d"]
    };
  },

  computed: {
    labels() {
      if (this.type == "importance") {
        return [
          "Very Unimportant",
          "Unimportant",
          "Neutral",
          "Important",
          "Very Important"
        ];
      } else if (this.type == "yes_no") {
        return ["No", "Yes"];
      } else if (this.type == "likelihood") {
        return [
          "Very Unlikely",
          "Unlikely",
          "Neutral",
          "Likely",
          "Very Likely"
        ];
      }

      return [
        "Totally Disagree",
        "Disagree",
        "Neutral",
        "Agree",
        "Totally Agree"
      ];
    }
  },

  methods: {
    getColor(index) {
      if (this.values.length == 2) {
        return index == 1
          ? this.valueColors[this.valueColors.length - 1]
          : this.valueColors[0];
      }

      return this.valueColors[index];
    }
  }
};
</script>

<style lang="scss" scoped>
.likert {
  width: 40%;
  min-width: 250px;
  max-width: 500px;
  display: inline-block;
  margin: 6px;

  &.importance,
  &.agreement,
  &.likelihood,
  &.agreement_importance {
    padding-bottom: 20px;
  }

  .labels {
    display: flex;
    justify-content: space-around;

    .label {
      padding-left: 8px;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .values {
    position: relative;
    border-radius: 2px;

    .value {
      height: 20px;

      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }

    .avg-value {
      width: 32px;
      text-align: center;
      bottom: -34px;
      position: absolute;
      font-weight: 600;

      &:after {
        content: "";
        position: absolute;
        width: 3px;
        height: 36px;
        background-color: $white;
        border-left: 1px solid #aaaaaa;
        bottom: 100%;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}
</style>
