<template>
  <cp-fullscreen-modal
    :show.sync="surveys.instanceDetailsOpen"
    title="Survey Details"
    back-text="Close"
    back-icon="close"
    :persistent="false"
  >
    <template #actions>
      <share
        v-if="
          questions.omniClients.tableSummary &&
            !omniClientRespondents.detailsOpen
        "
        color="dark"
        style="margin-top: -2px;"
        :omni-client="currentOmniClient"
        :filename="`Survey Export ${toMDY()}.xlsx`"
        title="Share Survey Details"
        endpoint="intouch/multi_dashboard_export.xlsx"
        :params="{
          filters: questions.omniClients.tableSummary.filters,
          structures: {
            date_interval:
              questions.omniClients.tableSummary.structures.date_interval,
            addon_options:
              questions.omniClients.tableSummary.structures.addon_options,
          },
          scopes: ['questions', 'residents_surveys', 'comments'],
        }"
      />
    </template>

    <comment-drawer />

    <cp-card
      flush-body
      collapse-actions-always
      :filter-count="filterCount"
      actions-icon="mdi-filter"
      actions-header-text="Survey Filters"
      show-actions-activator-label
    >
      <template #title v-if="surveys.instance">
        <div class="title-text-wrapper">
          {{ surveys.instance.name }}
          <h5 v-html="regionFilterText" />
        </div>
      </template>

      <template #actions v-if="omniClientSurvey">
        <cp-search
          v-if="currentOmniClient && currentTab == 0"
          v-model="questions.localSearch"
          clearable
          hide-details
          dense
          single-line
          :label="$store.state.searchLabels[currentTab]"
          class="mr-5"
        />

        <cp-search
          v-else-if="currentOmniClient"
          v-model="currentOmniClient.q_text"
          clearable
          hide-details
          dense
          single-line
          :label="$store.state.searchLabels[currentTab]"
          class="mr-5"
          @input="
            currentOmniClient.page.number = 1;
            currentOmniClient.fetch({ debounce: 250 });
          "
        />

        <cp-select
          v-if="omniClientSurvey.meta.filters.regions"
          v-model="omniClientSurvey.filters.regions"
          :items="omniClientSurvey.meta.filters.regions.values"
          clearable
          multiple
          class="card-header-actions"
          label="Regions"
          dense
          hide-details
          @input="omniClientSurvey.fetch()"
          :loading="omniClientQuestions && omniClientQuestions.awaitingResponse"
        />

        <cp-select
          v-if="omniClientSurvey.meta.filters.locations"
          v-model="omniClientSurvey.filters.locations"
          :items="omniClientSurvey.meta.filters.locations.values"
          clearable
          multiple
          class="card-header-actions"
          label="Locations"
          dense
          hide-details
          @input="omniClientSurvey.fetch()"
          :loading="omniClientQuestions && omniClientQuestions.awaitingResponse"
        />

        <cp-combo-date-filter
          v-if="omniClientSurvey"
          offset-y
          left
          :start-date="$store.state.filters.date_start"
          :end-date="$store.state.filters.date_end"
          :preset="$store.state.filters.date_preset"
          :loading="omniClientQuestions && omniClientQuestions.awaitingResponse"
          :activator-props="{
            class: 'mb-3',
          }"
          @selectionUpdated="
            $store.commit('setDateFilter', $event);
            omniClientSurvey.fetch();
          "
        />

        <v-btn
          @click="$store.dispatch('groups/openReportingGroupsFilterDialog')"
          rounded
          class="mb-1"
          color="primary"
          small
        >
          <v-icon small>mdi-filter</v-icon> Filter by Reporting Groups
        </v-btn>

        <v-switch
          v-model="surveys.onlyShowLowScores"
          label="Only Show Low Scores"
          class="mt-0"
          hide-details
        />
      </template>

      <internal-testing-link />

      <active-groups-banner
        v-if="$route.name != 'surveys'"
        :omni-client="omniClientSurvey"
      />

      <survey-details-body
        v-if="omniClientSurvey && !omniClientSurvey.awaitingResponse"
        :omni-client-survey="omniClientSurvey"
        :omni-client-questions="omniClientQuestions"
        :omni-client-respondents="omniClientRespondents"
        :omni-client-comments="omniClientComments"
        @tab-updated="currentTab = $event"
      />

      <div v-else class="text-center pa-4">
        <v-progress-circular
          color="primary"
          indeterminate
          :size="80"
          :width="10"
        />
      </div>
    </cp-card>
  </cp-fullscreen-modal>
</template>

<script>
import { mapState } from "vuex";
import { toMDY } from "@cp/utils/dateUtils";

export default {
  props: {
    omniClientSurvey: { type: Object, default: () => {} },
    omniClientQuestions: { type: Object, default: () => {} },
    omniClientRespondents: { type: Object, default: () => {} },
    omniClientComments: { type: Object, default: () => {} },
  },

  data() {
    return {
      currentTab: 0,
    };
  },

  computed: {
    ...mapState({
      surveys: state => state.surveys,
      questions: state => state.questions,
    }),

    currentOmniClient() {
      switch (this.currentTab) {
        case 0:
          return this.omniClientQuestions;
        case 1:
          return this.omniClientRespondents;
        case 2:
          return this.omniClientComments;
        default:
          return {};
      }
    },

    filterCount() {
      if (!this.omniClientSurvey || !this.omniClientSurvey.filters) return 0;

      let count = 0;

      if (this.omniClientSurvey.q_text || this.questions.localSearch != null) {
        count++;
      }

      if (
        this.omniClientSurvey.filters.regions &&
        this.omniClientSurvey.filters.regions.length
      ) {
        count++;
      }

      if (
        this.omniClientSurvey.filters.locations &&
        this.omniClientSurvey.filters.locations.length
      ) {
        count++;
      }

      if (this.surveys.onlyShowLowScores) {
        count++;
      }

      return count;
    },

    regionFilterText() {
      let text = "";
      let filterNames = {
        regions: [],
        locations: [],
      };

      if (!this.omniClientSurvey) return;

      ["regions", "locations"].forEach(area => {
        if (
          this.omniClientSurvey.filters[area] &&
          this.omniClientSurvey.meta.filters[area]
        ) {
          filterNames[area] = this.omniClientSurvey.meta.filters[area].values
            .filter(x => this.omniClientSurvey.filters[area].includes(x.value))
            .map(x => x.text);
        }

        if (filterNames[area].length > 0) {
          text += ` <span class="font-weight-bold">${area}:</span> ${
            filterNames[area].length > 3
              ? "Multiple"
              : filterNames[area].join(", ")
          }`;
        }
      });

      return text;
    },
  },

  methods: { toMDY },

  watch: {
    "surveys.instanceDetailsOpen"(isOpen) {
      if (!isOpen && this.$route.name != "surveyIndex") {
        this.$router.push({ name: "surveyIndex" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-header-actions {
  &::v-deep .v-select__selections {
    flex-wrap: nowrap;
  }
}

.title-text-wrapper {
  line-height: 1.1;

  h5 {
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
  }
}
</style>
