<template>
  <v-banner single-line rounded color="#f1f1f1" v-if="shouldShow" class="mb-2">
    Filtering By:
    <span class="font-weight-bold">{{ selectedGroupsDisplayText }}</span>

    <template #actions>
      <v-btn small text rounded color="error" @click="clear">
        <v-icon small>mdi-close</v-icon>Clear
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: {
    omniClient: { type: Object, default: () => {} }
  },

  computed: {
    ...mapGetters("groups", ["selectedGroups", "selectedGroupsDisplayText"]),
    shouldShow() {
      return (
        this.omniClient && this.selectedGroups && this.selectedGroups.length
      );
    }
  },

  methods: {
    ...mapMutations("groups", ["clearFilters"]),

    clear() {
      this.clearFilters();
      this.omniClient.fetch();
      this.$emit("change");
    }
  }
};
</script>
