import { Api } from "@cp/lib";
import Common from "../common.js";
const mApi = new Api(`${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1`);
let common = new Common("group", "groups", "groups");

export default {
  namespaced: true,

  state: {
    ...common.state,
    color: "#E86826",
    filterModalOpen: false,
    selected: [],
    formState: []
  },

  getters: {
    ...common.getters,

    selectedGroups({ selected, collection }) {
      return collection.filter(x => selected.includes(x.id)) || [];
    },

    selectedGroupsDisplayText(state, { selectedGroups }) {
      if (selectedGroups.length <= 0) {
        return "No Groups Selected";
      }

      return selectedGroups.map(x => x.name).join(" + ");
    }
  },

  actions: {
    ...common.actions,

    openReportingGroupsFilterDialog({ state, dispatch }) {
      state.formState = [...state.selected];
      state.filterModalOpen = true;
      dispatch("fetchReportingGroups");
    },

    closeReportingGroupsFilterDialog({ state }, apply = false) {
      if (apply) {
        state.selected = state.formState;
      }
      state.filterModalOpen = false;
    },

    fetchReportingGroups({ getters, commit, rootState }) {
      return mApi
        .authorize()
        .get("geographical_groups/autocomplete", { filters: [{ depth: 5 }] })
        .then(({ data }) => {
          commit(
            "setCollection",
            data.map(x => ({
              ...x.attributes,
              id: x.id
            }))
          );
        });
    }
  },

  mutations: {
    ...common.mutations,

    clearFilters({ selected }) {
      selected.splice(0);
    }
  }
};
