<template>
  <cp-fullscreen-modal
    :show.sync="respondents.detailsOpen"
    title="Response Details"
    back-text="Close"
    back-icon="close"
    persistent
    :close-disabled="auth.isTemporal"
    @fullscreenModalClosed="closeCleanup"
  >
    <template #actions>
      <share
        v-if="!auth.isTemporal && respondents.detailsOpen && omniClient"
        color="dark"
        :params="{
          export_type: 'resident_survey',
        }"
        :omni-client="omniClient"
      />
    </template>

    <internal-testing-link />

    <survey-result-detail />
  </cp-fullscreen-modal>
</template>

<script>
import { date } from "@cp/utils/dateUtils";
import { mapState } from "vuex";

export default {
  props: {
    omniClient: { type: Object, default: () => {} },
  },

  computed: {
    ...mapState({
      respondents: state => state.respondents,
      location: state => state.respondents.instance.location,
      auth: state => state.auth,
    }),
  },

  methods: {
    date,
    closeCleanup() {
      let query = Object.assign({}, this.$route.query);
      const hadToken = query.temp_token;
      delete query.showResidentSurvey;
      delete query.temp_token;
      if (hadToken) {
        window.location = this.$route.path;
      } else {
        this.$router
          .replace({ path: window.location.pathname, query: query })
          .catch(() => {});
        this.$store.commit("respondents/resetInstance");
      }
    },
  },
};
</script>
