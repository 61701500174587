<template>
  <div>
    <div v-if="!omniClient" class="text-center pa-4">
      <v-progress-circular
        color="primary"
        indeterminate
        :size="80"
        :width="10"
      />
    </div>

    <v-data-table
      v-else
      dense
      show-expand
      disable-pagination
      hide-default-footer
      :search="questions.localSearch"
      item-key="id"
      :headers="headers"
      :items="
        omniClient.forQuestionList({ onlyBadScores: surveys.onlyShowLowScores })
      "
      :loading="omniClient.awaitingResponse"
      :options="{
        multiSort: false,
      }"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      class="expandable-table"
    >
      <template #header.agreement_importance="{ header }">
        <v-icon class="location-color" small style="margin-top: -3px">
          mdi-home
        </v-icon>
        {{ header.text }}
      </template>

      <template #header.client_index.agreement_importance="{ header }">
        <v-icon class="company-color" small style="margin-top: -2px">
          mdi-office-building
        </v-icon>
        {{ header.text }}
      </template>

      <template #header.sb_index.agreement_importance="{ header }">
        <v-icon class="sbx-color" small style="margin-top: -4px">
          $vuetify.icons.SwiftBunnyLogo
        </v-icon>
        {{ header.text }}
      </template>

      <template #item.score_danger="{ item }">
        <v-tooltip
          right
          transition="slide-x-reverse-transition"
          v-if="item.question_type_id == 1 && item.score < 70"
        >
          <template #activator="{ on }">
            <v-icon color="error" v-on="on">
              mdi-alert-circle
            </v-icon>
          </template>
          Score below 70
        </v-tooltip>

        <template v-else-if="item.question_type_id == 4">
          <v-icon>mdi-comment-outline</v-icon>
        </template>
      </template>

      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="text-center py-2">
          <h1 class="mt-1">
            {{ questions.typeMap[item.question_type_id].name }}
          </h1>

          <template v-if="item.question_type_id == 1">
            <likert
              :values="
                item.response_summary ? item.response_summary.agreement : []
              "
              :average="item.ai_agreement"
              type="agreement"
            />
            <likert
              :values="
                item.response_summary ? item.response_summary.importance : []
              "
              :average="item.ai_importance"
              type="importance"
            />
          </template>

          <likert
            v-else-if="[8, 13, 2, 3].includes(item.question_type_id)"
            :values="item.response_summary ? item.response_summary : []"
            :average="
              // TODO once backend sends correct info, this ugliness can go away
              (item.timeline
                ? item.timeline[item.timeline.length - 1].ai_agreement
                : null) ||
                (item.timeline
                  ? item.timeline[item.timeline.length - 1].likelihood
                  : null) ||
                (item.timeline
                  ? item.timeline[item.timeline.length - 1].ai_importance
                  : null)
            "
            :type="item.question_type_slug"
          />

          <multiple-choice
            v-else-if="[5, 6].includes(item.question_type_id)"
            :item="item"
          />

          <div v-else-if="item.question_type_id == 4" class="py-3">
            <v-btn
              class="question-comment-activator"
              x-large
              elevation="2"
              rounded
              color="primary"
              :disabled="
                !item.response_summary || parseInt(item.response_summary) <= 0
              "
              @click="
                $store.dispatch('questions/viewTextEntries', {
                  locationID: omniClient.filters.locations,
                  surveyID: omniClient.filters.surveys,
                  question: item,
                })
              "
            >
              <v-icon x-large class="mt-1 mr-2">mdi-comment-outline</v-icon>
              <span class="question-comment-count">
                {{ item.response_summary || 0 }}
              </span>
              View Comments
            </v-btn>
          </div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { date } from "@cp/utils/dateUtils";

export default {
  props: {
    omniClient: { type: Object, default: () => {} },
  },

  computed: {
    ...mapState({
      surveys: state => state.surveys,
      locations: state => state.locations,
      questions: state => state.questions,
    }),

    dynamicHeaders() {
      let interval = this.omniClient.structures.date_interval;
      let headers = [];
      let startDate = date(this.$store.state.filters.date_start);
      let endDate = date(this.$store.state.filters.date_end);
      let intervalDiff = endDate.diff(startDate, interval);

      for (var i = 0; i < 4; i++) {
        let text;

        switch (interval) {
          case "month":
            let month = endDate.subtract(i, "month");
            text = `${month.format("MMM")} ${month.format("YY")}`;
            break;
          case "quarter":
            let quarter = endDate.subtract(i, "quarter");
            text = `Q${quarter.quarter()} ${quarter.format("YY")}`;
            break;
          case "year":
            text = endDate.subtract(i, "year").year();
            break;
        }

        headers.unshift({
          text: text,
          value: `historic[${i}]`,
          width: "70px",
          align: "center",
          class: "thin-column",
          cellClass: "thin-column",
        });
      }

      return headers;
    },

    headers() {
      return [
        {
          text: "",
          value: "score_danger",
          width: "10px",
          class: "thin-column",
          cellClass: "thin-column",
          sortable: false,
        },
        {
          text: "Question",
          value: "body",
        },
        ...this.dynamicHeaders,
        {
          text: "Company",
          value: "client_score",
          width: "100px",
          align: "center",
        },
        {
          text: "SB Index",
          value: "sbx_score",
          width: "95px",
          align: "center",
        },
      ];
    },
  },
};
</script>
