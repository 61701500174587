<template>
  <div class="card-page-contents">
    <v-progress-linear
      :active="awaitingResponse"
      indeterminate
      color="primary"
      absolute
      top
    />

    <active-groups-banner
      v-if="$route.name != 'surveys'"
      :omni-client="omniClient"
    />

    <template v-if="omniClient && omniClient.awaitingResponse">
      <v-skeleton-loader
        v-for="(n, i) in 4"
        class="mb-4"
        type="image"
        height="90"
        :key="i"
      />
    </template>

    <template v-else-if="items.length > 0">
      <row-link
        v-for="item in items"
        :key="item.id"
        :item="item"
        :omni-client="omniClient"
        :model="model"
      />
    </template>

    <v-alert v-else dense text icon="mdi-information">
      No results found for the given filters.
    </v-alert>

    <groups-filter :items="items" :omni-client="omniClient" />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    omniClient: { type: Object, default: () => {} },
    model: { type: Object, default: () => {} }
  },

  computed: {
    ...mapState({
      groups: state => state.groups
    }),

    ...mapGetters("groups", ["selectedGroups"]),

    items() {
      if (!this.omniClient) return [];

      let items = this.omniClient.forDashboard();

      if (items.length <= 0) return [];

      return items;
    },

    awaitingResponse() {
      if (!this.omniClient) return false;

      return this.omniClient.awaitingResponse;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-page-contents {
  padding: 18px;
  position: relative;
  max-height: 71vh;
  overflow-y: auto;
}
</style>
